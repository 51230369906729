<template>
  <div>
    <SplashScreen
      v-if="showSplashScreen"
      @onCloseSplashScreenEvent="closeSplashScreen"
    />
    <div v-else>
      <Header v-if="isDesktop" />
      <HeaderMobile v-if="!isDesktop" />
      <template v-if="spotSheet !== '' || recommendationList !== '' || seo_data !== undefined ">
        <Sidebar v-if="isDesktop" :status="showSidebar" />
        <SidebarMobile v-if="!isDesktop" />
      </template>
      <Map v-if="initiateMap" />
      <ShareModal v-if="showShareModal" />
      <Banner :isDesktop="isDesktop" />
      <Legend v-if="isDesktop" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SplashScreen from "../../components/layout/SplashScreen";
import Header from "../../components/layout/Header";
import HeaderMobile from "../../components/layout/HeaderMobile";
import Banner from "../../components/layout/banner/Banner";
import Sidebar from "../../components/layout/Sidebar";
import SidebarMobile from "../../components/layout/SidebarMobile";
import ShareModal from "../../components/layout/modal/Share";
import Legend from "../../components/layout/Legend";
import Map from "../../components/layout/Map";
import seoHelper from "../../utils/seoHelper"
import store from "../../store"

export default {
  props: ['seo_data'],
  data() {
    return {
      showSplashScreen: false,
      isDesktop: !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)),
      initiateMap: false,
    };
  },
  components: {
    SplashScreen,
    Header,
    HeaderMobile,
    Banner,
    Sidebar,
    SidebarMobile,
    ShareModal,
    Legend,
    Map,
  },
  methods: {
    closeSplashScreen() {
      this.showSplashScreen = false;
      this.initiateMap = true;
    },
  },
  created() {
    window.history.replaceState({
      "reload": window.location.pathname
    }, "", window.location);
    if (this.seo_data === undefined) return;
    // let elem = document.getElementById("seo-sidebar")
    // elem.style.display = "none"
    seoHelper.seo_data = this.seo_data
    this.$store.dispatch("map/setSearchPosition", [this.seo_data.seo_page.lng, this.seo_data.seo_page.lat]);
  },
  mounted() {
    this.showSplashScreen = !this.isDesktop && !window.location.hash && window.location.origin + "/" === window.location.href;
    this.initiateMap = !this.showSplashScreen;
    if (this.seo_data === undefined) return;
    this.$store.dispatch("map/setRecommendations");
    this.$store.dispatch("sidebar/showSidebar", true);
  },
  computed: {
    ...mapGetters("utils", ["showShareModal"]),
    ...mapGetters("sidebar", ["showSidebar"]),
    ...mapGetters("map", ["spotSheet", "recommendationList"]),
  },
};
</script>

<style scoped>

</style>


