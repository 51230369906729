<template>
  <div class="address">
    <div class="title">Address</div>
    <div>{{data}}</div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.address {
  color: #545e69;
  font-size: 14px;
  padding: 20px;
}
.address .title {
  font-weight: bold;
  padding-bottom: 5px;
}
</style>