<template>
  <header id="header-mobile">
    <transition name="slideNavbar" mode="out-in">
      <Navbar v-if="showNavbar" />
    </transition>
    <div class="menu">
      <div @click="showNavbar = !showNavbar" class="burger">
        <svg viewBox="0 0 100 80" width="40" height="15">
          <rect width="100" height="14" rx="6" />
          <rect y="30" width="100" height="14" rx="6" />
          <rect y="60" width="100" height="14" rx="6" />
        </svg>
      </div>
      <Search :mapClass="mapClass" searchPlaceholder="Where to?" />
    </div>
    <div class="filter">
      <ul class="menu-item">
        <li v-on:click="toggleHourly" v-bind:class="{ active: !monthlyMode }">
          Hourly
        </li>
        <li v-on:click="toggleMonthly" v-bind:class="{ active: monthlyMode }">
          Monthly
        </li>
      </ul>
      <transition name="slideDates" >
          <div v-show="!showFilter && !monthlyMode" @click="showFilter = true" class="dates">
            {{ formattedDate.arrivalDate }}, {{ formattedDate.arrivalTime }}
            <span> to </span>{{ formattedDate.arrivalDate }},
            {{ formattedDate.departureTime }}
          </div>
      </transition>
      <transition name="slideFilter" >
          <DateFilterMobile v-show="showFilter && !monthlyMode" @close="showFilter = false"/>
      </transition>
    </div>
  </header>
</template>

<script>
import Navbar from "./Navbar";
import DateFilterMobile from "../filter/DateFilterMobile";
import Search from "../filter/Search";
import { mapGetters } from "vuex";
import TrackHelper from "../../utils/analytics/amplitude"

export default {
  data() {
    return {
      showNavbar: false,
      showFilter: false,
    };
  },
  components: {
    Navbar,
    Search,
    DateFilterMobile,
  },
  methods: {
    toggleMonthly() {
      if (this.monthlyMode) return;
      TrackHelper.headerTapClicked("monthly");
      this.$store.dispatch("filter/setMonthlyMode", true);
      this.updateMapAndSidebar();
      if ("URLSearchParams" in window) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("monthly", "true");
        let newRelativePathQuery =
          window.location.pathname +
          "?" +
          searchParams.toString() +
          window.location.hash;
        history.pushState(null, "", newRelativePathQuery);
      }
      // this.$store.dispatch("sidebar/showSidebar", false);
    },
    toggleHourly() {
      if (!this.monthlyMode) return;
      TrackHelper.headerTapClicked("hourly");
      this.$store.dispatch("filter/setMonthlyMode", false);
      this.updateMapAndSidebar();
      let newRelativePathQuery =
        window.location.pathname + window.location.hash;
      history.pushState(null, "", newRelativePathQuery);
    },
    updateMapAndSidebar() {
      this.$store.dispatch("map/reloadTiles", {});
      if (this.sidebarContent === "spotsheet") {
        this.$store.dispatch("map/setRecommendationList", "");
        // If we switched to monthly with a onstreet segment, we keep sidebar closed
        if (this.monthlyMode && this.spotSheet.status.name !== 'Garage') return;
        this.$store.dispatch("map/setSpotsheet");
        this.mapClass.pendingSourceData = true;
      } else if (this.sidebarContent === "recommendations" || this.sidebarContent === "seo") {
        this.mapClass.setSearchPin();
        this.$store.dispatch("map/setRecommendations");
      }
    },
  },
  computed: {
    ...mapGetters("filter", [
      "monthlyMode",
      "getFilteredDate",
      "getFilterPayload",
    ]),
    ...mapGetters("map", ["sidebarContent", "mapClass", "spotSheet"]),
    formattedDate() {
      const arrivalDate = `${new Date(
        this.getFilteredDate.arrivalDate
      ).getMonth() + 1}/${new Date(
        this.getFilteredDate.arrivalDate
      ).getDate()}`;
      const arrivalTime = this.getFilteredDate.arrivalTime;
      const departureTime = this.getFilteredDate.departureTime;
      return {
        arrivalDate,
        arrivalTime,
        departureTime,
      };
    },
  },
};
</script>

<style scoped>
header {
  background: #fff;
  position: relative;
  top: 75px;
  border-radius: 5px;
  z-index: 9;
  width: 95%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  /* overflow: hidden; */
}
.menu {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ced6de;
  padding: 0px 5px;
}
.filter {
  padding: 16px;
  overflow: hidden;
}
ul.menu-item {
  display: flex;
  list-style: none;
  padding: 0 0 14px 0;
}
ul.menu-item li {
  color: #8293a3;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
}
ul.menu-item li:first-of-type {
  margin-right: 15px;
}
ul.menu-item li.active {
  border-bottom: 3px solid #1c9be6;
  color: #212529;
}
.dates {
  color: #1c9be6;
  font-size: 16px;
  overflow: hidden;
}
.dates span {
  color: #5e6872;
}

.slideFilter-enter-active,
.slideFilter-leave-active {
  height: 200px;
  transition: all 0.5s ease-in;
}

.slideFilter-enter,
.slideFilter-leave-to {
  transition: all 0.5s ease-out;
  height: 0;
}

.slideDates-enter-active,
.slideDates-leave-active {
  height: 16px;
  transition: all 0.62s ease-in;
}

.slideDates-enter,
.slideDates-leave-to {
  transition: all 0.62s ease-out;
  height: 0;
}

.slideNavbar-enter-active,
.slideNavbar-leave-active {
  height: 254px;
  transition: all 0.3s ease-in-out;
}

.slideNavbar-enter,
.slideNavbar-leave-to {
  transition: all 0.3s ease-in-out;
  height: 0;
}
</style>
