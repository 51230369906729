const MAP_SETTINGS_PROD = {
    style_url: 'mapbox://styles/hamzao/cjhvdsbh50h212rpnh9x2ymyt',
    navigation_style_url: 'mapbox://styles/hamzao/cjhvddmc80gp02spf9etp6jr2',
    sources: {
        regulations: {
            url: 'mapbox://hamzao.9qk29p1o',
            replace: true,
            vector: true,
            tiles_path: '/api/v4/tiles/{z}/{x}/{y}.mvt',
            monthly_tiles_path: '/api/v4/tiles/monthly/{z}/{x}/{y}.mvt',
            max_zoom: 16,
            use_params: true,
        },
        availability: {
            url: 'mapbox://hamzao.5c2wkezo',
            replace: true,
            vector: true,
            tiles_path: '/api/v4/tiles/availability/centroids/{z}/{x}/{y}.mvt',
            max_zoom: 16,
            use_params: true,
            refresh_every: 60,
        },
        garages_deals: {
            url: 'mapbox://hamzao.9giqc7de',
            replace: true,
            vector: true,
            tiles_path: '/api/v4/tiles/deals/centroids/{z}/{x}/{y}.mvt',
            max_zoom: 16,
            use_params: true,
        },
        fingerprint: {
            url: 'mapbox://hamzao.fingerprints_world_diff',
            replace: true,
            vector: true,
            tiles_path: '/api/v4/tiles/fingerprint/{z}/{x}/{y}.mvt',
            monthly_tiles_path: '/api/v4/tiles/fingerprint/{z}/{x}/{y}.mvt',
            max_zoom: 9,
            use_params: false,
        },
        suggestions: {
            url: 'mapbox://hamzao.cjnnqtlek0npz31jvgf4k5bki-4a5qp',
            replace: true,
        },
        spot_info: {
            url: 'mapbox://hamzao.31hpokxd',
            replace: true
        },
        park: {
            url: 'mapbox://hamzao.cj9rfvf4y26iv2xntdbajr0t3-3c1jm',
            replace: true
        },
        park_selected: {
            url: 'mapbox://hamzao.cja00wv792m3j2xpczu122i5j-9tn16',
            replace: true
        },
        place: {
            url: 'mapbox://hamzao.cj9rxiu8767zq2ylgfrrmsfkv-9hjpn',
            replace: true
        },
        // open_spots: {
        //     url: 'mapbox://hamzao.cjfpo40vv0m8z33o10at6foim-4i97y',
        //     replace: true
        // },
        // crowdsource_items: {
        //     url: 'mapbox://mapbox.mapbox-terrain-v2',
        //     replace: true
        // },
        cities: {
            url: 'mapbox://hamzao.a82s1aqw',
            replace: false
        },
        // crowdsource_item_selected: {
        //     url: 'mapbox://mapbox.mapbox-incidents-v1',
        //     replace: true
        // },
    },
    world_layer: 'world-diff-9bjb6y',
    filterable_layers: ['segments', 'garages', 'labels', 'labels-availability', 'labels-higher-chance', 'labels-deals', 'labels-deals-monthly', 'labels-garage', 'labels-navigation'],

    clickable_layers: [
    {car: 0.0, 'cities with icons':0.0, 'selected-place': 0.0},
    {'cities': 0.0},
    {'suggestions-pins': 0.0, 'suggestions-segments': 0.0},
    {'labels-deals': 14.0},
    {'labels-availability': 14.0, 'labels-higher-chance': 14.0},
    {labels: 14.0, 'labels-noparking': 14.0, 'labels-noparking-anytime': 18.0, 'labels-garage': 14.0},
    {
        segments: 14.0,
        'segments-no-data': 14.0,
        'segments-filtered': 14.0,
        'segments-filtered-server': 14.0,
        'segments-no-parking': 14.0,
        garages: 14.0,
        'garages-filtered': 14.0,
        'labels-filtered': 14.0,
    },
],
    booking_results_clickable_layers: [
    {'suggestions-pins': 0.0, 'suggestions-garages': 0.0, 'suggestions-segments': 0.0},
],
}

export default MAP_SETTINGS_PROD;