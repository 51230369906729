export default {
  namespaced: true,
  state: {
    showShareModal: false,
  },
  mutations: {
    showShareModal(state, payload) {
      state.showShareModal = payload;
    },
  },
  actions: {
    showShareModal(context, payload) {
      context.commit("showShareModal", payload);
    },
  },
  getters: {
    showShareModal: (state) => {
      return state.showShareModal;
    },
  },
};
