import MapboxHelper from "./mapboxHelper";
import MAP_SETTINGS_PROD from "../constants/mapSettings";
import store from "../store";
import * as turf from "@turf/turf";
import TrackHelper from "../utils/analytics/amplitude";
import seoHelper from "./seoHelper"

const MapHelper = function () {
  this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  this.mapSettings = MAP_SETTINGS_PROD;
  this.mapboxHelper;
  this.zoomLevel = 3.5;
  this.centerPosition = [-96.6, 35];
  this.labelIdAfterMapMoved = 0;
  this.tilesLoadedCallbacks = [];
  this.searchPin = {};
  this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  this.pendingSourceData = false;
};

MapHelper.prototype.initStartPosition = function () {
  let mapHelper = this;

  if (sessionStorage.getItem('trackingSource') === null)
    sessionStorage.setItem('trackingSource', this.getTrackingSource())

  if (mapHelper.isMobile) {
    mapHelper.zoomLevel = 1.8;
    mapHelper.centerPosition = [-90.772905, 42.582678];
  }

  // Check URL #Hash
  let searchParams = new URLSearchParams(
    location.href.split("#")[1] ||
    location.href.split("%23")[1] ||
    location.href.split("?")[1] ||
    ""
  );
  let realSearchParams = new URLSearchParams(window.location.search);

  if (realSearchParams.has("monthly") && realSearchParams.get("monthly") === "true") {
    store.dispatch("filter/setMonthlyMode", true);
    if (!searchParams.has("parking-near") && !searchParams.has("id")) {
      mapHelper.initMap();
      return;
    }
  }

  if (searchParams.has("parking-near")) {
    // $('.overview').find(".content").html('');
    let mapbox_geo_url =
      "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
      encodeURI(searchParams.get("parking-near").replace("/-/g", " ")) +
      ".json?access_token=" +
      mapboxToken +
      "&limit=1";
    if (searchParams.has("lng") && searchParams.has("lat")) {
      mapbox_geo_url +=
        "&proximity=" +
        encodeURI(searchParams.get("lng")) +
        "," +
        encodeURI(searchParams.get("lat"));
    }

    const request = new Request(mapbox_geo_url);
    fetch(request)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.features && results.features.length > 0) {
          let result = results.features[0];
          mapHelper.zoomLevel = 15;
          mapHelper.centerPosition = result.center;
          mapHelper.buildSearchPin(
            result.center[0],
            result.center[1],
            result.place_name
          );
          // mapHelper.lastSearch = result.place_name;
          mapHelper.setSearchLink(
            result.place_name,
            mapHelper.centerPosition[0],
            mapHelper.centerPosition[1],
            true,
            window.location.pathname
          );
          store.dispatch("filter/setSearchString", result.place_name);
          store.dispatch("map/setSearchPosition", result.center);
          store.dispatch("map/setRecommendations");
          store.dispatch("sidebar/showSidebar", true);
          store.dispatch("map/setSidebarContent", "recommendations");
          mapHelper.initMap();
        } else mapHelper.initMap();
      });

    return;
  }

  if (searchParams.has("lng") && searchParams.has("lat")) {
    if (searchParams.has("zoom"))
      mapHelper.zoomLevel = searchParams.get("zoom");
    mapHelper.centerPosition = [
      searchParams.get("lng"),
      searchParams.get("lat"),
    ];
    mapHelper.initMap();
    return;
  }

  if (searchParams.has("id")) {
    // let id = searchParams.get("id");
    let id = searchParams.get("id").split('?')[0];
    store.dispatch("map/setCurrentSegmentId", id);
    store.dispatch("map/setSpotsheet");
    return;
  }

  if (seoHelper.seo_data !== undefined) {
    mapHelper.zoomLevel = (seoHelper.seo_data.seo_page.zoom) ? seoHelper.seo_data.seo_page.zoom : 15;
    mapHelper.centerPosition = [seoHelper.seo_data.seo_page.lng, seoHelper.seo_data.seo_page.lat];
    mapHelper.buildSearchPin(
      seoHelper.seo_data.seo_page.lng,
      seoHelper.seo_data.seo_page.lat,
      seoHelper.seo_data.seo_page.name
    );
    mapHelper.initMap();
    return
  }

  if (mapHelper.centerPosition !== undefined) {
    mapHelper.initMap();
  }
};

MapHelper.prototype.initMap = function () {
  let mapHelper = this;
  mapHelper.mapboxHelper = new MapboxHelper(
    mapboxToken,
    mapHelper.mapSettings,
    "map",
    {},
    mapHelper.zoomLevel,
    mapHelper.centerPosition
  );
  // Attach geocoder to map
  let geocoder = store.getters["filter/geocoder"]
  document.getElementById('geocoder').appendChild(geocoder.onAdd(mapHelper.mapboxHelper.map));
  mapHelper.mapboxHelper.setCanRotate(true);
  mapHelper.mapboxHelper.map.doubleClickZoom.enable();
  mapHelper.mapboxHelper.map.on("style.load", () => mapHelper.reloadTiles());
  // mapHelper.mapboxHelper.map.on("data", (event) =>
  //   mapHelper.mapDataLoaded(event)
  // );
  mapHelper.mapboxHelper.setOnMapReadyListener(() => mapHelper.mapFirstLoad());
  mapHelper.mapboxHelper.setOnSourceDataListener(mapHelper.mapSettings.sources.regulations.url, () => mapHelper.onRegulationsSourceData(mapHelper));

  mapHelper.mapboxHelper.addMapControls()
  TrackHelper.mapOpen()
};

MapHelper.prototype.mapFirstLoad = function () {
  let mapHelper = this;

  console.log("Map Ready!");

  mapHelper.mapboxHelper.setOnMapClickListener((coordinates, feature) =>
    mapHelper.mapClicked(coordinates, feature)
  );
  mapHelper.mapboxHelper.setOnMapMoveListener((e) => mapHelper.mapMoved(e));

  if (store.getters["map/searchPosition"] !== "") {
    mapHelper.setSearchPin();
  }

  window.onpopstate = function (event) {
    mapHelper.popStateHistory(event);
  };
};

MapHelper.prototype.mapClicked = function (coordinates, feature) {
  let mapHelper = this;
  if (feature === null) {
    store.dispatch("sidebar/showSidebar", false);
  } else {
    // City selected. No need to do more
    if (feature.properties.city_name) {
      mapHelper.mapboxHelper.map.flyTo({
        center: [feature.properties.lng, feature.properties.lat],
        zoom: 15,
        bearing: 0,
      });
      return;
    }
    mapHelper.centerPosition = [coordinates.lng, coordinates.lat];
    store.dispatch("map/setSpotFeatures", mapHelper.mapboxHelper.getAllFeatures(feature, true));
    store.dispatch("map/setCurrentSegmentId", feature.id);
    store.dispatch("map/setSpotsheet");
    store.dispatch("sidebar/showSidebar", true);
  }
};

MapHelper.prototype.highlightSelectedFeatures = function (allFeatures) {
  let featureCollection = {
    type: "FeatureCollection",
    features: allFeatures,
  };
  this.mapboxHelper.map
    .getSource("mapbox://hamzao.31hpokxd")
    .setData(featureCollection);
};

MapHelper.prototype.reloadTiles = function () {
  if (this.mapSettings === undefined) return;

  this.mapboxHelper.onFiltersChanged({
    regulationDate: store.getters["filter/getFilterPayload"].arrivalDate,
    regulationEndDate: store.getters["filter/getFilterPayload"].departureDate,
    monthly: store.getters["filter/monthlyMode"],
  });
};

MapHelper.prototype.setSearchPin = function () {
  let mapHelper = this;
  let address = store.getters["filter/getSearchString"];
  let lng = store.getters["map/searchPosition"][0];
  let lat = store.getters["map/searchPosition"][1];
  mapHelper.buildSearchPin(lng, lat, address);
  this.mapboxHelper.map
    .getSource("mapbox://hamzao.cj9rxiu8767zq2ylgfrrmsfkv-9hjpn")
    .setData(mapHelper.searchPin);
};

MapHelper.prototype.clearSearchPin = function () {
  let featureCollection = {
    "type": "FeatureCollection",
    "features": []
  };
  this.mapboxHelper.map.getSource('mapbox://hamzao.cj9rxiu8767zq2ylgfrrmsfkv-9hjpn').setData(featureCollection);
};

MapHelper.prototype.highlightSegment = function (id, moveTo) {
  let feature = this.mapboxHelper.getFeatureById(id);
  if (feature === undefined || feature === null) {
    console.log("Could not find feature for segment", id);
  } else {
    let features = this.mapboxHelper.getAllFeatures(feature, true)
    if (moveTo)
      this.moveMapToFeature(features)
    store.dispatch("map/setSpotFeatures", features);

    // this.highlightSelectedFeatures(features);
  }
};

MapHelper.prototype.setSearchLink = function (address, lng, lat, history) {
  let hash =
    "parking-near=" +
    encodeURIComponent(address.replace(/,/g, " ").replace(/\s+/g, "-"));
  let monthly = store.getters["filter/monthlyMode"] ? '?monthly=true' : ''
  if (history) {
    window.history.pushState(
      {
        current: "#" + hash,
        centerPosition: [lng, lat],
        zoomLevel: this.zoomLevel,
        search: address,
        pin: [lng, lat],
        monthly: store.getters["filter/monthlyMode"],
      },
      "",
      "/" + monthly + "#" + hash
    );
  }
};

MapHelper.prototype.popStateHistory = function (event) {
  if (!event.state || !this.mapboxHelper || !this.mapboxHelper.map) return;

  if (event.state.reload) {
      window.location.pathname = event.state.reload;
  }

  //Label Selected
  if (event.state.centerPosition && event.state.zoomLevel && event.state.id) {
    // console.log('event.state', event.state)
    if (store.getters["filter/monthlyMode"] !== event.state.monthly) {
      store.dispatch("filter/setMonthlyMode", event.state.monthly);
      this.reloadTiles();
    }
    this.zoomLevel = event.state.zoomLevel;
    this.centerPosition = event.state.centerPosition;
    this.labelIdAfterMapMoved = event.state.id;
    this.mapboxHelper.map.flyTo({
      center: this.centerPosition,
      zoom: this.zoomLevel,
      bearing: 0,
    });
    // this.removeSearchPin();
  }

  //Search
  if (
    event.state.centerPosition &&
    event.state.zoomLevel &&
    event.state.search &&
    event.state.pin
  ) {
    this.zoomLevel = event.state.zoomLevel;
    this.centerPosition = event.state.centerPosition;
    this.mapboxHelper.map.flyTo({
      center: this.centerPosition,
      zoom: this.zoomLevel,
      bearing: 0,
    });
    store.dispatch("map/setSearchPosition", event.state.pin);
    store.dispatch("filter/setSearchString", event.state.search);
    if (store.getters["filter/monthlyMode"] !== event.state.monthly) {
      store.dispatch("filter/setMonthlyMode", event.state.monthly);
      this.reloadTiles();
    }
    this.highlightSelectedFeatures([])
    store.dispatch("map/setRecommendations");
    store.dispatch("map/setSidebarContent", "recommendations");
    store.dispatch("sidebar/showSidebar", true);
  }
};

MapHelper.prototype.updateUrlHash = function (hash, id, force) {
  // This is needed otherwise each time user clicks on back, we load the spotsheet, call updateUrlHash and push the same segment to history
  if (hash === window.location.hash.substring(1) && !force) return;

  if (id) {
    let monthly = store.getters["filter/monthlyMode"] ? '?monthly=true' : ''
    let data = {
      current: "#" + hash,
      centerPosition: this.centerPosition,
      zoomLevel: this.zoomLevel,
      id: id,
      monthly: store.getters["filter/monthlyMode"],
    }
    // console.log('Pushing state', data)
    window.history.pushState(data, "", "/" + monthly + "#" + hash);
  }
};

MapHelper.prototype.mapMoved = function (event) {
  if (event.type === "moveend" && this.labelIdAfterMapMoved > 0)
    this.loadLabelAfterMapMoved();

  // if (event.originalEvent) {
  //     $('.noresult-container').hide();
  // }
  this.zoomLevel = this.mapboxHelper.map.getZoom();
  // console.log('map moved setting zoom to', this.zoomLevel)
  this.centerPosition = [
    this.mapboxHelper.map.getCenter().lng,
    this.mapboxHelper.map.getCenter().lat,
  ];
};

MapHelper.prototype.loadLabelAfterMapMoved = function () {
  let id = this.labelIdAfterMapMoved;
  this.labelIdAfterMapMoved = 0;
  store.dispatch("map/setSpotFeatures", []);
  store.dispatch("map/setCurrentSegmentId", id);
  store.dispatch("map/setSpotsheet");
};

MapHelper.prototype.buildSearchPin = function (lng, lat, address) {
  store.dispatch("map/setSearchPosition", [lng, lat]);
  store.dispatch("filter/setSearchString", address);
  let index = address.indexOf(",");
  this.searchPin = {
    type: "Feature",
    properties: {
      type: "place", address: address.substr(
        0,
        index === -1 ? address.length : index
      )
    },
    geometry: {
      type: "Point",
      coordinates: [lng, lat],
    },
  };
};

MapHelper.prototype.mapDataLoaded = function (e) {
  if (
    e.isSourceLoaded &&
    e.dataType === "source" &&
    e.sourceDataType !== "metadata"
  ) {
    if (
      this.mapboxHelper.map.areTilesLoaded() &&
      this.tilesLoadedCallbacks.length
    )
      this.tilesLoadedCallbacks.pop()();
  }
};

MapHelper.prototype.getTrackingSource = function () {
  let urlElements = firstLoadedUrl.split('/').splice(3, 10)
  let cleanUrl = urlElements.join('/')
  if (cleanUrl.includes('google_maps_website')) {
    return 'google-maps'
  }
  let count = urlElements.length;
  if (count === 1 && (urlElements[0] === '' || urlElements[0].includes('#id=') && !urlElements[0].includes('monthly=true')))
    return 'home'
  else if (count === 1 && urlElements[0].includes('monthly=true'))
    return 'monthly'
  else if (count > 1 && urlElements[count - 1].includes('monthly-parking'))
    return ('monthly-' + urlElements[0])
  else if (count > 1 && urlElements[count - 1].includes('-parking'))
    return ('poi-' + urlElements[0])
  else
    return cleanUrl
}

MapHelper.prototype.moveMapToFeature = function (features) {
  let mapHelper = this;
  if (features.length > 0) {
    // Offset is added to compensate for the space taken by the left side bar. The offset unit is pixels.
    let offset = mapHelper.isMobile ? [0, 0] : [100, 0];
    let feature = turf.center(features[0].toJSON());
    mapHelper.mapboxHelper.map.flyTo({
      center: feature.geometry.coordinates,
      offset: offset,
      zoom: 16,
      bearing: 0,
      speed: 0.5
    });
  }
};

MapHelper.prototype.moveToSearchPosition = function () {
  let mapHelper = this;
  let searchPosition = store.getters["map/searchPosition"];
  if (searchPosition !== "") {
    // Offset is added to compensate for the space taken by the left side bar. The offset unit is pixels.
    let offset = mapHelper.isMobile ? [0, 0] : [100, 0];
    mapHelper.mapboxHelper.map.flyTo({
      center: searchPosition,
      offset: offset,
      zoom: 15,
      bearing: 0,
      speed: 0.5
    });
  }
};

MapHelper.prototype.onRegulationsSourceData = function(mapHelper) {
  if (!mapHelper.pendingSourceData) return;
  let selectedId = store.getters["map/currentSegmentId"]
  if (selectedId === null) throw "Pending source data with no selected id";

  let feature = mapHelper.mapboxHelper.getFeatureById(parseInt(selectedId));
  if (feature === null) return;

  let features = this.mapboxHelper.getAllFeatures(feature, true)
  store.dispatch("map/setSpotFeatures", features.concat(store.getters["map/spotFeatures"]));
  mapHelper.pendingSourceData = false;
}

export default MapHelper;
