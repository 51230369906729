<template>
  <ul class="navbar">
    <li>
      <a href="https://v3.spotangels.com/">Home -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/nyc-parking">NYC Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/san-francisco-parking">San Francisco Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/los-angeles-parking">Los Angeles Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/chicago-parking">Chicago Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/boston-parking">Boston Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/washington-dc-parking">Washington DC Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/philadelphia-parking">Philadelphia Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/san-diego-parking">San Diego Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/toronto-parking">Toronto Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/seattle-parking">Seattle Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/atlanta-parking">Atlanta Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/baltimore-parking">Baltimore Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/new-orleans-parking">New Orleans Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/jersey-city-parking">Jersey City Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/hoboken-parking">Hoboken Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/las-vegas-parking">Las Vegas Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/virginia-beach-parking">Virginia Beach Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/miami-parking">Miami Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/monthly-parking">Monthly Parking -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/cities">More cities -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/cities-universities">SpotAngels for Cities -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/about">About -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/blog">Blog -</a>
    </li>
    <li>
      <a
        href="https://www.notion.so/spotangels/SpotAngels-Guides-and-FAQs-968c6626cbec41abb5e7025e7f790f25"
      >FAQ -</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/privacy">Privacy</a>
    </li>
    <li>
      <a href="https://v3.spotangels.com/tos">Terms</a>
    </li>
  </ul>
</template>

<script>
export default {};
</script>

<style>
ul.navbar {
  background: #f5f7f8;
  padding: 10px 20px;
  overflow: hidden;
}
ul.navbar li {
  display: inline-block;
  padding-bottom: 10px;
}
ul.navbar li a {
  color: #8593a1;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
}
ul.navbar li a:hover {
  color: #1c9be6;
}
</style>
