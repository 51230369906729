<template>
  <div class="times_box">
    <div class="main_title">PARKING</div>
    <p>
      <label for="start_date" class="small_title">START DATE</label><br />
      <vuejs-datepicker
        id="start_date"
        v-model="monthlyStartDate"
        class="date_select"
        :disabledDates="disabledDates"
      ></vuejs-datepicker>
    </p>
    <div class="monthly_summary" v-if="deal.discount !== undefined">
      <div class="monthly_summary_title">1st Month Rate</div>
      <div class="monthly_summary_desc"></div>
      <div class="monthly_summary_price">
        <span
          v-bind:class="{ price_strikethrough: deal.discount !== undefined }"
          >${{ deal.price }}</span
        >
        <span style="color:#1C9BE6;" v-if="deal.discount !== undefined">
          ${{ deal.price - deal.discount }}</span
        >
      </div>
    </div>
    <div
      class="summary_rows"
      v-if="deal.deposit_fees !== undefined || deal.setup_fees !== undefined"
    >
      <template v-if="deal.deposit_fees !== undefined">
        <div class="summary_rows_title">Deposit</div>
        <div class="summary_rows_price">{{ deal.deposit_fees }}</div>
      </template>
      <template v-if="deal.setup_fees !== undefined">
        <div class="summary_rows_title">Set-up fees</div>
        <div class="summary_rows_price">{{ deal.setup_fees }}</div>
      </template>
    </div>
    <div class="monthly_summary">
      <div class="monthly_summary_title">{{ title }}</div>
      <div class="monthly_summary_desc">{{ deal.description }}</div>
      <div class="monthly_summary_price">${{ deal.price }}</div>
    </div>
  </div>
</template>

<script>
import vuejsDatepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      monthlyStartDate: new Date(),
    };
  },
  components: {
    vuejsDatepicker,
  },
  props: ["deal"],
  computed: {
    disabledDates: function() {
      let disabledDays = [];
      if (this.deal.monthly_start_days !== undefined) {
        for (let i = 1; i < 32; i++) {
          if (this.deal.monthly_start_days.indexOf(i) === -1)
            disabledDays.push(i);
        }
      }
      return {
        to: new Date(this.deal.monthly_min_start_date),
        from: new Date(this.deal.monthly_max_start_date),
        daysOfMonth: disabledDays,
      };
    },
    title: function() {
      return this.deal.discount === undefined
        ? "Monthly Rate"
        : "Monthly Rate After 1st Month";
    },
  },
};
</script>

<style scoped></style>
