<template>
  <div>
    <MobileBanner v-if="!isDesktop" />
    <DesktopBanner v-if="isDesktop && spotFeatures.length === 0" />
  </div>
</template>

<script>
import DesktopBanner from "./DesktopBanner";
import MobileBanner from "./MobileBanner";
import {mapGetters} from "vuex"
export default {
  props: ["isDesktop"],
  components: {
    DesktopBanner,
    MobileBanner,
  },
  computed: {
    ...mapGetters("map", ["spotFeatures"]),
  },
};
</script>
