<template>
  <div class="reviews">
    <div class="section-title">What {{ reviewType === 'Google' ? 'people' : 'Angels'}} say</div>
    <br>
    <div v-for="(review,index) in selectedReviews" :key="index" class="review">
      <div class="author">{{review.author_name}}</div>
      <div class="rating">
        <Stars :rate="review.rating" />
        <span class="date">{{review.relative_time_description}}</span>
      </div>
      <p class="review-text">{{review.text}}</p>
    </div>
    <span v-if="reviewType === 'Google'" class="powered-by">
      Powered by
      <img :src="require('images/Google_logo.svg')" alt="Google reviews" />
    </span>
<!--    <span>{{data.rating}}</span>-->
  </div>
</template>

<script>
import Stars from "../ui/Stars";
export default {
  props: ["data"],
  data() {
    return {
      selectedReviews: [],
      reviewType: "",
    };
  },
  components: {
    Stars,
  },
  mounted() {
    let spotAngelsReviews = this.data.filter(provider_reviews => provider_reviews.provider_name === 'SpotAngels')
    if (spotAngelsReviews.length > 0 && spotAngelsReviews[0].reviews) {
      this.selectedReviews = spotAngelsReviews[0].reviews
      this.reviewType = 'SpotAngels'
    }
    else {
      let googleReviews = this.data.filter(provider_reviews => provider_reviews.provider_name === 'Google')
      if (googleReviews.length > 0 && googleReviews[0].reviews) {
        this.reviewType = 'Google'
        this.selectedReviews = googleReviews[0].reviews
      }
    }
  },
};
</script>

<style scoped>
.reviews {
  border-bottom: 4px solid #f5f7f8;
  color: #212529;
  font-size: 14px;
  padding: 20px;
}
.powered-by {
  color: #8293a3;
  display: flex;
  padding: 5px 0 10px 0;
}
.powered-by img {
  padding-left: 5px;
}
.section-title,
.author {
  font-weight: 700;
}
.date {
  color: #8293a3;
  padding-left: 5px;
}
.review {
  padding: 10px 0;
}
.review-text {
  line-height: 18px;
}
.rating {
  display: flex;
  padding: 5px 0;
}
</style>