<template>
  <div class="share" @click="openShareModal">
    <div>
      <img :src="require('images/icon-share.svg')" />
    </div>
    <span>
      Share
      <br />spot
    </span>
  </div>
</template>

<script>
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  methods: {
    openShareModal() {
      TrackHelper.shareSpotClicked(window.location.pathname)
      this.$store.dispatch("utils/showShareModal", true);
    },
  },
};
</script>

<style scoped>
.share {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  align-self: flex-end;
}
.share div {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6ab7ec;
  border-radius: 50%;
  height: 38px;
  width: 38px;
}
.share span {
  color: #1c9be6;
  font-size: 12px;
  margin-top: 4px;
  text-align: center;
}
</style>