<template>
  <div class="rating">
    <span>{{ data.rating }}</span>
    <Stars :rate="data.rating" />
    <span>({{ data.user_ratings_total }})</span>
  </div>
</template>

<script>
import Stars from "../ui/Stars";
export default {
  props: ["data"],
  components: {
    Stars,
  },
};
</script>

<style scoped>
.rating {
  display: flex;
  flex-direction: row;
}
.rating span:first-of-type {
  padding-right: 5px;
}
.rating span:last-of-type {
  padding-left: 5px;
}
</style>