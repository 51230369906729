import Vue from "vue/dist/vue.esm";
import Vuex from "vuex";
import map from "./modules/map";
import sidebar from "./modules/sidebar";
import filter from "./modules/filter";
import utils from "./modules/utils";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    map,
    sidebar,
    filter,
    utils,
  },
});

export default store;
