<template>
  <div class="times_box">
    <div class="main_title">RESERVATION TIMES</div>
    <p>
      <label for="start_date" class="small_title">START</label><br />
      <vuejs-datepicker
        id="start_date"
        v-model="time_slot.start_date"
        @input="validateTimeSlot()"
        class="date_select"
        :disabledDates="disabledStartDates"
      ></vuejs-datepicker>
      <select
        id="start_time"
        class="hours_select"
        v-model="time_slot.start_time"
        v-on:change="validateTimeSlot()"
      >
        <option value="" disabled hidden>Choose time</option>
        <option v-for="option in times" v-bind:value="option.value">
          {{ option.text }}
        </option>
      </select>
    </p>
    <p>
      <label for="end_date" class="small_title">END</label><br />
      <vuejs-datepicker
        id="end_date"
        v-model="time_slot.end_date"
        @input="validateTimeSlot()"
        class="date_select"
        :disabledDates="disabledEndDates"
      ></vuejs-datepicker>
      <select
        id="end_time"
        class="hours_select"
        v-model="time_slot.end_time"
        v-on:change="validateTimeSlot()"
      >
        <option value="" disabled hidden>Choose time</option>
        <option
          v-for="option in times"
          :disabled="option.disable"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
    </p>
  </div>
</template>

<script>
import vuejsDatepicker from "vuejs-datepicker";
export default {
  data() {
    return {
      price: 0,
      time_slot: {
        start_date: null,
        end_date: null,
        start_time: null,
        end_time: null,
      },
      times: [],
      validTimeSlot: false,
      disabledStartDates: {
        to: new Date(Date.now() - 86400000),
      },
    };
  },
  components: {
    vuejsDatepicker,
  },
  props: ["params"],
  computed: {
    full_start_date: function() {
      return joinDateTime(this.time_slot.start_date, this.time_slot.start_time);
    },
    full_end_date: function() {
      return joinDateTime(this.time_slot.end_date, this.time_slot.end_time);
    },
    disabledEndDates: function() {
      return {
        to: new Date(this.time_slot.start_date),
      };
    },
  },
  created: function() {
    let vue = this;
    let startDateTime = this.params.startDateTime;
    let endDateTime = this.params.endDateTime;
    vue.time_slot = {
      start_date: extractDate(startDateTime),
      end_date: extractDate(endDateTime),
      start_time: extractTime(startDateTime),
      end_time: extractTime(endDateTime),
    };
    vue.initializeTimes();
    vue.disableInvalidEndTimes();
  },
  mounted: function() {
    this.$root.$emit(
      "timesChangeEvent",
      this.full_start_date,
      this.full_end_date
    );
  },
  watch: {
    validTimeSlot: {
      handler(val) {
        if (val) {
          this.$emit(
            "timesChangeEvent",
            this.full_start_date,
            this.full_end_date
          );
          this.validTimeSlot = false;
        }
      },
    },
  },
  methods: {
    validateTimeSlot: function() {
      if (+this.full_start_date >= +this.full_end_date) {
        let newEndDate = new Date(
          this.full_start_date.getTime() + 2 * 60 * 60 * 1000
        );
        this.time_slot = {
          start_date: this.time_slot.start_date,
          end_date: extractDate(newEndDate),
          start_time: this.time_slot.start_time,
          end_time: extractTime(newEndDate),
        };
      }
      this.disableInvalidEndTimes(this);
      this.validTimeSlot = true;
    },
    initializeTimes: function() {
      // Fill in select times array
      let time = new Date();
      time.setHours(0);
      time.setMinutes(0);
      for (var i = 0; i < 48; i++) {
        var hour = time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
        var option = {};
        option.text = hour;
        option.value = i * 30;
        this.times.push(option);
        time.setMinutes(time.getMinutes() + 30);
      }
    },
    disableInvalidEndTimes: function() {
      this.times = this.times.map((item) => ({
        text: item.text,
        value: item.value,
        disable:
          item.value <= this.time_slot.start_time &&
          sameDay(this.time_slot.start_date, this.time_slot.end_date),
      }));
    },
  },
};
</script>

<style scoped></style>
