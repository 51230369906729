import { render, staticRenderFns } from "./SidebarMobile.vue?vue&type=template&id=2bedca7f&scoped=true&"
import script from "./SidebarMobile.vue?vue&type=script&lang=js&"
export * from "./SidebarMobile.vue?vue&type=script&lang=js&"
import style0 from "./SidebarMobile.vue?vue&type=style&index=0&id=2bedca7f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bedca7f",
  null
  
)

export default component.exports