<template>
    <main>
        <form id="cc-form" class="input-group payment_box">
            <div class="main_title" style="margin: 50px 0 15px;" v-if="$parent.cc_screen">PAY WITH CARD</div>
            <div class="input-row">
                <label for="cc-number" class="small_title">CARD</label><span class="checkout_error"> {{cc_errors.card_number || cc_errors.card_expiration_date || cc_errors.card_cvc}}</span>
                <br>
                <div id="cc-number" class="form-field"><!--VGS Collect iframe for card number field will be here!--></div>
            </div>
            <div style="display:flex; flex-direction: row; justify-content: space-between">
                <div id="cc-expiration-date" class="form-field" style="display:flex; flex-direction: column; width: 110px;" tabindex="-1"><!--VGS Collect iframe for card expiration date field will be here!--></div>
                <div id="cc-cvc" class="form-field" style="display:flex; flex-direction: column; width: 60px;"><!--VGS Collect iframe for card cvv field will be here!--></div>
            </div>
            <div class="input-row">
                <label for="cc-name" class="small_title">NAME</label><span class="checkout_error"> {{cc_errors.card_name}}</span>
                <br>
                <div id="cc-name" class="form-field"><!--VGS Collect iframe for card name field will be here!--></div>
            </div>
            <div class="section-header-footer">
                <img alt="secure" style="width:12px;margin-right:4px" src="https://spotangels-assets.s3.amazonaws.com/secure-payment.png">
                Secure and encrypted payment
            </div>
        </form>

        <button class="payment_pay_button" @click="submitPaymentGuestData" :disabled="isDisabled">{{buttonText}}</button>
    </main>
</template>

<script>
    export default {
        props: [],
        data() {
            return {
                paymentForm: {},
                documentFilled: false,
                isProcessing: false,
                cc_errors : {
                    card_name: '',
                    card_expiration_date: '',
                    card_cvc: '',
                    card_number: '',
                },
                cardType: null,
            };
        },
        computed: {
            buttonText: function () {
              return this.isProcessing ? 'Processing...' : (this.$parent.walletPaymentEnabled) ? 'Pay with Card' : 'Pay and Reserve'
            },
            isDisabled: function() {
              return (!this.$parent.dealFound || this.isProcessing || this.$parent.userFacingError !== '')
            },
        },
        mounted: function () {
            let vue = this;
            var paymentStyles = {
                fontSize: "16px",
                "&::placeholder": {
                    color: "#8293A3",
                    fontSize: "16px"
                }
            };

            var styleCard = {
                color: '#1b1d1f',
                border: 'solid 1px #1b1d1f',
                transition: 'border-color .15s ease-in-out',
                height: '35px',
                borderRadius: '5px',
                paddingLeft: '45px',
                paddingRight: '0',
                boxSizing: 'border-box',
                '&:focus': {
                    borderColor: '#11bef5',
                },
                fontSize: "16px",
                "&::placeholder": {
                    color: "#8293A3",
                    fontSize: "16px"
                },
            };

            var styleName = Object.assign({}, styleCard);
            styleName.background = "url(\"data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAwQzQuNDggMCAwIDQuNDggMCAxMEMwIDE1LjUyIDQuNDggMjAgMTAgMjBDMTUuNTIgMjAgMjAgMTUuNTIgMjAgMTBDMjAgNC40OCAxNS41MiAwIDEwIDBaTTEwIDNDMTEuNjYgMyAxMyA0LjM0IDEzIDZDMTMgNy42NiAxMS42NiA5IDEwIDlDOC4zNCA5IDcgNy42NiA3IDZDNyA0LjM0IDguMzQgMyAxMCAzWk0xMCAxNy4yQzcuNSAxNy4yIDUuMjkgMTUuOTIgNCAxMy45OEM0LjAzIDExLjk5IDggMTAuOSAxMCAxMC45QzExLjk5IDEwLjkgMTUuOTcgMTEuOTkgMTYgMTMuOThDMTQuNzEgMTUuOTIgMTIuNSAxNy4yIDEwIDE3LjJaIiBmaWxsPSIjQ0VENkRFIi8+Cjwvc3ZnPgo=\") no-repeat scroll 10px 7px";

            var styleExpiry = Object.assign({}, styleCard);
            styleExpiry.paddingLeft= '10px';

            // VGS Collect form initialization
            vue.paymentForm = VGSCollect.create(vaultId, function(state) {
                vue.cc_errors = {
                    card_name: '',
                    card_expiration_date: '',
                    card_cvc: '',
                    card_number: '',
                }
            });

            // Create VGS Collect field for credit card number
            this.paymentForm.field('#cc-number', {
                type: 'card-number',
                name: 'card_number',
                successColor: '#212529',
                errorColor: '#FF4541',
                showCardIcon: {
                    left: '5px',
                    top: '17px'
                },
                placeholder: 'Card Number',
                validations: ['required', 'validCardNumber'],
                css: styleCard,
                // autoFocus: true,
                autoComplete: 'cc-number',
            });

            // Create VGS Collect field for credit card name
            this.paymentForm.field('#cc-name', {
                type: 'text',
                name: 'card_name',
                color: '#212529',
                css: styleName,
                placeholder: 'Full Name',
                validations: ['required'],
                autoComplete: 'cc-name',
            });

            // Create VGS Collect field for CVC
            this.paymentForm.field('#cc-cvc', {
                type: 'card-security-code',
                name: 'card_cvc',
                successColor: '#212529',
                errorColor: '#FF4541',
                css: styleExpiry,
                placeholder: 'CVV',
                validations: ['required', 'validCardSecurityCode'],
                autoComplete: 'cc-csc',
            });

            // Create VGS Collect field for credit card expiration date
            this.paymentForm.field('#cc-expiration-date', {
                type: 'card-expiration-date',
                name: 'card_expiration_date',
                successColor: '#212529',
                errorColor: '#FF4541',
                css: styleExpiry,
                placeholder: 'MM / YYYY',
                // validations: ['validCardExpirationDate', 'required', '/^(0[1-9]|1[0-2])\s\/?\s([0-9]{4})$/'],
                validations: ['required', '/^(0[1-9]|1[0-2]).*$/','validCardExpirationDate'],
                autoComplete: 'cc-exp',
            });
        },
        methods: {
            validateCCForm() {
                let vue = this;
                let valid = true;
                if (vue.paymentForm.state.card_number != undefined) {
                    vue.cardType = vue.paymentForm.state.card_number.cardType;
                }
                let leaf;
                for (leaf in vue.paymentForm.state) {
                    let errorMessages = vue.paymentForm.state[leaf].errorMessages;
                    if (errorMessages.length > 0) {
                        vue.cc_errors[leaf] = 'Invalid ' + leaf.replace(/_/g, ' ')
                        valid = false
                    }
                }
                return valid
            },
            submitPaymentGuestData() {
                let vue = this;
                vue.isProcessing = true;
                if (!vue.$parent.checkForm() || !vue.validateCCForm()) {
                    vue.isProcessing = false;
                    return;
                }
                let url = vue.$parent.isMonthly ? '/api/v3/garages/monthly_payment' : '/api/v3/pay_as_guest'
                vue.paymentForm.submit(url, {
                    dataType: 'html',
                    data: vue.$parent.payment_data,
                }, function(status, data) {
                    console.log('API response', data)
                    if (status === 200) {
                        vue.$parent.paymentSuccess = true;
                        vue.$parent.payment_response = data;
                        amplitude.getInstance().logEvent('Booking - Confirm Payment - Tap Pay', vue.$parent.amplitude_info);
                        // If production env
                        gtag('event', 'purchase', vue.$parent.gtag_info);
                        fbq('track', 'Purchase', {currency: "USD", value: vue.$parent.revenue});
                    } else {
                        vue.$parent.paymentFailure = true;
                        vue.$parent.paymentErrorMessage = data.error;
                    }
                    vue.isProcessing = false;
                });
            },
        }
    }
</script>

<style scoped>

</style>