<template>
  <div class="header">
    <div class="breadcrumb">
      <div class="parent"><a :href="angelsWebUrl + seo_data.seo_parent_url.url">{{ seo_data.seo_parent.name === 'NYC' ? 'New York City' : seo_data.seo_parent.name }}</a></div>
      <div class="next">&gt;</div>
      <div class="current">Parking near {{ seo_data.seo_page.name }}</div>
    </div>
  </div>
</template>

<script>
import seoHelper from "../../../utils/seoHelper"

export default {

  data() {
    return {
      angelsWebUrl: angelsWebUrl,
      seo_data: seoHelper.seo_data,
    };
  },
};
</script>

<style scoped>
.breadcrumb {
  font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
  font-size: 12px;
  padding: 8px 12px;
  background-color: #fff;
  text-transform: capitalize;
  border-radius: 4px;
  border-bottom: 1px solid #ced6de;
}

.breadcrumb .parent, .breadcrumb .parent a {
  display: inline-block;
  color: #1c9be6;
}

.breadcrumb .next {
  display: inline-block;
  color: #8593a1;
}

.current {
  display: inline-block;
  color: #8593a1;
}
</style>