import RegulationsHelper from "../../utils/regulationsHelper";
import { onBlockfaceOccupancyError } from "../../utils/availabitityHelper";
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  namespaced: true,
  state: {
    spotSheet: "",
    recommendationList: "",
    mapClass: null,
    searchPosition: "",
    sidebarContent: "",
    currentSegmentId: null,
    currentHistogram: "",
    spotFeatures: [],
  },
  mutations: {
    setRecommendations(state, payload) {
      RegulationsHelper.getRecommendations(
        state.searchPosition[0],
        state.searchPosition[1],
        this.getters["filter/getFilterPayload"].arrivalDate,
        this.getters["filter/getFilterPayload"].departureDate,
        this.getters["filter/monthlyMode"],
        payload.onRecommendationsGetSuccess,
        payload.onRecommendationsGetFailure
      );
      // state.sidebarContent = "recommendations";
    },
    setBlockFace(state, payload) {
      state.currentHistogram = payload;;
    },
    storeMapClass(state, payload) {
      state.mapClass = payload;
    },
    setSearchPosition(state, payload) {
      state.searchPosition = payload;
    },
    setMapClassProperty(state, payload) {
      state.mapClass[payload.property] = payload.value;
    },
    setSpotSheet(state, payload) {
      state.spotSheet = payload;
    },
    setRecommendationList(state, payload) {
      state.recommendationList = payload;
    },
    setCurrentSegmentId(state, payload) {
      state.currentSegmentId = payload;
    },
    setCurrentHistogram(state, payload) {
      state.currentHistogram = payload;
    },
    setSidebarContent(state, payload) {
      state.sidebarContent = payload;
    },
    setSpotFeatures(state, payload) {
      state.spotFeatures = payload;
    },
  },
  actions: {
    setBlockFace(context, payload) {
      RegulationsHelper.getSegmentOccupancy(
        payload,
        new Date(this.getters["filter/getDepartureDate"]).getDay(),
        0,
        (request, result) => {
          context.commit("setBlockFace", result.histogram);
        },
        onBlockfaceOccupancyError
      );
    },
    storeMapClass(context, payload) {
      context.commit("storeMapClass", payload);
    },
    setSearchPosition(context, payload) {
      context.commit("setSearchPosition", payload);
    },
    setMapClassProperty(context, payload) {
      context.commit("setMapClassProperty", payload);
    },
    setSpotsheet(context, payload) {
      let store = this
      context.commit("setSpotSheet", "");
      const onSegmentGetSuccess = function(request, data) {
        context.commit("setSpotSheet", data);
        context.commit("setSidebarContent", "spotsheet");
        context.commit("sidebar/showSidebar", true, { root: true })
        TrackHelper.spotOpened(data, store.getters["filter/monthlyMode"])
        if (data.status.name !== 'Garage')
          context.dispatch("setBlockFace", data.status.id)
      };
      const onSegmentFailure = function(r, e) {
        console.log("failure to retrieve segment", e); //TODO handle error
      };
      RegulationsHelper.getSpotInfo(
        context.state.currentSegmentId,
        store.getters["filter/getFilterPayload"].arrivalDate,
        store.getters["filter/getFilterPayload"].departureDate,
        store.getters["filter/monthlyMode"],
        onSegmentGetSuccess,
        onSegmentFailure
      );

    },
    setRecommendations(context, payload) {
      context.commit("setRecommendationList", "");
      const onRecommendationsGetSuccess = function(request, data) {
        context.commit("setRecommendationList", data);
        context.commit("setSpotSheet", "");
      };
      const onRecommendationsGetFailure = function(r, e) {
        console.log("failure to retrieve recommendations"); //TODO handle error
      };
      context.commit("setRecommendations", {
        onRecommendationsGetSuccess,
        onRecommendationsGetFailure,
      });
    },
    reloadTiles(context, payload) {
      context.state.mapClass.reloadTiles();
    },
    setCurrentSegmentId(context, payload) {
      context.commit("setCurrentSegmentId", payload);
    },
    setCurrentHistogram(context, payload) {
      context.commit("setCurrentHistogram", payload);
    },
    setSidebarContent(context, payload) {
      context.commit("setSidebarContent", payload);
    },
    setRecommendationList(context, payload) {
      context.commit("setRecommendationList", payload);
    },
    setSpotFeatures(context, payload) {
      context.commit("setSpotFeatures", payload);
    },
  },
  getters: {
    spotSheet: (state) => {
      return state.spotSheet;
    },
    mapClass: (state) => {
      return state.mapClass;
    },
    recommendationList: (state) => {
      return state.recommendationList;
    },
    sidebarContent: (state) => {
      return state.sidebarContent;
    },
    getMapClassProperty: (state) => (property) => {
      return state.mapClass[property];
    },
    searchPosition: (state) => {
      return state.searchPosition;
    },
    currentSegmentId: (state) => {
      return state.currentSegmentId;
    },
    currentHistogram: (state) => {
      return state.currentHistogram;
    },
    spotFeatures: (state) => {
      return state.spotFeatures;
    },
  },
};
