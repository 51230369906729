<template>
  <div>
    <RequestMonthlyParking v-if="monthlyRequested" :data="data" />
    <div v-else>
      <div class="park-summary">
        <div class="park-summary-wrapper">
          <img
            class="spot-icon"
            :src="require('images/garage.svg')"
          />
          <div class="meta">
            <Summary
              v-if="data.status && data.status.summary"
              :data="data.status.summary"
            />
            <Rating
              v-if="data.review_data && data.review_data[0].reviews"
              :data="data.review_data[0]"
            />
            <a v-if="data.status.show_operator_url === true && data.status.operator_url"
               @click="logWebsiteClicked"
               :href="data.status.operator_url" target="_blank">Website</a>
          </div>
          <Share />
        </div>

      </div>
      <template v-if="!monthlyMode">
        <GarageHourlyDeals
          v-if="data.status.deals"
          :status="data.status"
          :review_avg="reviewsAvg"
        />
        <GarageMonthlyDeals
          v-else-if="data.status.monthly_deals"
          :deals="data.status.monthly_deals"
          :status="data.status"
          :review_avg="reviewsAvg"
        />
      </template>
      <div v-if="monthlyMode">
        <GarageMonthlyDeals
          v-if="data.status.deals"
          :deals="data.status.deals"
          :status="data.status"
          :review_avg="reviewsAvg"
        />
        <GarageRequest
          v-else-if="
            data.status.monthly_full === true ||
              data.status.receive_requests === true
          "
          :monthlyFull="data.status.monthly_full"
          :receiveRequests="data.status.receive_requests"
          @monthlyRequestedEvent="(value) => (monthlyRequested = value)"
        />
      </div>
      <Pictures v-if="data.pictures.length > 0" :data="data.pictures" />
      <OpeningHours
        v-if="data.calendar && data.calendar.length"
        :data="data.calendar"
      />
      <Rates
        v-if="data.garage_info && data.garage_info.length"
        :data="data.garage_info"
        :monthlyMode="monthlyMode"
      />
      <ContributionStatus
        v-if="data.crowdsource && data.crowdsource.contribution_status"
        :data="data.crowdsource.contribution_status"
      />
      <MonthlyOptionsButton
        v-if="!monthlyMode && data.status.has_monthly_options"
        :segment-id="data.status.id"
        :city-id="data.status.city_id"
      />
      <Reviews
        v-if="data.review_data"
        :data="data.review_data"
      />
      <Address
        v-if="data.status && data.status.address"
        :data="data.status.address"
      />
      <!-- <h1>{{data.status.name}} {{getMapClassProperty("isCurrentSegmentGarage")}}</h1> -->
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import GarageMonthlyDeals from "./GarageMonthlyDeals";
import GarageHourlyDeals from "./GarageHourlyDeals"
import GarageRequest from "./GarageRequest";
import RequestMonthlyParking from "./RequestMonthlyForm";
import Rating from "./Rating";
import OpeningHours from "./OpeningHours";
import Rates from "./Rates";
import Reviews from "./Reviews";
import Address from "./Address";
import ContributionStatus from "./ContributionStatus";
import Summary from "./Summary";
import Pictures from "./Pictures";
import Share from "../ui/Share";
import MonthlyOptionsButton from "./MonthlyOptionsButton";
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  components: {
    GarageHourlyDeals,
    GarageMonthlyDeals,
    GarageRequest,
    Share,
    Rating,
    Reviews,
    Address,
    OpeningHours,
    Rates,
    ContributionStatus,
    Summary,
    RequestMonthlyParking,
    Pictures,
    MonthlyOptionsButton,
  },
  props: ["data"],
  data() {
    return {
      segment: {},
      monthlyRequested: false,
    };
  },
  methods: {
    logWebsiteClicked() {
      TrackHelper.operatorWebsiteClicked(this.data.status)
    }
  },
  computed: {
    reviewsAvg() {
      let reviews_avg = "''";
      if (this.data.review_data && this.data.review_data[0].reviews) {
        reviews_avg = this.data.review_data['0'].rating;
      }
      return reviews_avg
    },
    ...mapGetters("map", ["getMapClassProperty"]),
    ...mapGetters("filter", ["monthlyMode"]),
  },
  // mounted() {
  //   this.$store.dispatch("map/setMapClassProperty", {
  //     property: "isCurrentSegmentGarage",
  //     value: true,
  //   });
  // },
};
</script>

<style scoped>
.park-summary {
  border-bottom: 4px solid #f5f7f8;
}

.park-summary-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
}
@media (max-width: 767px) {
  .park-summary-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 25px 5px 16px;
  }
}

.park-summary .meta > div {
  padding-bottom: 5px;
}

.meta {
  width: 85%;
}
.meta h3 {
  color: #545e69;
  font-weight: bold;
  padding-bottom: 5px;
}

.title {
  color: #545e69;
  font-weight: bold;
  margin: 10px 0;
}

.toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 55px;
  left: 0;
  background: white;
  border-radius: 0 3px 3px 0;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 22px;
  height: 50px;
}

.toggle img {
  height: 14px;
}

@keyframes slide-open {
  0% {
    left: -395px;
  }
  100% {
    left: 0;
  }
}

@keyframes slide-close {
  0% {
    left: 0;
  }
  100% {
    left: -395px;
  }
}

a {
  pointer-events: auto !important;
  cursor: pointer !important;
  color: #1C9BE6 !important;
  text-decoration: underline !important;
}

.spot-icon {
  width: 20px;
  height: 20px;
  padding-right: 2px;
}
</style>
