<template>
  <div id="map"></div>
</template>

<script>
import {mapGetters} from "vuex";
import MapHelper from "../../utils/mapHelper";
import TrackHelper from "../../utils/analytics/amplitude"
import * as turf from "@turf/turf"

export default {
  data() {
    return {
      mapClass: "",
    };
  },
  mounted() {
    const mapHelper = new MapHelper();
    this.$store.dispatch("map/storeMapClass", mapHelper);
    this.mapClass = mapHelper;
    mapHelper.initStartPosition();
  },
  // created() {
  //   let liveMap = this;
  //   window.onpopstate = function (event) {
  //     liveMap.mapClass.popStateHistory(event);
  //   };
  // },
  watch: {
    currentSegmentId: function (val) {
      if (this.mapClass.mapboxHelper !== undefined) {
        let moveTo = this.sidebarContent === 'recommendations'
        this.mapClass.highlightSegment(val, moveTo)
      }
    },
    spotFeatures: function (val) {
      if (!this.mapClass.pendingSourceData && val.length > 0)
        this.mapClass.highlightSelectedFeatures(val)
    },
    spotSheet: function (val) {
      let firstLoad = this.mapClass.mapboxHelper === undefined

      if (val.status && val.status.entrances)
        this.addEntrancesFeature(val.status.entrances, val.status.lng, val.status.lat)

      if (firstLoad) {
        if (val === "") {
          TrackHelper.errorInitSegment(this.currentSegmentId)
          this.mapClass.initMap();
          return
        }
        this.mapClass.zoomLevel = 15;
        this.mapClass.centerPosition = [val.status.lng, val.status.lat];
        this.mapClass.pendingSourceData = true;
        this.mapClass.initMap();
      }

      if (val.status && val.status.address) {
        let hash = "id=" + val.status.id + "&address=" + encodeURIComponent(val.status.address)
        this.mapClass.updateUrlHash(hash, val.status.id, firstLoad);
      }

    },
    sidebarContent: function (val) {
      if (val === 'recommendations') {
        this.mapClass.moveToSearchPosition()
      }
    },
    // recommendationList: function (val) {
    //   if (val !== '') {
    //     this.$store.dispatch("sidebar/showSidebar", true);
    //     this.mapClass.setSearchPin()
    //   }
    // },
  },
  methods: {
    addEntrancesFeature: function(entrances, lng, lat) {
      let entranceFeatures = []
      let centroid = [lng, lat]
      for (let i = 0; i < entrances.length; i++) {
        let coordinates = [entrances[i].lng, entrances[i].lat];
        let azimuth = turf.bearing(turf.point(coordinates), centroid);
        let entranceFeature = {
          type: "Feature",
          geometry: {type: "Point", coordinates: coordinates},
          properties: {type: "entrance", azimuth: azimuth - 90},
        };
        entranceFeatures.push(entranceFeature);
      }
      this.$store.dispatch("map/setSpotFeatures", this.spotFeatures.concat(entranceFeatures));
    }
  },
  computed: {
    ...mapGetters("utils", ["showShareModal"]),
    ...mapGetters("sidebar", ["showSidebar"]),
    ...mapGetters("map", ["spotSheet", "recommendationList", "currentSegmentId", "sidebarContent", "spotFeatures"]),
  },
};
</script>

<style scoped>
@media (max-width: 767px) {
  #map {
    height: 100vh;
    position: absolute;
    width: 100vw;
    top: 0 !important;
  }
}

#map {
  position:absolute;
  top:92px;
  bottom:0;
  width:100%;
}

</style>

<style>
.mapboxgl-ctrl-top-right {
  margin-right: 10px;
}

@media (max-width: 767px) {
  .mapboxgl-ctrl-top-right {
    margin-top: 220px;
  }
}

.mapboxgl-style-switcher {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTQuODQ5cHgiIGhlaWdodD0iNTQuODQ5cHgiIHZpZXdCb3g9IjAgMCA1NC44NDkgNTQuODQ5IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NC44NDkgNTQuODQ5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PGc+PHBhdGggZD0iTTU0LjQ5NywzOS42MTRsLTEwLjM2My00LjQ5bC0xNC45MTcsNS45NjhjLTAuNTM3LDAuMjE0LTEuMTY1LDAuMzE5LTEuNzkzLDAuMzE5Yy0wLjYyNywwLTEuMjU0LTAuMTA0LTEuNzktMC4zMThsLTE0LjkyMS01Ljk2OEwwLjM1MSwzOS42MTRjLTAuNDcyLDAuMjAzLTAuNDY3LDAuNTI0LDAuMDEsMC43MTZMMjYuNTYsNTAuODFjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMEw1NC40ODgsNDAuMzNDNTQuOTY0LDQwLjEzOSw1NC45NjksMzkuODE3LDU0LjQ5NywzOS42MTR6Ii8+PHBhdGggZD0iTTU0LjQ5NywyNy41MTJsLTEwLjM2NC00LjQ5MWwtMTQuOTE2LDUuOTY2Yy0wLjUzNiwwLjIxNS0xLjE2NSwwLjMyMS0xLjc5MiwwLjMyMWMtMC42MjgsMC0xLjI1Ni0wLjEwNi0xLjc5My0wLjMyMWwtMTQuOTE4LTUuOTY2TDAuMzUxLDI3LjUxMmMtMC40NzIsMC4yMDMtMC40NjcsMC41MjMsMC4wMSwwLjcxNkwyNi41NiwzOC43MDZjMC40NzcsMC4xOSwxLjI1MSwwLjE5LDEuNzI5LDBsMjYuMTk5LTEwLjQ3OUM1NC45NjQsMjguMDM2LDU0Ljk2OSwyNy43MTYsNTQuNDk3LDI3LjUxMnoiLz48cGF0aCBkPSJNMC4zNjEsMTYuMTI1bDEzLjY2Miw1LjQ2NWwxMi41MzcsNS4wMTVjMC40NzcsMC4xOTEsMS4yNTEsMC4xOTEsMS43MjksMGwxMi41NDEtNS4wMTZsMTMuNjU4LTUuNDYzYzAuNDc3LTAuMTkxLDAuNDgtMC41MTEsMC4wMS0wLjcxNkwyOC4yNzcsNC4wNDhjLTAuNDcxLTAuMjA0LTEuMjM2LTAuMjA0LTEuNzA4LDBMMC4zNTEsMTUuNDFDLTAuMTIxLDE1LjYxNC0wLjExNiwxNS45MzUsMC4zNjEsMTYuMTI1eiIvPjwvZz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  opacity: 0.3;
}

.mapboxgl-ctrl-compass {
  display: none !important;
}

.mapboxgl-style-switcher.active {
  opacity: 1.0;
}
</style>
