<template>
  <div class="container animate" :class="showSidebar === true ? 'container--show' : 'container--hide'"
       id="sidebar-mobile">
    <div class="wrapper">
    <div class="direction-arrow">
      <img :src="require('images/rectangle.svg')" alt="">
<!--      <img v-if="direction === 'down'" :src="require('images/mobile-up.png')" alt="">-->
<!--      <img v-if="direction === 'up'" :src="require('images/mobile-down.png')" alt="">-->
      <img
        @click="toggleSidebar"
        class="toggle"
        :src="require('images/icon-close.svg')"
        alt="close sidebar"
      />
    </div>
      <div
        v-if="showBackToListButton"
        class="back-link"
        @click="showRecommendations"
      >
        <img
          :src="require('images/icon-arrow-left-blue.svg')"
          alt="Back to suggestions"
        />
        <span>Show list</span>
      </div>
      <Spotsheet v-if="spotSheet !== '' && (sidebarContent === 'spotsheet' || sidebarContent === 'monthlyForm')" :data="spotSheet" />
      <Poi v-else-if="sidebarContent === 'seo'"/>
      <Recommendations v-else-if="recommendationList !== '' && sidebarContent === 'recommendations'" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spotsheet from "../sidebar/Spotsheet";
import Recommendations from "../sidebar/Recommendations";
import TrackHelper from "../../utils/analytics/amplitude";
import Poi from "../sidebar/Poi"
import seoHelper from "../../utils/seoHelper"

export default {
  components: {
    Recommendations,
    Spotsheet,
    Poi,
  },
  data() {
    return {
      segment: {},
      transform: "",
      lastPosition: 0,
      startPosition: 0,
      endPosition: 0,
      sidebarPosition: 0,
      direction: 'down'
    };
  },
  mounted() {
    if (seoHelper.seo_data !== undefined) {
      this.$store.dispatch("map/setSidebarContent", "seo");
      this.$store.dispatch("sidebar/showSidebar", true);
    }

    const sidebar = document.getElementById("sidebar-mobile");
    const header = document.getElementById("header-mobile");

    let currentPosition = 0;
    if (sidebar) {
      if (this.spotSheet !== "")
        this.adjustHeightToDeals(this.spotSheet)
      // Add listeners
      sidebar.addEventListener("touchstart", (e) => {
        currentPosition = Math.round(e.changedTouches[0].clientY);
        this.startPosition = currentPosition;
        this.sidebarPosition = sidebar.getBoundingClientRect().y
        sidebar.classList.remove("animate");
      });
      sidebar.addEventListener("touchmove", (e) => {
        currentPosition = Math.round(e.changedTouches[0].clientY);
        if (currentPosition !== this.lastPosition) {
        const delta = currentPosition - this.startPosition;
        const direction = currentPosition - this.startPosition > 0 ? 'down': 'up'
        if(direction === 'up'){
          const deltaUp =  this.sidebarPosition - (-1 * delta)
          sidebar.style.transform = "translateY(" + deltaUp + "px)"
        }else if (direction === 'down'){
          const deltaDown =  this.sidebarPosition + delta
          sidebar.style.transform = "translateY(" + deltaDown + "px)"
        }
        }
        this.lastPosition = currentPosition;
      });
      sidebar.addEventListener("touchend", (e) => {
        sidebar.classList.add("animate");
        currentPosition = Math.round(e.changedTouches[0].clientY);
        // If user taps on the sidebar, don't do anything. This resolves the issue where user tries to close the sidebar with the cross but instead the share spot is triggered
        if (currentPosition === this.startPosition)
          return
        this.sidebarPosition = sidebar.getBoundingClientRect().y
        const position = this.sidebarPosition
        this.direction = currentPosition - this.startPosition > 0 ? 'down': 'up';
        sidebar.classList.add("animate");

        if (position > 50 && position < 170 && this.direction === 'up') {
            const transform = "translateY(" + 10 + "%)";
            sidebar.style.transform = transform;
            header.style.zIndex = 4;
            TrackHelper.mobileSlideUp();
          }     
             
          if (position > 170 && this.direction === 'up') {
            const transform = "translateY(" + 10 + "%)";
            sidebar.style.transform = transform;
            header.style.zIndex = 4;
            TrackHelper.mobileSlideUp();
            return
          }

          // Sidebar expanded and user pushes it down but not enough to move it. Change the direction to up in order to keep aspect of arrow
          if (position < 170 && this.direction === 'down') {
            const transform = "translateY(" + 10 + "%)";
            sidebar.style.transform = transform;
            this.direction = 'up'
          }

          if (position > 170 && position < 600 && this.direction === 'down') {
            const transform = "translateY(" + 70 + "%)";
            sidebar.style.transform = transform;
            header.style.zIndex = 9;
          }

          const wrapper = document.querySelector('.wrapper')
          if(wrapper.clientHeight - Math.abs(position) < document.body.clientHeight - 50 && this.direction === 'up' ){
            const offset =  wrapper.clientHeight >= 900 ? -90 : -15
            const transform = "translateY(" + offset + "%)";
            sidebar.style.transform = transform;
          }

      });
    }
  },
  computed: {
    ...mapGetters("utils", ["showShareModal"]),
    ...mapGetters("sidebar", ["showSidebar"]),
    ...mapGetters("map", ["spotSheet", "recommendationList", "sidebarContent"]),
    showBackToListButton() {
      return this.spotSheet !== '' && this.sidebarContent === 'spotsheet' && this.recommendationList !== '' && seoHelper.seo_data === undefined
    },
  },
  watch: {
    spotSheet: function(val) {
      this.adjustHeightToDeals(val)
    },
  },
  methods: {
    adjustHeightToDeals(spotSheetData) {
      const sidebar = document.getElementById("sidebar-mobile");
      if (sidebar) {
        let offset = 65
        // We want the initial position of the sidebar to show all deals. 75px per additional deal.
        if (spotSheetData.status !== undefined) {
          let dealCount = 1
          if (spotSheetData.status.monthly_deals)
            dealCount = spotSheetData.status.monthly_deals.length
          if (spotSheetData.status.deals)
            dealCount = spotSheetData.status.deals.length
          const clientHeight = document.getElementById("data").clientHeight
          offset -= (dealCount - 1) * (75*100/clientHeight)
        }
        const transform = "translateY(" + offset + "%)";
        sidebar.style.transform = transform;
      }
    },
    toggleSidebar() {
      this.$store.dispatch("sidebar/toggleSidebar");
    },
    showRecommendations() {
      this.$store.dispatch("map/setSidebarContent", "recommendations");
    },
  },
};
</script>

<style scoped>
.animate {
  transition: transform 0.3s ease-out;
}
.container {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 4;
  font-size: 14px;
  color: #8293a3;
  width: 100%;
  height: 0;
  background: white;
}
.hide-sidebar {
  height: 0;
}
.container--show {
  height: 100%;
  transform: translateY(70%);
  animation: slide-open 0.5s ease-in;
  animation-fill-mode: forwards;
}

.container--hide {
  transform: translateY(10%);
  animation: slide-close 0.2s ease-in;
  animation-fill-mode: forwards;
}

.wrapper {
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  overflow: hidden;
  /* overflow-x: hidden;
  overflow-y: auto; */
  padding-top: 5px;
  width: 100%;
  /* height: 100vh; */
}
.direction-arrow .toggle {
  position: absolute;
  top: 2px;
  right: 2px;
  margin-left: auto;
  width: 20px !important;
  cursor: pointer;
}
/* @keyframes slide-open {
  0% {
    bottom: -200px;
  }
  100% {
    bottom: 0;
  }
}
@keyframes slide-close {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -200px;
  }
} */
.back-link {
  position: absolute;
  top: -40px;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  padding: 9px 15px 7px;
  margin: 0 15px;
  background-color: white;
  border-radius: 24px;
  color: #545E69;
  box-shadow: 0 0 10px #545E69;
 -webkit-box-shadow: 0 0 10px #545E69;
  white-space: nowrap;
  width: 105px;
}
.back-link img {
  height: 12px;
  padding-right: 5px;
}
.back-link span:hover {
  text-decoration: underline;
}
.direction-arrow {
  display: flex;
  justify-content: center;
}
.direction-arrow img {
  width: 35px;
}
</style>
