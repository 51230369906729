<template>
  <div class="splash-screen" @click="closeSplashScreen">
    <h2 class="header">
      Get $2 credit with promocode WELCOME<br /><br />Download the App now!
    </h2>
    <div class="store">
      <a
        href="http://download.spotangels.com/M3e9hc6MtO"
        @click.stop="branchTagsClick"
        class="cta"
      >
        <img
          :src="require('images/download-SpotAngels-iphone-app.png')"
          alt="Apple store"
        />
      </a>
      <a
        href="http://download.spotangels.com/M3e9hc6MtO"
        @click.stop="branchTagsClick"
        class="cta"
      >
        <img :src="require('images/playstore.png')" alt="Play store" />
      </a>
    </div>
    <p class="description">
      or go to the
      <a @click="closeSplashScreen">mobile site</a>
    </p>
  </div>
</template>

<script>
import branchTags from "../../utils/branchTags";
export default {
  methods: {
    closeSplashScreen() {
      this.$emit("onCloseSplashScreenEvent");
    },
    branchTagsClick(element) {
      branchTags(element.target.href);
    },
  },
};
</script>

<style scoped>
img{
  pointer-events: none;
}
.splash-screen {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 3rem;
  text-align: center;
}
.splash-screen .header {
  color: #545e69;
  font-size: 24px;
  font-weight: normal;
  margin: 0;
}
.splash-screen .description {
  color: #8293a3;
  font-size: 16px;
}
.splash-screen .description a {
  color: #1c9be6;
  text-decoration: underline;
}
.splash-screen .store {
  display: flex;
  margin: 2rem 0;
}
.splash-screen .store img {
  width: 150px;
}
.splash-screen .store img:first-of-type {
  padding-right: 5px;
}
</style>
