<template>
  <div>
    <div v-for="(month,index) in data" :key='index'>
      <div class="regulation-month">
        <div class="regulation-month-title">{{ month.title }}</div>
        <div v-for="(day,index) in month.days" :key='index' class="regulation-details">
          <span class="regulation-days">{{ day.title }}</span>
          <span class="regulation-hours">
                 <div v-for="(hour,index) in day.hours" :key='index'>
                   {{ hour }}<br>
                 </div>
                </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.park-regulation {
  color: #8293a3;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 16px 0;
}
.regulation-title {
  font-weight: bold;
  color: #545E69;
  /* padding-bottom: 3px; */
}
.regulation-month {
  margin-bottom: 12px;
}
.regulation-month-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
}

.regulation-details {
  display: flex;
  justify-content: space-between;

}

.regulation-month-title {
  font-weight: bold;
  color: #8293A3;
}
</style>