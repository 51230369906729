<template>
  <div class="park-regulation">
    <div class="regulation-title">{{holidays.title}}</div>
    <div class="regulation-month">
      <div v-for="(day, index) in holidays.days" :key='index'>
        <span class="regulation-days">{{day}}</span>
        <div style="clear:both;"></div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: ["holidays"],
};
</script>

<style scoped>
.park-regulation {
  color: #8293a3;
  font-size: 14px;
  line-height: 20px;
  padding: 0 16px;
}
.regulation-title {
  font-weight: bold;
  color: #545E69;
  /* padding-bottom: 3px; */
}
.regulation-month {
  margin-bottom: 12px;
}

</style>