function SatelliteLayerControl() {
    this.satellite_view = false;
    this.satellite_loaded = false;
}

SatelliteLayerControl.prototype.onAdd = function(map) {
    var satelliteLayerCtrl = this;
    this.map = map;

    this.controlContainer = document.createElement("div");
    this.controlContainer.classList.add("mapboxgl-ctrl");
    this.controlContainer.classList.add("mapboxgl-ctrl-group");
    this.styleButton  = document.createElement("button");

    this.styleButton .addEventListener("click", function() {
        satelliteLayerCtrl.toggleSatelliteView();
    });

    this.styleButton .classList.add("mapboxgl-ctrl-icon");
    this.styleButton .classList.add("mapboxgl-style-switcher");
    this.controlContainer.appendChild(this.styleButton );

    return this.controlContainer;
};

SatelliteLayerControl.prototype.onRemove = function() {
    this._container.parentNode.removeChild(this.controlContainer);
    this.map = undefined;
};

SatelliteLayerControl.prototype.toggleSatelliteView = function() {
    if (this.satellite_view === false) {
        this.enableSatelliteView();
    } else {
        this.disableSatelliteView();
    }
};

SatelliteLayerControl.prototype.enableSatelliteView = function() {
    this.loadSatelliteLayer();
    var layers = this.map.getStyle().layers;
    for (var i = 0; i < layers.length; i++) {
        // if (layers[i].source === "mapbox://mapbox.mapbox-streets-v7") {
        //     this.map.setLayoutProperty(layers[i].id, 'visibility', 'none');
        // }
        if (layers[i].source === "mapbox-satellite") {
            this.map.setLayoutProperty(layers[i].id, 'visibility', 'visible');
        }
    }
    this.styleButton.classList.add("active");
    this.satellite_view = true;
};

SatelliteLayerControl.prototype.disableSatelliteView = function() {
    var layers = this.map.getStyle().layers;
    for (var i = 0; i < layers.length; i++) {
        if (layers[i].source === "mapbox://mapbox.mapbox-streets-v7") {
            this.map.setLayoutProperty(layers[i].id, 'visibility', 'visible');
        }
        if (layers[i].source === "mapbox-satellite") {
            this.map.setLayoutProperty(layers[i].id, 'visibility', 'none');
        }
    }
    this.styleButton.classList.remove("active");
    this.satellite_view = false;
};


SatelliteLayerControl.prototype.loadSatelliteLayer = function() {
    if (this.satellite_loaded === true)
        return;
    var layers = this.map.getStyle().layers;

    this.map.addSource("mapbox-satellite", {
        "type": "raster",
        "url": "mapbox://mapbox.satellite",
        "tileSize": 256
    });
    this.map.addLayer({
        "type": "raster",
        "id": 'satellite-map',
        "source": "mapbox-satellite",
        "opacity": 1
    }, layers[1].id);
    this.satellite_loaded = true;
};

export {SatelliteLayerControl}