<template>
  <div class="park-regulation">
    <div class="regulations-pictures">
      <img v-for="(item, index) in data" class="regulation-picture" :src="item.thumbnail_url" :key="index" @click="openGallery(index)">
    </div>
    <LightBox ref="lightbox" :media="media" :showLightBox="false" :showCaption="true"></LightBox>
  </div>
</template>

<script>

require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
import LightBox from 'vue-image-lightbox'
export default {
  props: ["data"],
  components: {
    LightBox,
  },
  data() {
    return {
      showLightbox: false,
    };
  },
  computed : {
    media() {
      let result = []
      this.data.forEach((item) => {
        let element = {
          thumb: item.thumbnail_url,
          src: item.picture_url,
          caption: '<div style="padding-top:10px">' + item.created_at_by + '</div>'
        }
        result.push(element)
      })
      return result
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  },
};
</script>

<style scoped>
.park-regulation {
  color: #8293a3;
  font-size: 14px;
  line-height: 20px;
  padding: 20px;
  border-bottom:4px solid #f5f7f8;
}
.regulations-pictures {
  height: 175px;
  overflow-x: auto;
  white-space: nowrap;
  background: white;
}

.regulation-picture {
  width: 152px;
  height: 152px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 8px;
  display: inline-block;
  object-fit: cover;
  cursor: pointer;
}

img {
  vertical-align: middle;
  border: 0;
}

</style>