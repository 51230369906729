<template>
    <div>
        <div class="summary">
            <div class="summary_price">
                <span v-bind:class="{ price_strikethrough: (discount !== undefined) }">{{displayPrice}}</span>
                <span style="color:#1C9BE6;" v-if="discount !== undefined"> {{newPrice}}</span>
            </div>
            <div class="summary_description_slot" style="justify-self: end; font-weight: bold">{{firstLine}}</div>
            <div class="summary_description_details" style="justify-self: end;">{{secondLine}}</div>
        </div>
        <div class="monthly_checkout_msg">{{note1}}</div>
        <div class="monthly_checkout_msg">{{note2}}</div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            };
        },
        computed : {
          displayPrice : function() {
              return this.$parent.isDownPayment ? this.$parent.deal.down_payment_fmt : '$' + this.price
          },
            newPrice : function() {
              return this.$parent.deal.finalPrice
            },
            firstLine : function() {
                return this.$parent.isDownPayment ? '50% Down Payment' : this.description
            },
            secondLine : function() {
                return this.$parent.isDownPayment ? 'You will pay the remainder to our partner - free cancellation' : 'Free cancellation'
            },
            note2 : function() {
                return (this.checkout_msg) ? '*'+this.checkout_msg : ''
            },
            note1 : function() {
                return !this.$parent.isDownPayment ? 'A hold will be placed on your credit card but it won’t be charged. You will be able to set-up billing with our partner.' : ''
            },
        },

        props: ["price", "description", "checkout_msg", "discount"],
    }
</script>

<style scoped>

</style>