<template>
  <div v-html="data" class="summary"></div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.summary {
  line-height: 20px;
  padding-bottom: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>

<style>
.meta a {
  color: #8293a3 !important;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}
big {
  font-weight: bold;
  color: #545E69;
  font-size: 14px;
}
</style>