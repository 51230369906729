<template>
  <div class="overview-label">
    <div class="hidden-xs"></div>
    <div class="header">
      <div class="park-summary up"><b>Request monthly spot</b><br><br>After sending your information, our
        partners will contact you to help you reserve the spot.
      </div>
    </div>
    <div class="park-details">
      <div class="park-regulations">
        <form class="monthly-parking">
          <label for="first-name">First name*</label><input type="text" v-model="firstName" id="first-name" placeholder="required" required>
          <label for="last-name">Last name*</label><input type="text" v-model="lastName" id="last-name" placeholder="required" required>
          <label for="email">Email*</label><input type="email" v-model="email" id="email" placeholder="required" required>
          <label for="phone-number">Phone number*</label><input type="tel" v-model="phoneNumber" id="phone-number" placeholder="required" required>
          <label for="car-make">Car make*</label><input type="text" v-model="carMake" id="car-make" placeholder="required" required>
          <label for="car-model">Car model*</label><input type="text" v-model="carModel" id="car-model" placeholder="required" required>
          <label for="start-date">Expected start date</label><input type="date" v-model="startDate" id="start-date">
          <label for="expected-stay">Expected stay (in months)</label><input type="number" v-model="expectedStay" id="expected-stay">
          <label for="spot-count">Number of spots needed</label><input type="number" v-model="spotCount" id="spot-count">
          <label for="comment">Comment (optional)</label><textarea id="comment" v-model="comment"></textarea>
          <button id="cancel" @click="cancel">Cancel</button>
          <button type="submit" id="submit" @click="submitMonthly" :disabled="isDisabled">{{submitButtonLabel}}</button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  import TrackHelper from "../../utils/analytics/amplitude";

  export default {
    data() {
      return {
        firstName : '',
        lastName : '',
        email : '',
        phoneNumber : '',
        carMake : '',
        carModel : '',
        startDate : '',
        expectedStay : '',
        spotCount : '',
        comment : '',
        submitting: false,
        submitFailure: false,
      };
    },
    props: ["data"],
    methods: {
      submitMonthly() {
        let vue = this
        this.submitting = true
        TrackHelper.requestMonthlySpotSubmitClicked(this.data.status)
        let request_url = spotAngelsApiV3 + "/api/v3/crowdsource/request_monthly_parking";

        // Using formData as Json not accepted by endpoint
        const formData = new FormData();
        formData.append('login_token', regulationsAccessToken);
        formData.append('first_name', this.firstName);
        formData.append('last_name', this.lastName);
        formData.append('phone_number', this.phoneNumber);
        formData.append('email', this.email);
        formData.append('comments', this.comment);
        formData.append('garage_id', this.data.status.id);
        formData.append('provider', '');
        formData.append('car_make', this.carMake);
        formData.append('car_model', this.carModel);
        formData.append('start_date', this.startDate);
        formData.append('expected_stay', this.expectedStay);
        formData.append('spot_count', this.spotCount);

        fetch(request_url, {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              vue.submitFailure = true;
              throw Error(response.statusText);
            }
            return response
          })
          .then(response => { return response.json(); })
          .then(data => {
            if (data.duplicate === undefined)
              gtag('event', 'Monthly Lead Request', {
                'event_category': 'Lead',
                'event_label': 'Request',
                'transaction_id': Date.now(),
                'affiliation': sessionStorage.getItem('firstLoadedUrl'),
                'items': [
                  {
                    'id': vue.data.status.id.toString(),
                    'name': vue.data.status.id.toString(),
                    'category': 'Lead',
                    'url': sessionStorage.getItem('firstLoadedUrl'),
                    'quantity': 1
                  }]
              });
            if (!alert('We sent your request, we\'ll get back to you very soon!')) {
              vue.$parent.monthlyRequested = false
            }
          })
        .catch((e) => {
          vue.submitFailure = true;
          vue.submitting = false
          alert('There was an issue sending your request. Please try again.')
        });


      },
      cancel() {
        TrackHelper.requestMonthlySpotCancelClicked()
        this.$parent.monthlyRequested = false
      }
    },
    computed: {
      ...mapGetters("filter", ["getFilteredDate", "getSearchString"]),
      ...mapGetters("map", ["sidebarContent"]),
      submitButtonLabel: function() {
        return ((this.submitting) ? 'Submitting...' : 'Send')
      },
      isDisabled: function() {
        return this.submitting
      }
    },
    mounted() {
      this.$store.dispatch("map/setSidebarContent", "monthlyForm")
    }
  };
</script>

<style scoped>
  b, strong {
    font-weight: 700;
  }
  label {
    font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #545E69;
    margin-bottom: 5px;
    font-weight: 700;
  }
  input, textarea {
    width: 100%;
    display: inline-block;
    font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #545e69;
    border: 0.5px solid #e0e6ea;
    border-radius: 24px;
    margin-top: 4px;
    margin-bottom: 16px;
    padding: 8px 0px 8px 8px;
  }
  form.monthly-parking {
    padding-left: 16px;
    padding-right: 20px;
  }
  .park-summary {
    padding: 16px;
    font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #8293a3 !important;
    border-bottom: 4px solid #f5f7f8;
  }
  form.monthly-parking button#submit {
    width: 65%;
    float: right;
    position: relative;
    left: 4px;
  }
  form.monthly-parking button {
    background: #1C9BE6;
    border: 1px solid #1C9BE6;
    font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    border-radius: 24px;
    font-size: 16px;
    padding: 8px 28px;
    color: #FFF;
    display: block;
    cursor: pointer;
  }
  form.monthly-parking button#cancel {
    width: 30%;
    float: left;
    background: #CED6DE;
    border: 0;
  }

</style>
