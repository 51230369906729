<template>
  <div class="date-filter-desktop">
    <div class="arrival">
      <vuejs-datepicker
        v-model="getFilteredDate.arrivalDate"
        @input="setArrivalDate"
        @closed="activateUpdates"
        :format="getFilteredDate.arrivalConfig.altFormat"
        :disabled-dates="disabledArrivalDates"
        :placeholder="getFilteredDate.arrivalConfig.altFormat"
        name="date"
        input-class="input-calendar-desktop"
        calendar-class="calendar-container-desktop"
      ></vuejs-datepicker>
      <SelectTime direction="arrival" />
    </div>

    <div class="departure">
      <vuejs-datepicker
        v-model="getFilteredDate.departureDate"
        @input="setDepartureDate"
        @closed="activateUpdates"
        :format="getFilteredDate.departureConfig.altFormat"
        :disabled-dates="disabledDepartureDates"
        :placeholder="getFilteredDate.departureConfig.altFormat"
        name="date"
        input-class="input-calendar-desktop"
        calendar-class="calendar-container-desktop"
      ></vuejs-datepicker>
      <SelectTime direction="departure" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import vuejsDatepicker from "vuejs-datepicker";
import DATES from "../../constants/dates";
import SelectTime from "./SelectTime";
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  data() {
    return {
      dates: DATES,
      arrival: "",
      departure: "",
      firstLoad: false,
    };
  },
  components: {
    SelectTime,
    vuejsDatepicker,
  },
  methods: {
    activateUpdates() {
      this.firstLoad = false;
    },
    setArrivalDate(date) {
      this.arrival = date;
      if(!(this.getFilteredDate.arrivalDate <= this.getFilteredDate.departureDate)){
        this.$store.dispatch("filter/setDepartureMinDate", new Date(this.arrival));
        this.$store.dispatch("filter/setDepartureDate", new Date(this.arrival));
      }
      if (!this.firstLoad) {
        this.updateMapAndSidebar();
        TrackHelper.filterChanged("Arrival Date");
      }
    },
    setDepartureDate(date) {
      this.departure = date;
      if (!this.firstLoad) {
        this.updateMapAndSidebar();
        TrackHelper.filterChanged("Departure Date");
      }
    },
    updateMapAndSidebar() {
      this.$store.dispatch("map/reloadTiles", {});
      if (this.sidebarContent === "spotsheet")
        this.$store.dispatch("map/setSpotsheet");
      else if (this.sidebarContent === "recommendations" || this.sidebarContent === "seo") {
        this.mapClass.setSearchPin();
        this.$store.dispatch("map/setRecommendations");
      }
    },
  },
  computed: {
    ...mapGetters("filter", ["getFilteredDate", "getSearchString"]),
    ...mapGetters("map", ["sidebarContent", "mapClass"]),
    disabledArrivalDates() {
      const arrivalDate = this.getFilteredDate.arrivalConfig.minDate
      const disabledDate = arrivalDate.setDate(arrivalDate.getDate() - 1)
      return {
        dates: [new Date(disabledDate)],
        customPredictor: function(date) {
          if(date <= new Date(disabledDate) ){
            return true
          }
        }
      };
    },
    disabledDepartureDates() {
      let limitDate = new Date()
      limitDate.setDate(this.getFilteredDate.arrivalDate.getDate() - 1)
      return {
        to: limitDate,
      };
    },
  },
  mounted() {
    this.$store.dispatch("filter/setArrivalDate", new Date());
    this.$store.dispatch("filter/setDepartureDate", new Date());
  },
};
</script>

<style scoped>
.date-filter-desktop {
  display: flex;
}
.arrival,
.departure {
  display: flex;
  align-items: center;
}
.arrival:after {
  content: "to";
  text-align: center;
  width: 30px;
}
</style>
<style>
.date-filter-desktop .input-calendar-desktop {
  align-items: center;
  background: transparent url(../../../assets/images/dropdown-arrow.svg) right 15px
  center no-repeat;
  border: 0;
  color: black;
  cursor: pointer;
  height: 40px;
  width: 110px;
}
.date-filter-desktop .input-calendar-desktop:focus {
  outline: none;
}
@media (min-width: 768px) {
  .date-filter-desktop .vdp-datepicker {
    display: flex;
    align-items: center;
    border-left: 1px solid #e0e6ea;
    height: 100%;
    padding-left: 10px;
  }
  .date-filter-desktop .calendar-container-desktop {
    position: absolute;
    top: 50px;
    left: 0;
    padding: 10px;
  }
}
</style>
