import { render, staticRenderFns } from "./Pictures.vue?vue&type=template&id=2f1be50a&scoped=true&"
import script from "./Pictures.vue?vue&type=script&lang=js&"
export * from "./Pictures.vue?vue&type=script&lang=js&"
import style0 from "./Pictures.vue?vue&type=style&index=0&id=2f1be50a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f1be50a",
  null
  
)

export default component.exports