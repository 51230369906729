<template>
  <div class="date-filter-mobile">
    <div class="arrival">
      <vuejs-datepicker
        v-model="getFilteredDate.arrivalDate"
        @input="setArrivalDate"
        @closed="activateUpdates"
        :format="getFilteredDate.arrivalConfig.altFormat"
        :disabled-dates="disabledArrivalDates"
        :placeholder="getFilteredDate.arrivalConfig.altFormat"
        name="date"
        input-class="input-calendar-mobile"
        calendar-class="calendar-container-mobile"
        wrapper-class="datepicker-wrapper"
      ></vuejs-datepicker>
      <SelectTime
        direction="arrival"
        @selectedTimeEvent="selectedTimeEventHandler"
      />
    </div>
    <div class="dates-separator">to</div>
    <div class="departure">
      <vuejs-datepicker
        v-model="getFilteredDate.departureDate"
        @input="setDepartureDate"
        @closed="activateUpdates"
        :format="getFilteredDate.departureConfig.altFormat"
        :disabled-dates="disabledDepartureDates"
        :placeholder="getFilteredDate.departureConfig.altFormat"
        name="date"
        input-class="input-calendar-mobile"
        calendar-class="calendar-container-mobile"
      ></vuejs-datepicker>
      <SelectTime
        direction="departure"
        @selectedTimeEvent="selectedTimeEventHandler"
      />
    </div>
    <button class="search-btn" @click="updateFilterDate">Search</button>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import vuejsDatepicker from "vuejs-datepicker";
import DATES from "../../constants/dates";
import SelectTime from "./SelectTime";
export default {
  data() {
    return {
      dates: DATES,
      arrival: "",
      departure: "",
      firstLoad: true,
      selectedTimeEvent: false,
    };
  },
  components: {
    SelectTime,
    vuejsDatepicker,
  },
  methods: {
    activateUpdates() {
      this.firstLoad = false;
    },
    setArrivalDate(date) {
      this.arrival = date;
      if(!(this.getFilteredDate.arrivalDate <= this.getFilteredDate.departureDate)){
        this.$store.dispatch("filter/setDepartureMinDate", new Date(this.arrival));
        this.$store.dispatch("filter/setDepartureDate", new Date(this.arrival));
      }
    },
    setDepartureDate(date) {
      this.departure = date;
    },
    updateMapAndSidebar() {
      this.$store.dispatch("map/reloadTiles", {});
      if (this.sidebarContent === "spotsheet")
        this.$store.dispatch("map/setSpotsheet");
      else if (this.sidebarContent === "recommendations" || this.sidebarContent === "seo") {
        this.mapClass.setSearchPin();
        this.$store.dispatch("map/setRecommendations");
      }
    },
    selectedTimeEventHandler() {
      this.selectedTimeEvent = true;
    },
    updateFilterDate() {
      if (!this.firstLoad || this.selectedTimeEvent) {
        this.updateMapAndSidebar();
      }
      this.$emit("close");
    },
  },
  computed: {
    ...mapGetters("filter", ["getFilteredDate", "getSearchString"]),
    ...mapGetters("map", ["sidebarContent", "mapClass"]),
    disabledArrivalDates() {
      const arrivalDate = this.getFilteredDate.arrivalConfig.minDate
      const disabledDate = arrivalDate.setDate(arrivalDate.getDate() - 1)
      return {
        dates: [new Date(disabledDate)],
        customPredictor: function(date) {
          if(date <= new Date(disabledDate) ){
            return true
          }
        }
      };
    },
    disabledDepartureDates() {
      return {
        to: new Date(this.getFilteredDate.arrivalDate),
      };
    },
  },
  mounted() {
    this.$store.dispatch("filter/setArrivalDate", new Date());
    this.$store.dispatch("filter/setDepartureDate", new Date());
  },
};
</script>

<style scoped>
.date-filter-mobile {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
}
.arrival,
.departure {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dates-separator {
  margin: 5px;
}
.search-btn {
  background: #1c9be6;
  border: 0;
  border-radius: 30px;
  color: #fff;
  margin: 20px auto 10px;
  padding: 14px 20px;
  text-align: center;
  width: 100%;
}
.search-btn {
  outline: none;
}
</style>

<style>
.date-filter-mobile .input-calendar-mobile {
  align-items: center;
  background: white url(../../../assets/images/icon-calendar.svg) right 15px
    center no-repeat;
  border: 1px solid #ced6de;
  border-radius: 30px;
  color: black;
  height: 50px;
  padding-left: 20px;
  text-align: left;
  width: 100%;
}
.date-filter-mobile .input-calendar-mobile:focus {
  outline: none;
}
.date-filter-mobile .calendar-container-mobile {
  position: fixed;
}
.datepicker-wrapper {
  max-width: 60%;
}
</style>
