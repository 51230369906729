<template>
  <div v-if="showBanner" class="desktop-banner">
    <div class="brand">
      <img :src="require('images/spotangels-symbol.svg')" class="logo" />
      <div>
        <div class="brand-name">SpotAngels</div>
        <p class="brand-description">Parking Map & Deals</p>
        <img
          v-for="(item, index) in 5"
          :src="require('images/icon-star-on.svg')"
          :key="index"
          class="star"
        />
      </div>
      <a
        href="http://download.spotangels.com/QLIf/YNgz8s7lgJ"
        @click="branchTagsClick"
        class="cta"
      >
        <img :src="require('images/icon-smartphone.svg')" />
        Send to my phone
      </a>
    </div>
    <div class="store">
      <img
        :src="require('images/download-SpotAngels-iphone-app.png')"
        alt="Apple store"
      />
      <img :src="require('images/playstore.png')" alt="Play store" />
    </div>
    <img
      :src="require('images/close-button-mobile.svg')"
      @click="hideBanner"
      class="close"
    />
  </div>
</template>

<script>
import branchTags from "../../../utils/branchTags";
export default {
  data() {
    return {
      showBanner: true,
    };
  },
  methods: {
    hideBanner() {
      localStorage.setItem("bannerDismissed", true);
      this.showBanner = false;
    },
    branchTagsClick(element) {
      this.hideBanner();
      branchTags(element.target.href);
    },
  },
  mounted() {
    const bannerDismissed = localStorage.getItem("bannerDismissed");
    if (bannerDismissed) {
      this.showBanner = false;
    }
  },
};
</script>

<style scoped>
.desktop-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  height: 90px;
  padding: 16px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.brand {
  display: flex;
  align-items: center;
}
.brand-name {
  color: #212529;
  font-size: 22px;
  font-weight: bold;
}
.star {
  height: 15px;
  width: 15px;
  margin-top: 4px;
}
.cta {
  display: flex;
  align-items: center;
  gap: 10px;
  background: #1c9be6;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  padding: 14px 30px;
  margin-left: 15px;
  text-decoration: none;
}
.cta img {
  width: 12px;
}
.logo {
  border-radius: 8px;
  border: 1px solid #d5dce4;
  height: 58px;
  width: 58px;
  margin-right: 15px;
  padding: 8px 5px;
}
.store {
  margin-right: 20px;
}
.store img {
  width: 150px;
  margin: 0 5px;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 14px;
  margin: 10px;
}
</style>
