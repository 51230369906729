<template>
  <div class="deals">
    <div class="monthly-deals-header">
      <img
        :src="require('images/icon-booking.svg')"
        alt="Monthly parking available"
      />
      <span class="monthly-deals-header-title">Monthly parking deals</span>
    </div>
    <div v-for="(deal, index) in deals" :key="index" class="deals-wrapper">
      <div class="deal-info">
        <div class="deal-price">
          <span :class="deal.discount ? 'full-price' : null">{{
            deal.price_fmt
          }}</span>
          <span v-if="deal.discount" class="blue"
            >${{ (deal.price - deal.discount).toFixed(2).replace('.00', '') }}</span>
          <template v-if="!monthlyMode">
            <span v-if="deal.discount" style="font-size:15px"> for 1st month</span>
            <span v-else style="font-size:15px"> per month</span>
          </template>
        </div>
        <div class="deal-type">{{ deal.description }}</div>
        <div v-if="deal.discount" class="blue deal-provider">${{ deal.discount }} off your first month with {{ deal.source }}</div>
        <div v-else class="deal-provider">with {{ deal.source }}</div>
      </div>
      <button class="deal-button" @click="openCheckout(deal, status.id)">
        {{ deal.cta_title }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  props: ["status", "reviews_avg", "deals"],
  computed: {
    ...mapGetters("filter", ["monthlyMode"])
  },
  methods: {
    openCheckout(deal, id) {
      TrackHelper.bookGarageClicked(this.status, deal, this.reviews_avg, 'Spot Sheet', 'Monthly')
      if (deal.sa_payment_flow !== undefined && deal.sa_payment_flow === true) {
        sessionStorage.setItem("offerPrice", deal.price);
        const link =
          "/checkout?segment_id=" +
          id +
          "&source=" +
          deal.source +
          "&type=monthly";
        const rateID = deal.rate_id ? "&rate_id=" + deal.rate_id : "";
        window.open(link + rateID);
      } else {
        // if (deal.source === "City of Las Vegas" && liveMap.isEmbed == true) { TODO : add isEmbed property
        //   deal.full_link += "&is_embed=true"
        // }
        window.open(deal.full_link);
      }
    },
  },
};
</script>

<style scoped>
.deals {
  border-bottom: 4px solid #f5f7f8;
}
.monthly-deals-header {
  display: flex;
  align-items: center;
  padding: 15px 20px;
}
@media (max-width: 767px) {
  .monthly-deals-header {
    padding: 10px 20px 5px;
  }
}
.monthly-deals-header img {
  width: 18px;
}
.monthly-deals-header-title {
  color: #212529;
  font-size: 18px;
  padding-left: 5px;
}
.deals-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f5f7f8;
  color: #000;
  font-size: 12px;
  padding: 10px 0;
}
.deals-wrapper:last-of-type {
  border-bottom: 0;
}
.deal-info {
  margin-left: 20px;
  padding-right: 10px;
  width: 60%;
}

.deal-info > div {
  padding: 2px 0;
}

.deal-button {
  background: #1c9be6;
  border: 1px solid #1c9be6;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin-right: 16px;
  outline: none;
  padding: 5px 22px 5px 22px;
}
.deal-price {
  color: #212529;
  font-size: 18px;
}
.full-price {
  text-decoration: line-through;
}
.blue {
  color: #1c9be6 !important;
}
.deal-provider {
  color: #8293a3;
}
</style>