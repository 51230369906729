<template>
  <div class="time-container">
    <div v-if="direction === 'arrival'">
      <select
        v-model="selectedArrivalTime"
        @change="setArrivalTime"
        class="time-select"
      >
        <option v-for="item in dates" :key="item.minutes" :value="item.minutes" :disabled="isArrivalToday && item.minutes < currentTime">
          {{ item.display }}
        </option>
      </select>
    </div>
    <div v-if="direction === 'departure'">
      <select
        v-model="selectedDepartureTime"
        @change="setDepartureTime"
        class="time-select"
      >
        <option v-for="item in dates" :key="item.minutes" :value="item.minutes" :disabled="isArrivalAndDepartureSameDay && item.minutes <= selectedArrivalTime">
          {{ item.display }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DATES from "../../constants/dates";
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  data() {
    return {
      dates: DATES,
      selectedArrivalTime: "",
      selectedDepartureTime: "",
      selectedTime: 0,
      currentTime: 1440,
      isDesktop: !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
    };
  },
  props: ["direction"],
  methods: {
    setInitialTimes() {
      let minutesToAdd = (new Date().getMinutes() > 30) ? 60 : 30
      this.currentTime = new Date().getHours() * 60 + minutesToAdd
      this.selectedArrivalTime = this.currentTime
      this.selectedDepartureTime = (this.selectedArrivalTime + 120) % 1440;
      if (this.selectedArrivalTime + 120 >= 1440) {
        let currentDepartureDate = new Date(this.getFilteredDate.departureDate)
        this.$store.dispatch("filter/setDepartureDate", new Date(currentDepartureDate.setDate(currentDepartureDate.getDate() + 1)));
      }

      const arrivalTime = this.dates.find(
        (item) => item.minutes === this.selectedArrivalTime
      ).display;
      const departureTime = this.dates.find(
        (item) => item.minutes === this.selectedDepartureTime
      ).display;
      this.$store.dispatch("filter/setDepartureTime", departureTime);
      this.$store.dispatch("filter/setArrivalTime", arrivalTime);
    },
    changeSelectedTime(selectedTime) {
      this.selectedArrivalTime = this.dates.find(
        (item) => item.minutes === selectedTime
      ).minutes;
      if (this.selectedDepartureTime <= this.selectedArrivalTime) {
        this.selectedDepartureTime = (this.selectedArrivalTime + 120) % 1440;
        if (this.selectedArrivalTime + 120 >= 1440) {
          let currentDepartureDate = new Date(this.getFilteredDate.departureDate)
          this.$store.dispatch("filter/setDepartureDate", new Date(currentDepartureDate.setDate(currentDepartureDate.getDate() + 1)));
        }
      }

      const arrivalTime = this.dates.find(
        (item) => item.minutes === this.selectedArrivalTime
      ).display;
      const departureTime = this.dates.find(
        (item) => item.minutes === this.selectedDepartureTime
      ).display;
      this.$store.dispatch("filter/setDepartureTime", departureTime);
      this.$store.dispatch("filter/setArrivalTime", arrivalTime);
    },
    setArrivalTime() {
      const arrivalTime = this.dates.find(
        (item) => item.minutes === this.selectedArrivalTime
      );
      this.$store.dispatch("filter/setArrivalTime", arrivalTime.display);
      this.$store.dispatch("filter/setSelectedTime", this.selectedArrivalTime);
      TrackHelper.filterChanged("Arrival Hour");
    },
    setDepartureTime() {
      const departureTime = this.dates.find(
        (item) => item.minutes === this.selectedDepartureTime
      );
      this.$store.dispatch("filter/setDepartureTime", departureTime.display);

      if (this.isDesktop) {
        this.updateMapAndSidebar();
      } else {
        this.$emit("selectedTimeEvent");
      }
      TrackHelper.filterChanged("Departure Hour");
    },
    updateMapAndSidebar() {
      this.$store.dispatch("map/reloadTiles");
      if (this.sidebarContent === "spotsheet")
        this.$store.dispatch("map/setSpotsheet");
      else if (this.sidebarContent === "recommendations" || this.sidebarContent === "seo") {
        this.mapClass.setSearchPin();
        this.$store.dispatch("map/setRecommendations");
      }
    },
  },
  watch: {
    getSelectedTime: function(val) {
      if (this.direction === 'departure') {
        this.changeSelectedTime(val);
        if (this.isDesktop) {
          this.updateMapAndSidebar();
        } else {
          this.$emit("selectedTimeEvent");
        }
      }
    },
  },
  computed: {
    isArrivalToday : function() {
      let today = new Date().toISOString()
      let arrivalDate = this.getFilteredDate.arrivalDate.toISOString()
      return today.split('T')[0] === arrivalDate.split('T')[0]
    },
    isArrivalAndDepartureSameDay : function() {
      let arrivalDate = this.getFilteredDate.arrivalDate.toISOString()
      let departureDate = this.getFilteredDate.departureDate.toISOString()
      return departureDate.split('T')[0] === arrivalDate.split('T')[0]
    },
    ...mapGetters("filter", ["getSelectedTime", "getFilteredDate"]),
    ...mapGetters("map", ["sidebarContent", "mapClass"]),
  },
  created() {
    this.setInitialTimes();
  },
};
</script>
<style scoped>
.time-container {
  min-width: 40%;
}
select {
  margin-left: 10px;
}
.time-select {
  align-items: center;
  background: white url(../../../assets/images/icon-clock.svg) right 15px center
    no-repeat;
  border: 1px solid #ced6de;
  border-radius: 30px;
  color: black;
  cursor: pointer;
  height: 50px;
  padding-left: 15px;
  text-align: left;
  appearance: none;
  width: 100%;
}
.time-select:focus {
  outline: none;
}
@media (min-width: 768px) {
  .time-container {
    min-width: 0;
  }
  select {
    width: 150px;
  }
  .time-select {
    align-items: center;
    background: url(../../../assets/images/dropdown-arrow.svg) no-repeat 93%
      center;
    border: 0;
    border-radius: 0;
    color: black;
    height: 50px;
    padding-left: 10px;
    text-align: center;
    appearance: none;
    width: 100px;
  }
  .time-select:focus {
    outline: none;
  }
  .arrival .time-select {
    border-left: 1px solid #e0e6ea;
    border-right: 1px solid #e0e6ea;
  }
  .departure .time-select {
    border-left: 1px solid #e0e6ea;
  }
}
</style>
