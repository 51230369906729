<template>
  <div class="stars">
    <img v-for="index in rateOn" :src="require('images/icon-star-on.svg')" :key="index" />
    <img v-for="index in rateOff" :src="require('images/icon-star-off.svg')" :key="index+5" />
  </div>
</template>

<script>
export default {
  props: ["rate"],
  computed: {
    rateOn() {
      return Math.round(parseFloat(this.rate));
    },
    rateOff() {
      return 5 - Math.round(parseFloat(this.rate));
    },
  },
};
</script>

<style scoped>
.stars {
  display: flex;
}
</style>