import { render, staticRenderFns } from "./Legend.vue?vue&type=template&id=35e95b40&scoped=true&"
var script = {}
import style0 from "./Legend.vue?vue&type=style&index=0&id=35e95b40&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e95b40",
  null
  
)

export default component.exports