<template>
  <div
    class="container"
    :class="status === true ? 'container--show' : 'container--hide'"
  >
    <div class="wrapper" id="sidebar-desktop">
      <div v-if="showBackToListButton"
        class="back-link"
        @click="showRecommendations"
      >
        <img :src="require('images/icon-arrow-left-blue.svg')" alt="Back to suggestions" />
        <span>Back to suggestions</span>
      </div>
      <Spotsheet v-if="spotSheet !== '' && (sidebarContent === 'spotsheet' || sidebarContent === 'monthlyForm')" :data="spotSheet" />
      <Poi v-else-if="sidebarContent === 'seo'"/>
      <Recommendations v-else-if="recommendationList !== '' && sidebarContent === 'recommendations'" />
    </div>
    <div class="toggle" @click="toggleSidebar">
      <img
        v-if="status"
        :src="require('images/icon-caret-left.svg')"
        alt="close sidebar"
      />
      <img
        v-if="!status"
        :src="require('images/icon-caret-right.svg')"
        alt="show sidebar"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spotsheet from "../sidebar/Spotsheet";
import Recommendations from "../sidebar/Recommendations";
import Poi from "../sidebar/Poi"
import seoHelper from "../../utils/seoHelper"

export default {
  components: {
    Recommendations,
    Spotsheet,
    Poi,
  },
  props: ["status"],
  data() {
    return {
      segment: {},
    };
  },
  computed: {
    ...mapGetters("utils", ["showShareModal"]),
    ...mapGetters("sidebar", ["showSidebar"]),
    ...mapGetters("map", [
      "spotSheet",
      "recommendationList",
      "sidebarContent",
      "mapClass",
      "searchPosition",
    ]),
    ...mapGetters("filter", ["getSearchString"]),
    showBackToListButton() {
      return this.spotSheet !== '' && this.sidebarContent === 'spotsheet' && this.recommendationList !== '' && seoHelper.seo_data === undefined
    },
  },
  mounted() {
    if (seoHelper.seo_data !== undefined) {
      this.$store.dispatch("map/setSidebarContent", "seo");
      this.$store.dispatch("sidebar/showSidebar", true);
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("sidebar/toggleSidebar");
    },
    showRecommendations() {
      this.$store.dispatch("map/setSidebarContent", "recommendations");
      this.mapClass.setSearchLink(
        this.getSearchString,
        this.searchPosition[0],
        this.searchPosition[1],
        true
      );
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 91px;
  height: calc(100% - 90px);
  z-index: 1;
  font-size: 14px;
  color: #8293a3;
}
.wrapper {
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
  width: 395px;
}
.container--hide {
  animation: slide-close 0.2s ease-in;
  animation-fill-mode: forwards;
}
.container--show {
  animation: slide-open 0.2s ease-in;
  animation-fill-mode: forwards;
}
.meta h3 {
  color: #545e69;
  font-weight: bold;
  padding-bottom: 5px;
}
.toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 55px;
  left: 0;
  background: white;
  border-radius: 0 3px 3px 0;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 22px;
  height: 50px;
}
.toggle img {
  height: 14px;
}

@keyframes slide-open {
  0% {
    left: -395px;
  }
  100% {
    left: 0;
  }
}
@keyframes slide-close {
  0% {
    left: 0;
  }
  100% {
    left: -395px;
  }
}

.back-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #1c9be6;
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px 0;
}
.back-link img {
  height: 12px;
  padding-right: 5px;
}
.back-link span:hover {
  text-decoration: underline;
}
</style>
