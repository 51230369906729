<template>
    <div>
        <div class="main_title" v-if="$parent.cc_screen">YOUR ORDER</div>
        <div class="summary">
            <div class="summary_price">
                <div class="onsite_strikethrough" v-if="onSitePrice > price">${{onSitePrice}} on-site</div>
                <div class="source_deal">${{price}}</div>
            </div>
            <div class="summary_description_slot">{{formatDate(full_start_date)}} - {{formatDate(full_end_date)}}</div>
            <div class="summary_description_details">Free cancellation up to start time</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        mounted() {
          this.$root.$on("timesChangeEvent", (d1, d2) => {
              this.full_start_date = d1;
              this.full_end_date = d2;
          })
        },
        methods: {
            formatDate(date) {
                let unformattedDate = parseParamDate(date + ':00');
                let day = ("0" + unformattedDate.getDate()).slice(-2);
                let month = ("0" + (unformattedDate.getMonth() + 1)).slice(-2);
                let hours = unformattedDate.getHours();
                let minutes = unformattedDate.getMinutes();
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                return month + "/" + day + " " + hours + ':' + minutes + ' ' + ampm;
            }
        },
        props: ["onSitePrice", "price", "full_start_date", "full_end_date"],
    }
</script>

<style scoped>

</style>