<template>
  <div>
    <div class="parking-options-header">
      <div class="title">Parking Options ({{ optionsCount }})</div>
    </div>
    <ul class="parking-options" id="parking-recos">
      <li
        v-for="(option, index) in recommendationList.results"
        :data-segment="option.id"
        v-on:click="showSegment(option)"
        @mouseover="highlightSegment(option.id)"
        :key="index"
        :style="(index > 4 && sidebarContent ==='seo' && !showAll) ? 'display: none;' : ''"
      >
        <template v-if="monthlyMode">
          <div class="reco-item-first-line">
            <img
              v-if="option.deals !== undefined"
              :src="require('images/flash.png')"
            />{{ option.name }}
          </div>
          <div class="reco-item-second-line">{{ option.walking_time_fmt }}</div>
          <div class="reco-item-third-line">
            {{ option.garage_type }} - {{ option.address.split(",")[0] }}
          </div>
          <template v-if="option.deals !== undefined">
            <div class="reco-item-right">
              <span class="reco-item-right-first">
                {{ option.price_range_fmt }}
              </span>
              <button class="deal" v-on:click="viewDeal(option, 'monthly')">
                View Deal
              </button>
            </div>
          </template>
          <template v-else>
            <div class="reco-item-right">
              <template v-if="option.monthly_rates !== undefined">
                <div class="reco-item-right-first">
                  ${{ option.monthly_rates[0].rate }}
                </div>
                <div class="reco-item-right-second">
                  {{ option.monthly_rates[0].type.label }}
                </div>
              </template>
              <div v-else class="spot-infos garage">
                <div class="reco-item-right-first">Request</div>
                <div class="reco-item-right-second">price</div>
              </div>
            </div>
          </template>
        </template>

        <!-- Hourly recommendations -->
        <template v-else>
          <div class="spot-type">
            <img
              class="spot-icon"
              :src="require('images/' + icons[option.type])"
            />
            <img
              v-if="option.deals !== undefined"
              class="spot-flash"
              :src="require('images/booking.svg')"
            />
            <span class="option-name">{{ option.name }}</span>
            <span v-if=" option.price_fmt !== undefined && option.type === 'garage' && option.price_fmt !== 'Free'">&nbsp;
              <span> - </span>
              <span :class="(option.onsite_price > option.price) ? 'discounted-price' : null">{{ option.price_fmt }}</span>
              <span v-if="option.onsite_price > option.price" class="full-price">{{ option.onsite_price_fmt }}</span>
              </span>
          </div>
          <div class="spot-reason">
            {{
              option.reason
                ? option.reason.includes('Cheapest and closest bookable') ? 'Cheapest and closest bookable' : option.reason
                : "Nearby " +
                  (option.garage_type ? option.garage_type : option.type)
            }}
          </div>
          <div
            class="spot-distance"
            :class="
              option.deals === undefined
                ? 'spot-distance'
                : 'spot-distance with-ellipsis'
            "
          >
            {{ option.walking_time_fmt }} - {{ option.address.split(",")[0] }}
          </div>
          <button
            v-if="option.deals !== undefined"
            class="book-deal-button"
            v-on:click="viewDeal(option, 'transient')"
          >
            View deal
          </button>
        </template>
      </li>
    </ul>
    <div v-if="sidebarContent === 'seo' && !showAll" class="read-more" @click="() => this.showAll = true ">See more</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  data() {
    return {
      showAll: false,
      icons: {
        garage: "garage.svg",
        free: "free.svg",
        meter: "meter.svg",
        time_limit: "time-limit.svg",
      },
    };
  },
  computed: {
    ...mapGetters("filter", ["monthlyMode"]),
    ...mapGetters("map", ["mapClass", "recommendationList", "sidebarContent"]),
    optionsCount() {
      return this.recommendationList.results.length;
    },
  },
  methods: {
    showSegment(park) {
      this.$store.dispatch("map/setCurrentSegmentId", park.id);
      this.$store.dispatch("map/setSpotsheet");
      TrackHelper.recommendationItemClicked(park);
    },
    highlightSegment(id) {
      this.mapClass.highlightSegment(id, false);
    },
    viewDeal(park, offer) {
      TrackHelper.recommendationsViewDealClicked(park, offer);
    },
  },
};
</script>

<style scoped lang="scss">
.full-price {
  text-decoration: line-through;
}

.discounted-price {
  color: #1c9be6;
}

.option-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 230px;
}

.parking-options-header {
  padding: 6px 20px 14px;
}

@media (min-width: 768px) {
  .parking-options-header {
    padding: 16px;
  }
}

.parking-options-header .title {
  font-size: 18px;
  color: #212529;
}

.parking-options li:nth-of-type(1) {
  border-top: 1px solid #ced6de;
}

.parking-options li {
  display: flex;
  flex-direction: column;
  height: 88px;
  position: relative;
  list-style: none;
  cursor: pointer;
  border-bottom: 1px solid #ced6de;
}

.spot-icon {
  width: 30px;
  height: 30px;
  background-color: rgba(255, 204, 0, 0.2);
  border-radius: 50%;
  padding: 5px;
  float: left;
}

.spot-flash {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 13px;
  left: 29px;
}

.spot-type {
  display: flex;
  align-items: center;
  margin: 10px 0 0 10px;
  line-height: 30px;
  font-weight: 500;
  font-size: 18px;
  color: #212529;
}

.parking-options .spot-reason {
  position: absolute;
  height: 16px;
  left: 40px;
  line-height: 18px;
  top: 38px;
  font-size: 14px;
  color: #1c9be6;
}

.parking-options .spot-distance {
  position: absolute;
  height: 16px;
  left: 40px;
  line-height: 18px;
  top: 57px;
  font-size: 14px;
  color: #8293a3;
}

.with-ellipsis {
  width: 220px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.price-strike {
  color: #212529;
  text-decoration: line-through;
  border: none;
  padding-right: 5px;
}

.deal-price {
  color: #1c9be6;
}

.normal-price {
  color: #212529;
}

button.book-deal-button {
  position: absolute;
  top: 43px;
  right: 10px;
  width: 105px;
  height: 30px;
  border: 1px solid #1c9be6;
  border-radius: 60px;
  font-size: 16px;
  color: #fff;
  background-color: #1c9be6;

  &:hover {
    cursor: pointer;
  }
}

.parking-options li:hover,
.parking-options li.active {
  background: rgba(70, 155, 223, 0.05);
}

.reco-item-first-line {
  position: absolute;
  width: calc(100% - 194px);
  height: 19px;
  left: 12px;
  line-height: 18px;
  top: calc(50% - 19px / 2 - 17.5px);
  font-weight: bold;
  font-size: 14px;
  color: #565e68;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reco-item-first-line img {
  margin-right: 4px;
  float: left;
}

.reco-item-second-line {
  position: absolute;
  width: 260px;
  height: 16px;
  left: 12px;
  top: calc(50% - 16px / 2 + 1px);
  line-height: normal;
  font-size: 12px;
  color: #8593a1;
}

.reco-item-third-line {
  position: absolute;
  width: 300px;
  height: 16px;
  left: 12px;
  top: calc(50% - 16px / 2 + 19px);
  line-height: normal;
  font-size: 12px;
  color: #2d6f9f;
  border-color: #2d6f9f;
}

.reco-item-right {
  position: absolute;
  right: 5px;
  top: 25px;
  width: 200px;
  /*height: 45px;*/
  text-align: right;
}

.reco-item-right-first {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: #565e68;
  font-weight: bolder;
  text-align: right;
}

.reco-item-right-first:empty {
  border: none;
}

.reco-item-right-second {
  font-size: 11px;
  color: #565e68 !important;
  text-align: right;
}

.deal button,
button.deal {
  padding: 5px 16px;
  border: 1px solid #1c9be6;
  border-radius: 24px;
  font-size: 14px;
  color: #fff;
  background-color: #1c9be6;

  &:hover {
    cursor: pointer;
  }

  margin-left: 5px;
}

.read-more {
  background: url(../../../assets/images/see-more.png) 170px 20px no-repeat !important;
  background: #fff;
  color: #1c9be6;
  padding: 0px 0 20px 150px;
  margin: 10px 0 ;
  cursor: pointer;
}
</style>
