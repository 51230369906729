<template>
    <div>
        <div style="display: flex; margin: 15px">
            <span class="back_image" v-show="cc_screen" v-on:click="switchViews"></span>
            <span class="garage_title">{{garage_name}}</span>
        </div>
        <div class="bottom_line"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            };
        },
        methods: {
            switchViews() {
                this.$parent.cc_screen = false
            },
        },
        props: ["garage_name", "cc_screen"],
    }
</script>

<style scoped>

</style>