const DATES = [
  { minutes: 0, hours: '0', display: '12:00 AM' },
  { minutes: 30, hours: '0', display: '12:30 AM' },
  { minutes: 60, hours: '1', display: '1:00 AM' },
  { minutes: 90, hours: '1', display: '1:30 AM' },
  { minutes: 120, hours: '2', display: '2:00 AM' },
  { minutes: 150, hours: '2', display: '2:30 AM' },
  { minutes: 180, hours: '3', display: '3:00 AM' },
  { minutes: 210, hours: '3', display: '3:30 AM' },
  { minutes: 240, hours: '4', display: '4:00 AM' },
  { minutes: 270, hours: '4', display: '4:30 AM' },
  { minutes: 300, hours: '5', display: '5:00 AM' },
  { minutes: 330, hours: '5', display: '5:30 AM' },
  { minutes: 360, hours: '6', display: '6:00 AM' },
  { minutes: 390, hours: '6', display: '6:30 AM' },
  { minutes: 420, hours: '7', display: '7:00 AM' },
  { minutes: 450, hours: '7', display: '7:30 AM' },
  { minutes: 480, hours: '8', display: '8:00 AM' },
  { minutes: 510, hours: '8', display: '8:30 AM' },
  { minutes: 540, hours: '9', display: '9:00 AM' },
  { minutes: 570, hours: '9', display: '9:30 AM' },
  { minutes: 600, hours: '10', display: '10:00 AM' },
  { minutes: 630, hours: '10', display: '10:30 AM' },
  { minutes: 660, hours: '11', display: '11:00 AM' },
  { minutes: 690, hours: '11', display: '11:30 AM' },
  { minutes: 720, hours: '12', display: '12:00 PM' },
  { minutes: 750, hours: '12', display: '12:30 PM' },
  { minutes: 780, hours: '13', display: '1:00 PM' },
  { minutes: 810, hours: '13', display: '1:30 PM' },
  { minutes: 840, hours: '14', display: '2:00 PM' },
  { minutes: 870, hours: '14', display: '2:30 PM' },
  { minutes: 900, hours: '15', display: '3:00 PM' },
  { minutes: 930, hours: '15', display: '3:30 PM' },
  { minutes: 960, hours: '16', display: '4:00 PM' },
  { minutes: 990, hours: '16', display: '4:30 PM' },
  { minutes: 1020, hours: '17', display: '5:00 PM' },
  { minutes: 1050, hours: '17', display: '5:30 PM' },
  { minutes: 1080, hours: '18', display: '6:00 PM' },
  { minutes: 1110, hours: '18', display: '6:30 PM' },
  { minutes: 1140, hours: '19', display: '7:00 PM' },
  { minutes: 1170, hours: '19', display: '7:30 PM' },
  { minutes: 1200, hours: '20', display: '8:00 PM' },
  { minutes: 1230, hours: '20', display: '8:30 PM' },
  { minutes: 1260, hours: '21', display: '9:00 PM' },
  { minutes: 1290, hours: '21', display: '9:30 PM' },
  { minutes: 1320, hours: '22', display: '10:00 PM' },
  { minutes: 1350, hours: '22', display: '10:30 PM' },
  { minutes: 1380, hours: '23', display: '11:00 PM' },
  { minutes: 1410, hours: '23', display: '11:30 PM' },
]

export default DATES
