<template>
  <div class="rates">
    <div v-if="!monthlyMode" class="title">Rates for today</div>
    <div v-for="(item, index) in data" :key="index" class="regulation-month">
      <div class="title">{{item.title}}</div>
      <div v-for="(entry, index) in item.entries" :key="index" class="regulation-month-wrapper">
        <div v-html="entry.title"></div>
        <div>{{entry.values[0]}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "monthlyMode"],
};
</script>

<style scoped>
.rates {
  color: #545e69;
  font-size: 14px;
  padding: 0 16px;
  text-align: left;
}
.title {
  font-weight: bold;
  padding-bottom: 8px;
}
.regulation-month {
  color: #8293a3;
  padding-bottom: 12px;
}
.regulation-month-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;
}
</style>