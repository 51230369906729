
let blockfaceOccupancyRequest = null;

// Chart.defaults.global.defaultFontColor = '#545E69';
// Chart.defaults.global.defaultFontFamily = 'MuseoSansRounded';
let histogram = null;

const onBlockfaceOccupancyReceived = function (request, result) {

    const data = result.histogram;
    
    let nodata = true;
    for (let i = 0; i < data.bins.length; i++) {
        if (data.bins[i].description !== 'No data') {
            nodata = false;
            break;
        }
    }

    if (nodata) {
                  // $('#availability-histogram').remove();
                  console.log("No Chart");
                  return null;
                } else {
                         // console.log("Send data");
                         // console.log("Blockface: ", data);
                         histogram = new Chart(
                           document
                             .getElementById("occupancy-chart")
                             .getContext("2d"),
                           {
                             type: "bar",
                             data: {
                               labels: Array.from(data.bins.keys()),
                               datasets: [
                                 {
                                   backgroundColor: "#96D5FA",
                                   borderColor: "#96D5FA",
                                   hoverBackgroundColor: "#1C9BE6",
                                   hoverBorderColor: "#1C9BE6",
                                   data: data.bins.map((bin) =>
                                     Math.max(bin.value, 0.25)
                                   ),
                                 },
                               ],
                             },
                             options: {
                               cornerRadius: 4,
                               responsive: true,
                               maintainAspectRatio: false,
                               legend: {
                                 display: false,
                               },
                               scales: {
                                 yAxes: [
                                   {
                                     gridLines: {
                                       drawBorder: false,
                                     },
                                     ticks: {
                                       min: data.y_labels.reduce((min, label) =>
                                         label.value < min.value ? label : min
                                       ).value,
                                       max:
                                         data.y_labels.reduce((max, label) =>
                                           label.value > max.value ? label : max
                                         ).value + 0.25,
                                       stepSize: 0.01,
                                       autoSkip: false,
                                       userCallback: (label) => {
                                         for (
                                           let i = 0;
                                           i < data.y_labels.length;
                                           i++
                                         ) {
                                           if (
                                             label === data.y_labels[i].value
                                           ) {
                                             return data.y_labels[i].label;
                                           }
                                         }
                                       },
                                     },
                                   },
                                 ],
                                 xAxes: [
                                   {
                                     categoryPercentage: 0.95,
                                     barPercentage: 0.95,
                                     gridLines: {
                                       display: false,
                                     },
                                     ticks: {
                                       min: 0,
                                       max: data.bins.length - 1,
                                       maxRotation: 0,
                                       autoSkip: false,
                                       userCallback: (label) => {
                                         for (
                                           let i = 0;
                                           i < data.x_labels.length;
                                           i++
                                         ) {
                                           if (
                                             label === data.x_labels[i].value
                                           ) {
                                             return data.x_labels[i].label;
                                           }
                                         }
                                       },
                                     },
                                   },
                                 ],
                               },
                               tooltips: {
                                 enabled: false,
                                 mode: "index",
                                 axis: "x",
                                 intersect: false,
                                 custom: (tooltipModel) =>
                                   buildCustomTooltip(tooltipModel, data.bins),
                               },
                             },
                           }
                         );
                       }
    return data;
}

const onBlockfaceOccupancyError = function (request, e) {
    if (request !== blockfaceOccupancyRequest) return;
    console.log('error getting blockface occupancy');
    console.log(e);
}

const buildCustomTooltip = function (tooltipModel, bins) {
    let tooltip = $('#histogram-tooltip');

    if (tooltip.length === 0) {
        const label = $('<span/>', { id: 'label', css: { color: '#1C9BE6' } });
        const description = $('<span/>', { id: 'description' });

        tooltip = $(
            '<div/>',
            {
                id: 'histogram-tooltip',
                css: {
                    'white-space': 'nowrap',
                    padding: '6px',
                    position: 'absolute',
                    opacity: '1',
                    background: 'white none repeat scroll 0% 0%',
                    border: '1px solid #CED6DE',
                    borderRadius: '6px',
                    fontSize: '14px',
                    color: '#212529',
                    pointerEvents: 'none',
                },
            },
        ).append(label).append(description);
        $('.overview-label')[0].appendChild(tooltip[0]);
    }

    if (tooltipModel.opacity === 0) {
        tooltip.css('opacity', 0);
        return;
    }

    const bin = bins[tooltipModel.dataPoints[0].index];
    tooltip.children('#label').text(`${bin.label}: `);
    tooltip.children('#description').text(bin.description);

    const position = histogram.canvas.getBoundingClientRect();
    let caretX = tooltipModel.caretX;
    if (isNaN(caretX)) {
        // caretX is missing from the model when there's no data, so compute it manually
        caretX = 71.55 + 15.11 * (tooltipModel.dataPoints[0].index);
    }

    tooltip.css('left', `${position.left + window.pageXOffset + caretX - (tooltip.width() / 2)}px`);
    tooltip.css('top', `${$('#spacer-tooltip')[0].getBoundingClientRect().top + window.pageYOffset - 93}px`);
    tooltip.css('opacity', 1);
}


export { onBlockfaceOccupancyReceived, onBlockfaceOccupancyError, buildCustomTooltip }
