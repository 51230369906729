export default function(link) {
  let n = link.indexOf("?");
  link = link.substring(0, n != -1 ? n : link.length);
  const distinct_id = "";
  const experiment_group_branch = "&experiment_group=ux";
  console.log(
    link +
      "?~tags=" +
      encodeURIComponent(window.location.href) +
      "&~tags=" +
      window.location.pathname +
      experiment_group_branch +
      distinct_id +
      "&$ios_deepview=false&$og_redirect=" +
      encodeURIComponent(window.location.href)
  );
  return (
    link +
    "?~tags=" +
    encodeURIComponent(window.location.href) +
    "&~tags=" +
    window.location.pathname +
    experiment_group_branch +
    distinct_id +
    "&$ios_deepview=false&$og_redirect=" +
    encodeURIComponent(window.location.href)
  );
}
