<template>
    <div class="payment_popup" id="payment_success">
        <div class="payment_modal">

            <div class="link" v-on:click="showParkingPass">Close</div>

            <img class="payment_popup_image" src="https://spotangels-assets.s3.amazonaws.com/payment_email.png">

            <div v-if="$parent.isMonthly">
                <p class="payment_popup_title_text">A confirmation email has been sent to {{email}}</p>
                <p class="payment_popup_text">It has the information on the next steps</p>
            </div>
            <div v-else>
                <p class="payment_popup_title_text">Your parking pass has been sent to {{email}}</p>
                <p class="payment_popup_text">It has the information needed to access the garage and cancel/modify your reservation</p>
            </div>

            <a href="http://download.spotangels.com/QLIf/YNgz8s7lgJ" onclick="branchTags()" style="text-decoration: none;">
                <button class="payment_popup_button"><div class="mobile"></div><div class="text">Download app</div></button>
            </a>

            <div class="payment_popup_text link" v-on:click="showParkingPass">See Order Summary</div>

        </div>
    </div>
</template>

<script>
    export default {
        props: ["email"],
        methods: {
            showParkingPass() {
                this.$parent.paymentSuccess = false;
                this.$parent.parkingPass = true;
            },
        }
    }
</script>

<style scoped>

</style>