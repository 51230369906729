<template>
  <div class="park-regulation">
    <button @click="toggleMonthly">View Monthly Parking Options</button>
  </div>
</template>

<script>
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  props: ["segmentId", "cityId"],
  methods : {
    toggleMonthly() {
      TrackHelper.viewMonthlyOptionsClicked(this.segmentId, this.cityId)
      window.location.href = window.location.href.replace('/#', '/?monthly=true#')
    }
  }
};
</script>

<style scoped>
  .park-regulation {
    border-bottom: 4px solid #f5f7f8;
    padding: 16px;
    font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #8293a3;
  }

  button {
    background: #1C9BE6;
    border: 1px solid #1C9BE6;
    box-sizing: border-box;
    border-radius: 24px;
    font-size: 16px;
    padding: 8px 28px;
    color: #FFF;
    display: block;
    width: 100%;
    cursor: pointer;
  }
</style>