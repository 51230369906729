export default {
  namespaced: true,
  state: {
    showSidebar: false,
  },
  mutations: {
    showSidebar(state, payload) {
      state.showSidebar = payload;
    },
    toggleSidebar(state) {
      state.showSidebar = !state.showSidebar;
    },
  },
  actions: {
    showSidebar(context, payload) {
      // console.log('triggered showsidebar', payload)
      context.commit("showSidebar", payload);
    },
    toggleSidebar(context) {
      context.commit("toggleSidebar");
    },
  },
  getters: {
    showSidebar: (state) => {
      return state.showSidebar;
    },
  },
};
