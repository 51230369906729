<template>
  <div class="container">
    <Backdrop />
    <div class="share-modal">
      <img :src="require('images/plane.svg')" />
      <div class="title">Share link to the map</div>
      <div class="link">{{ sharingLink }}</div>
      <input type="hidden" id="link-sharing" :value="sharingLink" />
      <button @click.stop.prevent="copyLink">{{ caption }}</button>
    </div>
  </div>
</template>

<script>
import TrackHelper from "../../../utils/analytics/amplitude";
import Backdrop from "./Backdrop";
export default {
  props: ["link"],
  data() {
    return {
      sharingLink: null,
      caption: "Copy link",
    };
  },
  components: {
    Backdrop,
  },
  mounted() {
    this.sharingLink = document.URL;
  },
  methods: {
    copyLink() {
      TrackHelper.shareLinkCopied("monthly");
      const textToCopy = document.querySelector("#link-sharing");
      textToCopy.setAttribute("type", "text");
      textToCopy.select();
      try {
        document.execCommand("copy");
        this.caption = "Copied ✓";
      } catch (err) {
        console("unable to copy");
      }
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.share-modal {
  position: absolute;
  margin: 0 auto;
  z-index: 11;
  width: 90%;
  top: 20%;
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 4px;
  text-align: center;
}
@media (min-width: 768px) {
  .share-modal {
    padding: 3rem;
    width: 50%;
  }
}
.title {
  color: #545e69;
  margin-top: 56px;
  margin-bottom: 12px;
  text-align: center;
}
.img {
  width: 120px;
}
.link {
  background: #f5f7f8;
  color: #8293a3;
  font-size: 16px;
  border-radius: 6px;
  padding: 16px;
  text-align: left;
  word-break: break-all;
}
button {
  background: #1c9be6;
  border: 1px solid #1c9be6;
  box-sizing: border-box;
  border-radius: 24px;
  color: #fff;
  font-size: 16px;
  margin-top: 56px;
  padding: 12.5px 0px;
  width: 100%;
  cursor: pointer;
}
</style>
