<template>
    <div>
        <!--<img :src="garage_picture_url.replace('http://s3','https://s3')" class="pass_picture">-->
        <img :src="mapbox_image_url" class="pass_picture">
    </div>
</template>

<script>
    export default {
        data() {
            return {

            };
        },
        computed: {
            mapbox_image_url : function() {
                let base_url = 'https://api.mapbox.com/styles/v1/hamzao'
                let style = 'cje7fj5fp97br2so3oqpcjcbz'
                let overlay = 'pin-l-p+2d6f9f'
                let token = 'pk.eyJ1IjoiaGFtemFvIiwiYSI6ImNpaG5wZW1rcDBwZHl0Y2x6YmRkYm4xeDIifQ.edx8_PwqJurn00EdduZGFw'
                return `${base_url}/${style}/static/${overlay}(${this.lng},${this.lat})/${this.lng},${this.lat},15,0,20/400x400@2x?access_token=${token}`
            },
        },
        props: ["lng", "lat"],
    }

</script>

<style scoped>

</style>