<template>
  <div>
    <h3 class="need-parking">Most searched places in {{ seo_data.seo_parent.name }}</h3>
    <ul class="popular">
      <li v-for="(other, index) in seo_data.seo_most_searched" :key="index"><a :href="angelsWebUrl + other.url">{{ other.name }} Parking</a></li>
    </ul>
  </div>
</template>

<script>
import seoHelper from "../../../utils/seoHelper"

export default {

  data() {
    return {
      angelsWebUrl: angelsWebUrl,
      seo_data: seoHelper.seo_data,
    };
  },
};
</script>

<style scoped>
h3 {
  font-size: 14px;
}
.need-parking {
  line-height: 23px;
  font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
  font-weight: normal;
  padding: 0px 12px 0 12px;
  color: #545E69;
  margin: 0;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

ul.popular {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 3%;
  margin-top: 8px;
}

ul.popular li {
  font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 12px;
  color: #8593a1 !important;
}

a {
  font-family: "MuseoSansRounded", Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: #1c9be6;
  line-height: 14px;
  text-decoration: none;
}
</style>