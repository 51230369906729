<template>
  <div class="backdrop" @click="closeModal"></div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$store.dispatch("utils/showShareModal", false);
    },
  },
};
</script>

<style scoped>
.backdrop {
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
}
</style>
