<template>
    <div class="info_box">
        <div class="main_title">YOUR INFORMATION</div>
        <p v-if="deal.payment.license_plate_required">
            <label for="license_plate" class="small_title">LICENSE PLATE</label><span class="checkout_error"> {{errors.license_plate}}</span>
            <br>
            <input id="license_plate" ref="license_plate" v-model="license_plate" type="text" name="license_plate" placeholder="Enter your license plate number" v-on:input="clearErrors()" autofocus>
        </p>
        <p v-if="deal.payment.phone_number_required || is_monthly">
            <label for="phone_number" class="small_title">PHONE NUMBER</label><span class="checkout_error"> {{errors.phone_number}}</span>
            <br>
            <input id="phone_number" ref="phone_number" v-model="phone_number" type="number" name="phone_number" placeholder="Enter your phone number" v-on:input="clearErrors()">
        </p>
        <p>
            <label for="email" class="small_title">EMAIL</label><span class="checkout_error"> {{errors.email}}</span>
            <br>
            <input id="email" ref="email" v-model="email" type="text" name="email" placeholder="Enter your email" v-on:input="clearErrors()">
        </p>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                license_plate: localStorage.getItem('license_plate'),
                phone_number: localStorage.getItem('phone_number'),
                email: localStorage.getItem('email'),
                errors: {},
            };
        },
        methods: {
            isFormValid: function () {
                this.errors = {license_plate: '', phone_number:'', email:''};

                if (this.deal.payment.phone_number_required && !this.phone_number) {
                    this.errors.phone_number = 'Phone number required';
                }
                if (this.deal.payment.license_plate_required && !this.license_plate) {
                    this.errors.license_plate = 'License plate required';
                }
                if (!this.email) {
                    this.errors.email = 'Email required';
                } else if (!this.validEmail(this.email)) {
                    this.errors.email = 'Valid email required';
                }

                let valid = (this.errors.license_plate === '' && this.errors.email === '' && this.errors.phone_number === '')
                if (valid) {
                    if (this.phone_number)
                        localStorage.setItem('phone_number', this.phone_number)
                    if (this.license_plate)
                        localStorage.setItem('license_plate', this.license_plate)
                    if (this.email)
                        localStorage.setItem('email', this.email)
                }
                this.setFocusOnErrorField()

                return valid
            },
            validEmail: function (email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            clearErrors: function () {
                this.errors = {license_plate: '', phone_number:'', email:''};
            },
            setFocusOnErrorField: function () {
              if (this.errors.license_plate !== '')
                  this.$refs.license_plate.focus()
              else if (this.errors.phone_number !== '')
                  this.$refs.phone_number.focus()
              else if (this.errors.email !== '')
                  this.$refs.email.focus()
            },
        },
        props: ["deal","is_monthly"],
    }
</script>

<style scoped>

</style>