<template>
  <div class="mobile-banner">
    <div class="brand">
      <img :src="require('images/spotangels-symbol.svg')" class="logo" />
      <div>
        <div class="brand-name">SpotAngels</div>
        <div class="brand-description">Making parking easy together</div>
      </div>
    </div>
    <a
      href="http://download.spotangels.com/yHFdwFtuvO"
      @click="branchTagsClick"
      class="cta"
      >GET THE APP</a
    >
  </div>
</template>

<script>
import branchTags from "../../../utils/branchTags";
export default {
  methods: {
    branchTagsClick(element) {
      branchTags(element.target.href);
    },
  },
};
</script>

<style scoped>
.mobile-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c9be6;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  color: #fff;
  font-size: 13px;
  height: 68px;
  letter-spacing: 0.01em;
  line-height: 14px;
  padding: 16px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}
@media (max-width: 991px) and (min-width: 768px) {
  .mobile-banner {
    position: relative;
  }  
}
.brand {
  display: flex;
  align-items: center;
}
.brand-name {
  font-size: 15px;
  line-height: 20px;
}
.logo {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}
.cta {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50px;
  color: #fff;
  font-size: 75%;
  padding: 6px 8px;
  text-decoration: none;
}
</style>
