<template>
    <div id="summary_container" class="overview">

        <div class="pass_title">
            <div>RESERVATION #{{reservation_id}}<br>{{segment.status.garage_name}}</div>
        </div>

        <div class="parking_pass">A confirmation was sent to {{payment_data.email}}</div>
        <MapboxImage :lng="$parent.lng" :lat="$parent.lat"/>

        <div class="pass_table">
            <div class="pass_cell">Location<span class="pass_cell_value">{{segment.status.garage_name}}</span></div>
            <div class="pass_cell">Address<span class="pass_cell_value"><a :href="gmaps_url" target="_blank" class="link">{{segment.status.address}}</a></span></div>
            <div class="pass_cell" v-if="(payment_data.license_plate)">Vehicle<span class="pass_cell_value">{{payment_data.license_plate}}</span></div>
            <div class="pass_cell">Payment<span class="pass_cell_value">{{card_id}}</span></div>
            <div v-if="$parent.isMonthly">
                <div class="pass_cell">Start Date<span class="pass_cell_value">{{payment_data.start_time.toDateString()}}</span></div>
                <div class="pass_cell">Confirmation #<span class="pass_cell_value">{{reservation_id}}</span></div>
                <div class="pass_cell" v-if="(segment.status.operator_name)">Operated by<span class="pass_cell_value">{{segment.status.operator_name}}</span></div>
                <div class="pass_cell">Rate type<span class="pass_cell_value">${{$parent.deal.description}}</span></div>
                <div class="pass_cell">Monthly Rate<span class="pass_cell_value">${{payment_data.rate}}</span></div>
                <div class="pass_cell" v-if="$parent.isDownPayment">Charged Down Payment<span class="pass_cell_value">{{$parent.deal.finalPrice}}</span></div>
            </div>
            <div v-else>
                <div class="pass_cell">Start time<span class="pass_cell_value">{{formatDate(payment_data.start_time)}}</span></div>
                <div class="pass_cell">End time<span class="pass_cell_value">{{formatDate(payment_data.end_time)}}</span></div>
                <div class="pass_cell">Confirmation #<span class="pass_cell_value">{{reservation_id}}</span></div>
                <div class="pass_cell" v-if="(segment.status.operator_name)">Operated by<span class="pass_cell_value">{{segment.status.operator_name}}</span></div>
                <div class="pass_cell">Parking fee<span class="pass_cell_value">${{payment_data.rate}}</span></div>
            </div>

        </div>

        <div v-if="!$parent.isMonthly" class="view_pass_in_app"><img :src="logoImage" class="pass_logo">
            <span style="padding:10px 0 0 10px">View Parking Pass in the App<br>
            <a href="http://download.spotangels.com/QLIf/YNgz8s7lgJ" onclick="branchTags()" class="link">Download the app</a></span>
        </div>
    </div>
</template>

<script>
    import logoImage from "../../../assets/images/spotangels-logo.png";
    import MapboxImage from "../../components/checkout/mapbox_image.vue";

    export default {
        data() {
            return {
                logoImage,
            };
        },
        components: {
            MapboxImage,
        },
        computed: {
            reservation_id : function() {
                return (this.payment_response.partner_response.reservation_id) ? this.payment_response.partner_response.reservation_id : (this.payment_response.partner_response.id) ? 'SA' + this.payment_response.partner_response.id : '-'
            },
            card_id : function () {
                return (this.payment_response.card.card_number) ? ('**** ' + this.payment_response.card.card_number.slice(-4)) : 'Wallet payment'
            },
            gmaps_url : function() {
              return `http://maps.google.com/maps?z=14&t=m&q=loc:${this.$parent.lat}+${this.$parent.lng}`
            },
        },
        methods: {
            formatDate(date) {
                let unformattedDate = parseParamDate(date + ':00');
                let day = ("0" + unformattedDate.getDate()).slice(-2);
                let month = ("0" + (unformattedDate.getMonth() + 1)).slice(-2);
                let hours = unformattedDate.getHours();
                let minutes = unformattedDate.getMinutes();
                let ampm = hours >= 12 ? 'PM' : 'AM';
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0'+minutes : minutes;
                return month + "/" + day + " " + hours + ':' + minutes + ' ' + ampm;
            }
        },
        props: ["payment_data", "segment", "payment_response"],
    }
</script>

<style scoped>

</style>