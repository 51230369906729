<template>
  <div id="geocoder" class="geocoder"></div>
</template>

<script>
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {mapGetters} from "vuex"
import seoHelper from "../../utils/seoHelper"

export default {
  methods: {
    createSearch() {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxToken,
        countries: "US, CA, GB, FR, MA, DE, NL, BE",
        placeholder: "Where do you need Parking?",
        clearAndBlurOnEsc: true,
        flyTo: false,
        marker: false,
      });
      geocoder.on("result", (response) => {
        document.querySelector("#geocoder input").blur()
        this.$store.dispatch("filter/setSearchString", response.result.place_name);
        this.$store.dispatch("map/setSearchPosition", response.result.center);
        this.mapClass.mapboxHelper.map.jumpTo({
          center: response.result.center,
          zoom: 15,
          bearing: 0,
          speed: 2,
        });
        this.mapClass.setSearchLink(
          response.result.place_name,
          response.result.center[0],
          response.result.center[1],
          true
        );
        this.mapClass.setSearchPin();
        this.$store.dispatch("map/setRecommendations");
        this.$store.dispatch("map/setSidebarContent", "recommendations");
        this.$store.dispatch("sidebar/showSidebar", true);
        // Clear seo data to enable back to list button to show
        seoHelper.seo_data = undefined
      });
      geocoder.on("clear", (ev) => {
        this.mapClass.clearSearchPin();
        this.$store.dispatch("map/setRecommendationList", "");
      })
      this.$store.dispatch("filter/setGeocoder", geocoder);
    },
  },
  mounted() {
    this.createSearch();
  },
  computed: {
    ...mapGetters("map", ["mapClass", "sidebarContent"]),
  },
};
</script>

<style scoped>
.geocoder {
  width: 100%;
}
</style>
<style>
#geocoder .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon-search {
  fill: #1c9be6;
}
#geocoder .mapboxgl-ctrl-geocoder--input {
  padding: 6px 10px 6px 40px;
}
#geocoder .mapboxgl-ctrl-geocoder,
#geocoder .mapboxgl-ctrl-geocoder .suggestions  {
  box-shadow: none;
}
#geocoder .mapboxgl-ctrl-geocoder--input::-webkit-input-placeholder {
  color: #ced6de;
}
#geocoder .mapboxgl-ctrl-geocoder--input::-moz-placeholder {
  color: #ced6de;
}
#geocoder .mapboxgl-ctrl-geocoder--input:-ms-input-placeholder {
  color: #ced6de;
}
#geocoder .mapboxgl-ctrl-geocoder--input:-moz-placeholder {
  color: #ced6de;
}
#geocoder .mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
}
@media (min-width: 768px) {
  #geocoder {
    padding: 0 10px;
  }
  #geocoder .mapboxgl-ctrl-geocoder {
    border-radius: 0;
    padding-left: 35px;
    max-width: 100%;
    width: 100%;
  }
  #geocoder .mapboxgl-ctrl-geocoder--icon-search {
    display: block;
  }
  #geocoder .mapboxgl-ctrl-geocoder--input {
    padding: 0;
  }
  #geocoder .mapboxgl-ctrl-geocoder,
  #geocoder .mapboxgl-ctrl-geocoder .suggestions  {
    box-shadow: none;
  }
}
</style>
