<template>
  <div>
    <div class="park-summary">
      <div class="park-summary-wrapper">
        <img
          v-if="icons[data.status.name]"
          class="spot-icon"
          :src="require('images/' + icons[data.status.name])"
        />
        <div class="meta">
          <Summary
            v-if="data.status && data.status.summary"
            :data="data.status.summary"
          />
          <Rating
            v-if="data.review_data && data.review_data[0].reviews"
            :data="data.review_data[0]"
          />
        </div>
        <Share />
      </div>
      <PayMeter v-if="data.status.meter_payment !== undefined && !isMobile && data.status.can_pay_in_app !== true" :data="data.status"/>
    </div>
    <div class="getapp-spot-info">
      <a
        v-if="isMobile"
        rel="noopener"
        href="http://download.spotangels.com/yHFdwFtuvO"
        class="cta-button animated pulse"
      >
        Get the free app to see more
      </a>
    </div>
    <div :class="isMobile ? 'blur' : ''">
      <Pictures v-if="data.pictures.length > 0" :data="data.pictures" />
      <div v-if="showHistogram" class="chart-wrapper">
        <div class="chart-title">Crowdsourced Availability</div>
        <div id="spacer-tooltip">
          <div id="histogram-tooltip"></div>
        </div>
        <div id="histogram">
          <Histogram :key="data.status.id" />
        </div>
      </div>
      <OpeningHours
        v-if="data.calendar && data.calendar.length"
        :data="data.calendar"
      />
      <Holidays
        v-if="data.holidays"
        :holidays="data.holidays"
      />
      <ContributionStatus
        v-if="data.crowdsource && data.crowdsource.contribution_status"
        :data="data.crowdsource.contribution_status"
      />
      <Reviews
        v-if="data.review_data"
        :data="data.review_data"
      />
      <Address
        v-if="data.status && data.status.address"
        :data="data.status.address"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Rating from "./Rating";
import Histogram from "./Histogram";
import OpeningHours from "./OpeningHours";
import Reviews from "./Reviews";
import Address from "./Address";
import ContributionStatus from "./ContributionStatus";
import Summary from "./Summary";
import Pictures from "./Pictures";
import PayMeter from "./PayMeter";
import Share from "../ui/Share";
import Holidays from "./Holidays"

export default {
  components: {
    Share,
    Rating,
    Reviews,
    Address,
    Histogram,
    OpeningHours,
    ContributionStatus,
    Summary,
    Pictures,
    PayMeter,
    Holidays,
  },
  props: ["data"],
  data() {
    return {
      segment: {},
      isMobile: false,
      icons: {
        Free: "free.svg",
        MeterHour: "meter.svg",
        "TimeLimit": "time-limit.svg",
      },
    };
  },
  computed: {
    ...mapGetters("map", ["getMapClassProperty"]),
    ...mapGetters("map", ["currentHistogram"]),
    showHistogram() {
      return (
        this.currentHistogram &&
        this.currentHistogram.bins.filter(
          (item) => item.description !== "No data"
        ).length > 0
      );
    },
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
};
</script>

<style scoped>
.blur {
  filter: blur(3px);
}
.cta-button {
  background: #1c9be6;
  border: 1px solid #1c9be6;
  border-radius: 24px;
  font-size: 16px;
  padding: 14px 20px;
  color: #fff;
  margin: 10px auto;
  display: block;
  width: 80%;
  text-decoration: none;
  -webkit-animation-iteration-count: 3;
  text-align: center;
  animation-iteration-count: 3;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}
#histogram {
  position: relative;
  height: 120px;
  padding: 0;
}
#spacer-tooltip {
  margin-top: 10px;
  background: white;
  height: 30px;
  width: 100%;
}
#histogram-tooltip {
  white-space: nowrap;
  padding: 6px;
  position: absolute;
  opacity: 0;
  background: none 0% 0% repeat scroll white;
  border: 1px solid rgb(206, 214, 222);
  border-radius: 6px;
  font-size: 14px;
  color: rgb(33, 37, 41);
  pointer-events: none;
}
.park-summary {
  border-bottom: 4px solid #f5f7f8;
  padding: 16px;
}
.park-summary-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.park-summary .meta > div {
  padding-bottom: 5px;
}
.meta h3 {
  color: #545e69;
  font-weight: bold;
  padding-bottom: 5px;
}
.title {
  color: #545e69;
  font-weight: bold;
  margin: 10px 0;
}
.toggle {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 55px;
  left: 0;
  background: white;
  border-radius: 0 3px 3px 0;
  box-shadow: 1px 0 4px 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  width: 22px;
  height: 50px;
}
.toggle img {
  height: 14px;
}

@keyframes slide-open {
  0% {
    left: -395px;
  }
  100% {
    left: 0;
  }
}
@keyframes slide-close {
  0% {
    left: 0;
  }
  100% {
    left: -395px;
  }
}
.chart-wrapper {
  padding: 20px;
}
.chart-title {
  font-weight: bold;
  color: #545e69;
  font-size: 14px;
}
.spot-icon {
  width: 20px;
  height: 20px;
  padding-right: 2px;
}
</style>
