<template>
  <header>
    <MobileBanner class="mobile-banner-for-desktop" />
    <transition name="fade" mode="out-in">
      <Navbar v-if="showNavbar" />
    </transition>
    <div class="menu">
      <div class="nav">
        <div class="logo-wrapper">
          <div @click="showNavbar = !showNavbar" class="burger">
            <svg viewBox="0 0 100 80" width="40" height="15">
              <rect width="100" height="14" rx="6" />
              <rect y="30" width="100" height="14" rx="6" />
              <rect y="60" width="100" height="14" rx="6" />
            </svg>
          </div>
          <a href="/">
            <img :src="require('images/SPOTANGELS.svg')" class="logo" />
          </a>
        </div>

        <ul class="menu-item">
          <li v-on:click="toggleHourly" v-bind:class="{ active: !monthlyMode }">
            Hourly
          </li>
          <li v-on:click="toggleMonthly" v-bind:class="{ active: monthlyMode }">
            Monthly
          </li>
        </ul>
      </div>
      <div class="filter-wrapper">
        <div class="filter">
          <Search />
          <DateFilter v-if="!monthlyMode" />
        </div>
        <a
          href="http://download.spotangels.com/QLIf/YNgz8s7lgJ"
          @click="branchTagsClick"
          title="Get Spotangels app"
          class="cta-download-app"
        >
          <img
            :src="require('images/icon-smartphone.svg')"
            alt="Get Spotangels app"
          />
          Get the app
        </a>
      </div>

    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import branchTags from "../../utils/branchTags";
import MobileBanner from "../layout/banner/MobileBanner";
import Navbar from "./Navbar";
import DateFilter from "../filter/DateFilter";
import Search from "../filter/Search";
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  data() {
    return {
      showNavbar: false,
    };
  },
  components: {
    Navbar,
    Search,
    DateFilter,
    MobileBanner
  },
  methods: {
    toggleMonthly() {
      if (this.monthlyMode) return;
      TrackHelper.headerTapClicked("monthly");
      this.$store.dispatch("filter/setMonthlyMode", true);
      this.updateMapAndSidebar();
      if ("URLSearchParams" in window) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("monthly", "true");
        let newRelativePathQuery =
          window.location.pathname +
          "?" +
          searchParams.toString() +
          window.location.hash;
        history.pushState(null, "", newRelativePathQuery);
      }
      // // Close sidebar if onstreet segment
      // if (
      //   this.sidebarContent === "spotsheet" &&
      //   this.spotSheet.status.name !== "Garage"
      // )
      //   this.$store.dispatch("sidebar/showSidebar", false);
    },
    toggleHourly() {
      if (!this.monthlyMode) return;
      TrackHelper.headerTapClicked("hourly");
      this.$store.dispatch("filter/setMonthlyMode", false);
      this.updateMapAndSidebar();
      let newRelativePathQuery =
        window.location.pathname + window.location.hash;
      history.pushState(null, "", newRelativePathQuery);
    },
    updateMapAndSidebar() {
      this.$store.dispatch("map/reloadTiles", {});
      if (this.sidebarContent === "spotsheet") {
        this.$store.dispatch("map/setRecommendationList", "");
        // If we switched to monthly with a onstreet segment, we keep sidebar closed
        if (this.monthlyMode && this.spotSheet.status.name !== 'Garage') return;
        this.$store.dispatch("map/setSpotsheet");
        this.mapClass.pendingSourceData = true;
      } else if (this.sidebarContent === "recommendations" || this.sidebarContent === "seo") {
        this.mapClass.setSearchPin();
        this.$store.dispatch("map/setRecommendations");
      }
    },
    branchTagsClick(element) {
      branchTags(element.target.href);
    },
  },
  computed: {
    ...mapGetters("filter", [
      "monthlyMode",
      "getFilteredDate",
      "getFilterPayload",
    ]),
    ...mapGetters("map", ["sidebarContent", "spotSheet", "mapClass"]),
  },
};
</script>

<style scoped>
header {
  display: flex;
  flex-direction: column;
  background: white;
  position: fixed;
  width: 100%;
  z-index: 10;
}
.menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e0e6ea;
  height: 93px;
  padding: 0 10px;
}
.nav {
  display: flex;
  align-items: center;
}
.logo-wrapper {
  display: flex;
  align-items: center;
  margin-right: 15px;
}
.burger {
  cursor: pointer;
  margin-right: 10px;
}
.logo {
  height: 25px;
}
ul.menu-item {
  display: flex;
  list-style: none;
  margin: 0 15px;
  padding: 0;
}
ul.menu-item li {
  cursor: pointer;
  margin-right: 14px;
}
ul.menu-item li:last-of-type {
  padding-right: 0;
}
ul.menu-item li {
  color: #8293a3;
  font-size: 16px;
  letter-spacing: 1px;
  text-decoration: none;
}
ul.menu-item li.active {
  border-bottom: 3px solid #1c9be6;
  color: #212529;
}
.filter-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 400px);
  /*margin-right: 10px;*/
  /*flex: 1 1;*/
}
.filter {
  display: flex;
  align-items: center;
  border: 1px solid #e0e6ea;
  border-radius: 24px;
  height: 50px;
  flex: 1 1;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.cta-download-app {
  display: flex;
  align-items: center;
  background: #1c9be6;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
  grid-gap: 10px;
  padding: 14px 30px 14px 15px;
  text-decoration: none;
  margin-left: 3%;
  white-space: nowrap;
}
.cta-download-app img {
  width: 12px;
}
.mobile-banner-for-desktop {
  display: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .mobile-banner-for-desktop {
    display: flex;
  }
  .cta-download-app {
    display: none;
  }
  .logo-wrapper {
    display: none;
  }
}
</style>
