<template>
  <div v-html="data" class="contribution"></div>
</template>

<script>
export default {
  props: ["data"],
};
</script>

<style scoped>
.contribution {
  border-bottom: 4px solid #f5f7f8;
  padding: 0 16px 16px;

}
</style>

<style>
font {
  padding-bottom: 5px;
  display: inline-block;
}
</style>