const parseDate = (date, hour) => {
  let getDate = date.toISOString().split('T')[0]
  // This is in case we need this function before the created loop of SelectTime.vue
  if (typeof hour !== 'string') {
    console.log('Warning : unexpected hour format', hour)
    if (hour === 12)
       hour = '12:00 PM'
    else if (hour > 12) {
      hour = ('0' + (hour - 12).toString()).slice(-2) + ':00 PM'
    }
    else
      hour = ('0' + hour.toString()).slice(-2) + ':00 AM'
  }
  const ampm = hour.split(' ')[1]
  const time = hour.split(' ')[0].split(':')
  time.push(ampm)
  if(ampm === 'PM' && time[0] !== '12' ) {
    time[0] = (Number(time[0]) + 12).toString()
  }
  if(ampm === 'AM' && time[0] === '12' ) {
     time[0] = (Number(time[0]) - 12).toString().concat('0')
  }
  return new Date(getDate + 'T' + ('0' + time[0]).slice(-2) + ':' + time[1])
}

  export { parseDate }