import mapboxgl from "mapbox-gl";
import * as turf from '@turf/turf';
import {SatelliteLayerControl} from "./satelliteLayerControl"

class MapboxHelper {
  constructor(accessToken, mapSettings, containerId, filters, initialZoom, initialCenter) {
    mapboxgl.accessToken = accessToken;

    this.map = new mapboxgl.Map({
      style: mapSettings.style_url + '?fresh=true',
      container: containerId,
      logoPosition: 'bottom-right',
      maxZoom: 18,
      zoom: initialZoom,
      center: initialCenter,
    });

    this.mapSettings = mapSettings;
    this.filters = filters;

    this.map.doubleClickZoom.disable();
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disable();

    this.map.on('sourcedata', e => { this.onSourceData(e) });
    this.map.on('load', e => { this.onMapReady(e); });
    this.map.on('style.load', _ => { this.refreshSources(); });

    this.onMapReadyListener = null;
    this.onMapClickListener = null;
    this.onSourceDataListeners = {};
  }

  static newVectorSource(path, maxZoom) {
    return {
      'type': 'vector',
      'maxzoom': maxZoom,
      'tiles': cdnBaseUrls.map(url => url + path),
    }
  }

  static newGeoJsonSource(features = []) {
    return {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": features
      },
    }
  }

  isStyleLoaded() {
    return this.map.isStyleLoaded();
  }

  onFiltersChanged(filters) {
    this.filters = filters;
    this.refreshSources();
  }

  setCanRotate(canRotate) {
    if (canRotate) {
      this.map.dragRotate.enable();
      this.map.touchZoomRotate.enable();
    } else {
      this.map.dragRotate.disable();
      this.map.touchZoomRotate.disable();
    }
  }

  addGeocoder(elt, placeholder, onResult) {
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: placeholder,
      clearAndBlurOnEsc: true,
      flyTo: false,
      marker: false
    });
    geocoder.on('result', (e) => onResult(e.result));

    elt.appendChild(geocoder.onAdd(this.map))
  }

  setOnMapReadyListener(listener) {
    this.onMapReadyListener = listener;
  }

  setOnMapClickListener(listener) {
    this.onMapClickListener = listener;
  }

  setOnMapMoveListener(listener) {
    this.onMapMoveListener = listener;
  }

  setOnSourceDataListener(source, listener) {
    this.onSourceDataListeners[source] = listener;
  }

  replaceSource(id, source) {
    const styleLayers = this.map.getStyle().layers;
    const removedLayers = [];
    const aboveLayerIds = [];

    // Store position of the source's layers in the style
    for (let i = 0; i < styleLayers.length; i++) {
      let layer = styleLayers[i];
      if (layer.source === id) {
        removedLayers.push(layer);
        if (i < styleLayers.length - 1) {
          aboveLayerIds.push(styleLayers[i + 1].id);
        } else {
          aboveLayerIds.push(-1);
        }
      }
    }

    // Actually remove the layers
    for (let i = 0; i < removedLayers.length; i++) {
      this.map.removeLayer(removedLayers[i].id);
    }

    // Replace the source
    try {
      this.map.removeSource(id);
    } catch {
      console.log('Source not found', id)
    }

    this.map.addSource(id, source);

    // Restore the layers in their original position
    for (let i = removedLayers.length - 1; i >= 0; i--) {
      let aboveLayerId = aboveLayerIds[i];
      let layer = removedLayers[i];

      if (source.type === 'geojson') {
        delete layer['source-layer']
      }

      if (aboveLayerId !== -1) {
        this.map.addLayer(layer, aboveLayerId);
      } else {
        this.map.addLayer(layer)
      }
    }
  }

  setSourceFeatures(id, features) {
    const source = this.map.getSource(id);
    if (typeof (source) === 'undefined') {
      console.log('Warning: attempted to set source features on ' + id + ' but not found');
      return;
    } else if (source.type !== 'geojson') {
      console.log('Warning: attempted to set features on non-geojson source ' + id);
      return
    }

    source.setData({
      'type': 'FeatureCollection',
      features: features,
    })
  }

  clearSource(id) { this.setSourceFeatures(id, []); }

  jumpTo(center, zoom = 16.0) {
    this.map.jumpTo({center: center, zoom: zoom});
  }

  flyTo(center, zoom = 16.0) {
    this.map.flyTo({
      center: center,
      zoom: zoom,
      bearing: 0,
      speed: 2
    });
  }

  getFeatureById(id) {
    let features = this.map.querySourceFeatures('mapbox://hamzao.9giqc7de', {
      sourceLayer: 'centroids'
    });
    for (let i = 0; i < features.length; i++) {
      if (features[i].id === id) {
        return features[i].toJSON();
      }
    }

    features = this.map.querySourceFeatures('mapbox://hamzao.9qk29p1o', {
      sourceLayer: 'segments'
    });
    for (let i = 0; i < features.length; i++) {
      if (features[i].id === id) {
        return features[i].toJSON();
      }
    }

    features = this.map.querySourceFeatures('mapbox://hamzao.cjnnqtlek0npz31jvgf4k5bki-4a5qp', {
      sourceLayer: 'segments'
    });
    for (let i = 0; i < features.length; i++) {
      if (features[i].id === id) {
        return features[i].toJSON();
      }
    }
    return null
  }

  querySourceFeaturesById(source, sourceLayer, featureId) {
    return this.map.querySourceFeatures(source, {sourceLayer: sourceLayer, filter: ["==", "$id", featureId]});
  }

  getAllFeatures(feature, nolabel) {
    let allFeatures = [feature];
    let search = ['centroid', 'segment'];
    if (!nolabel && feature.layer.id !== "labels" && feature.layer.id !== "labels-garage" && feature.layer.id !== "labels-noparking") {
      search = ['centroid'];
    } else if (!nolabel) {
      search = ['segment'];
    }
    if (search.indexOf("centroid") !== -1 && feature) {
      // deals layer first
      const centroidFeatures = this.map.querySourceFeatures('mapbox://hamzao.9giqc7de', {
        sourceLayer: 'centroids',
        filter: ["in", "$id", feature.id]
      });
      if (centroidFeatures.length > 0)
        allFeatures = allFeatures.concat(centroidFeatures)
      else {
        const missingFeature = turf.center(feature.toJSON());
        missingFeature.properties = feature.properties;
        allFeatures.push(missingFeature)
      }
    }
    if (search.indexOf("segment") !== -1 && feature && feature.id != null) {
      const segmentsFeatures = this.map.querySourceFeatures('mapbox://hamzao.9qk29p1o', {
        sourceLayer: 'segments',
        filter: ["in", "$id", feature.id]
      });
      if (segmentsFeatures.length > 0)
        allFeatures = allFeatures.concat(segmentsFeatures)
    }

    return (allFeatures)
  };

  setSourceLayersVisible(sourceId, visible) {
    let styleLayers = this.map.getStyle().layers;
    let visibility = visible ? 'visible' : 'none';
    for (let i = 0; i < styleLayers.length; i++) {
      let layer = styleLayers[i];
      if (layer.source === sourceId) {
        this.map.setLayoutProperty(layer.id, 'visibility', visibility);
      }
    }
  }

  addMapControls() {
    // Add controls
    this.map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    let geoLocateControl = new mapboxgl.GeolocateControl({
      showUserLocation: true
    });
    this.map.addControl(geoLocateControl, 'top-right');
    this.satelliteLayer = new SatelliteLayerControl();
    this.map.addControl(this.satelliteLayer, 'top-right');
  }

  /*
   Private methods, please don't call from outside
   */

  onMapReady() {
    this.map.on('mousemove', e => { this.onMouseMove(e)});
    this.map.on('click', e => { this.onMapClick(e) });
    this.map.on('moveend', e => { this.onMapMove(e) });
    this.map.on('zoomend', e => { this.onMapMove(e) });

    if (this.onMapReadyListener !== null) {
      this.onMapReadyListener();
    }
  }

  onMouseMove(e) {
    if (this.onMapClickListener === null || this.mapSettings.clickable_layers.length === 0) {
      return
    }

    this.map.getCanvas().style.cursor = this.findFeatureAt(e) !== null ? 'pointer' : '';
  }

  onMapClick(e) {
    if (this.onMapClickListener === null || this.mapSettings.clickable_layers.length === 0) {
      return
    }

    this.onMapClickListener(e.lngLat, this.findFeatureAt(e));
  }

  onMapMove(e) {
    if (this.onMapMoveListener == null) {
      return
    }

    this.onMapMoveListener(e)
  }

  onSourceData(e) {
    if (!this.onSourceDataListeners.hasOwnProperty(e.sourceId)) {
      return;
    }

    this.onSourceDataListeners[e.sourceId](e);
  }

  findFeatureAt(e) {
    const bbox = [
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5]
    ];

    const styleLayerIds = this.map.getStyle().layers.map(l => l.id);
    let features = [];
    for (const layersGroup of this.mapSettings.clickable_layers) {
      features = this.map.queryRenderedFeatures(bbox, {layers: Object.keys(layersGroup).filter(layerId => styleLayerIds.includes(layerId))});
      if (features.length > 0) {
        break;
      }
    }
    let closestFeature = null;
    let minDist = Number.MAX_SAFE_INTEGER;

    for (let i = 0; i < features.length; i++) {
      const feature = features[i];
      let dist;

      if (feature.geometry.type === "Point") {
        dist = turf.distance(feature, turf.point([e.lngLat.lng, e.lngLat.lat])) * 0.25; // x0.25 give higher priority to labels
      } else if (feature.geometry.type === "LineString") {
        dist = turf.pointToLineDistance(turf.point([e.lngLat.lng, e.lngLat.lat]), feature);
      }

      if (dist < minDist) {
        closestFeature = feature;
        minDist = dist;
      }
    }
    return closestFeature;
  }

  refreshSources() {
    const dateParams = this.getDateParams();

    for (const value of Object.values(this.mapSettings.sources)) {
      if (!value.replace) continue;

      if (value.vector) {
        if (!this.filters.monthly || (value.monthly_tiles_path)) {
          this.setSourceLayersVisible(value.url, true)
          this.replaceSource(
            value.url,
            MapboxHelper.newVectorSource(
              (this.filters.monthly ? value.monthly_tiles_path : value.tiles_path) + (value.use_params ? dateParams : ""),
              value.max_zoom
            )
          );
        } else {
          this.setSourceLayersVisible(value.url, false)
        }
      } else {
        this.replaceSource(value.url, MapboxHelper.newGeoJsonSource());
      }
    }
  }

  getDateParams() {
    let url = "";
    if (this.filters.timezone !== undefined) {
      url += "?zone=" + this.filters.timezone;
    }

    if (this.filters.regulationDate !== undefined) {
      url += (url.length === 0 ? "?" : "&") + "time=" +
        new Date(
          this.filters.regulationDate.getTime() -
          this.filters.regulationDate.getTimezoneOffset() * 60000
        ).toISOString();
    }

    if (this.filters.regulationEndDate !== undefined) {
      url += (url.length === 0 ? "?" : "&") + "endTime=" +
        new Date(
          this.filters.regulationEndDate.getTime() -
          this.filters.regulationEndDate.getTimezoneOffset() * 60000
        ).toISOString();
    }
    return url;
  };

  static DRAWING_STYLES = [
    {
      'id': 'gl-draw-polygon-fill-inactive',
      'type': 'fill',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'fill-color': '#3bb2d0',
        'fill-outline-color': '#3bb2d0',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-fill-active',
      'type': 'fill',
      'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      'paint': {
        'fill-color': '#fbb03b',
        'fill-outline-color': '#fbb03b',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-midpoint',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'meta', 'midpoint']],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#3bb2d0'
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-inactive',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Polygon'],
        ['!=', 'mode', 'static']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#3bb2d0',
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-active',
      'type': 'line',
      'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#fbb03b',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-line-inactive',
      'type': 'line',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'LineString'],
        ['!=', 'mode', 'static']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#3bb2d0',
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-line-active',
      'type': 'line',
      'filter': ['all',
        ['==', '$type', 'LineString'],
        ['==', 'active', 'true']
      ],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#fbb03b',
        'line-dasharray': [0.2, 2],
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 8,
        'circle-color': '#fff'
      }
    },
    {
      'id': 'gl-draw-polygon-and-line-vertex-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'meta', 'vertex'],
        ['==', '$type', 'Point'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 8,
        'circle-color': '#fbb03b'
      }
    },
    {
      'id': 'gl-draw-point-point-stroke-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 5,
        'circle-opacity': 1,
        'circle-color': '#000'
      }
    },
    {
      'id': 'gl-draw-point-inactive',
      'type': 'circle',
      'filter': ['all',
        ['==', 'active', 'false'],
        ['==', '$type', 'Point'],
        ['==', 'meta', 'feature'],
        ['!=', 'mode', 'static']
      ],
      'paint': {
        'circle-radius': 3,
        'circle-color': '#3bb2d0'
      }
    },
    {
      'id': 'gl-draw-point-stroke-active',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['==', 'active', 'true'],
        ['!=', 'meta', 'midpoint']
      ],
      'paint': {
        'circle-radius': 10,
        'circle-color': '#000'
      }
    },
    {
      'id': 'gl-draw-point-active',
      'type': 'circle',
      'filter': ['all',
        ['==', '$type', 'Point'],
        ['!=', 'meta', 'midpoint'],
        ['==', 'active', 'true']],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#fbb03b'
      }
    },
    {
      'id': 'gl-draw-polygon-fill-static',
      'type': 'fill',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      'paint': {
        'fill-color': '#404040',
        'fill-outline-color': '#404040',
        'fill-opacity': 0.1
      }
    },
    {
      'id': 'gl-draw-polygon-stroke-static',
      'type': 'line',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#404040',
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-line-static',
      'type': 'line',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
      'layout': {
        'line-cap': 'round',
        'line-join': 'round'
      },
      'paint': {
        'line-color': '#404040',
        'line-width': 2
      }
    },
    {
      'id': 'gl-draw-point-static',
      'type': 'circle',
      'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
      'paint': {
        'circle-radius': 5,
        'circle-color': '#404040'
      }
    }
  ];
}

export default MapboxHelper;