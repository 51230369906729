<script>

    export default {
        props: ["price", "source"],
        data () {
            let stripeToken = "";
            if (this.source.toLowerCase() === 'spothero') {
                stripeToken = spotHeroStripeToken;
            } else if (this.source.toLowerCase() === 'interpark') {
                stripeToken = interparkStripeToken;
            } else if (this.source.toLowerCase() === 'way.com') {
                stripeToken = wayStripeToken;
            } else {
                return;
            }
            const stripeInstance = Stripe(stripeToken)
            return {
                loading: true,
                canMakePayment: false,
                elements: stripeInstance.elements(),
                paymentRequest: stripeInstance.paymentRequest({
                    country: 'US',
                    currency: 'usd',
                    total: {
                        label: 'Parking with ' + this.source,
                        amount: parseFloat((this.price * 100).toFixed(2)),
                    }
                }),
            }
        },
        watch: {
            price: function(newVal, oldVal) { // watch it
                this.paymentRequest.update({
                    total: {
                        label: 'Parking with ' + this.source,
                        amount: parseFloat((newVal * 100).toFixed(2)),
                    }
                })
            }
        },
        methods: {
            init (canMakePayment) {
                this.loading = false
                this.canMakePayment = canMakePayment
                if (canMakePayment)
                    this.$parent.walletPaymentEnabled = true
                this.$nextTick(this.createPaymentRequestButton)
            },
            createPaymentRequestButton () {
                let vue = this
                if (!this.canMakePayment || !this.$refs.element) return

                let prButton = this.elements.create('paymentRequestButton', { paymentRequest: this.paymentRequest })
                prButton.mount(this.$refs.element)

                prButton.on('click', function(ev) {
                    // vue.$parent.paymentSuccess = true;
                    if (!vue.$parent.checkForm()) {
                        ev.preventDefault()
                        return;
                    }
                })
            }
        },
        mounted () {
            if (this.paymentRequest) {
              // Check the availability of the Payment Request API first.
              this.paymentRequest.canMakePayment().then(this.init)

              // Notify the parent component when we receive a token.
              this.paymentRequest.on('token', event => this.$emit('token', event))
            }
        },
        render (createElement) {
            // Render a loading slot if we are waiting for canMakePayment.
            if (this.loading) {
                return this.$slots.loading && this.$slots.loading[0]
            }

            // Render a warning slot if payment request isn't available.
            if (! this.canMakePayment) {
                return this.$slots.unavailable && this.$slots.unavailable[0]
            }

            // Render scoped slot if provided.
            if (this.$scopedSlots.default) {
                return this.$scopedSlots.default({
                    listeners: { click: event => this.paymentRequest.show() },
                    canMakePayment: this.canMakePayment,
                })
            }

            // Otherwise render default Stripe Element button.
            return createElement('div', { ref: 'element' })
        }
    }
</script>

