<template>
  <div class="park-regulation">
    <div v-for="(calendar, index) in data" :key='index'>
      <div class="regulation-title">{{ calendar.name }}</div>
      <div v-if="calendar.permits && calendar.permits.length > 0" class="regulation-permits">Except permit
        <span>{{calendar.permits.join(',')}}</span>
      </div>

      <Regulation v-if="calendar.months && calendar.months.length > 0" :data="calendar.months" />
      <Regulation v-if="calendar.prices && calendar.prices.length > 0" :data="calendar.prices" />
    </div>
  </div>
</template>

<script>
import Regulation from "../ui/Regulation";

export default {
  props: ["data"],
  components: {
    Regulation
  },
};
</script>

<style scoped>
.park-regulation {
  color: #8293a3;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 16px 0;
}

.regulation-title {
  font-weight: bold;
  color: #545E69;
  /* padding-bottom: 3px; */
}


.regulation-permits {
  font-family:"MuseoSansRounded", Arial, Helvetica, sans-serif;
  font-size:12px;
  font-weight:300;
  font-style:italic;
  line-height:16px;
  color:#8293a3;
}

</style>