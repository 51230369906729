import { parseDate } from "../../utils/dateHelper";

export default {
  namespaced: true,
  state: {
    geocoder: "",
    monthlyMode: false,
    selectedTime: 0,
    searchString: "",
    filteredDate: {
      arrivalDate: new Date(),
      arrivalTime: new Date().getHours(),
      departureDate: new Date(),
      departureTime: new Date().getHours(),
      arrivalConfig: {
        altFormat: "MM/dd/yyyy",
        altInput: false,
        dateFormat: "yyyy-MM-dd",
        minDate: new Date(),
      },
      departureConfig: {
        altFormat: "MM/dd/yyyy",
        altInput: true,
        dateFormat: "yyyy-MM-dd",
        minDate: new Date(),
      },
    },
  },
  mutations: {
    setFilteredDate(state, payload) {
      state.filteredDate.arrivalDate = payload.arrivalDate;
      state.filteredDate.arrivalTime = payload.arrivalTime;
      state.filteredDate.departureDate = payload.departureDate;
      state.filteredDate.departureTime = payload.departureTime;
    },
    setDepartureDate(state, payload) {
      state.filteredDate.departureDate = payload;
    },
    setArrivalDate(state, payload) {
      state.filteredDate.arrivalDate = payload;
    },
    setArrivalTime(state, payload) {
      state.filteredDate.arrivalTime = payload;
    },
    setDepartureMinDate(state, payload) {
      state.filteredDate.departureConfig.minDate = payload;
    },
    setDepartureTime(state, payload) {
      state.filteredDate.departureTime = payload;
    },
    setSelectedTime(state, payload) {
      state.selectedTime = payload;
    },
    setSearchString(state, payload) {
      state.searchString = payload;
    },
    setMonthlyMode(state, payload) {
      state.monthlyMode = payload;
      state.recommendationList = "";
    },
    setGeocoder(state, payload) {
      state.geocoder = payload;
    },
  },
  actions: {
    setMonthlyMode(context, payload) {
      context.commit("setMonthlyMode", payload);
    },
    setFilteredDate(context, payload) {
      context.commit("setFilteredDate", payload);
    },
    setDepartureDate(context, payload) {
      context.commit("setDepartureDate", payload);
    },
    setArrivalDate(context, payload) {
      context.commit("setArrivalDate", payload);
    },
    setArrivalTime(context, payload) {
      context.commit("setArrivalTime", payload);
    },
    setDepartureTime(context, payload) {
      context.commit("setDepartureTime", payload);
    },
    setSelectedTime(context, payload) {
      context.commit("setSelectedTime", payload);
    },
    setDepartureMinDate(context, payload) {
      context.commit("setDepartureMinDate", payload);
    },
    setSearchString(context, payload) {
      context.commit("setSearchString", payload);
    },
    setGeocoder(context, payload) {
      context.commit("setGeocoder", payload);
    },
  },
  getters: {
    geocoder: (state) => {
      return state.geocoder;
    },
    monthlyMode: (state) => {
      return state.monthlyMode;
    },
    getFilteredDate: (state) => {
      return state.filteredDate;
    },
    getDepartureTime: (state) => {
      return state.filteredDate.departureTime;
    },
    getDepartureDate: (state) => {
      return state.filteredDate.departureDate;
    },
    getArrivalTime: (state) => {
      return state.filteredDate.arrivalTime;
    },
    getSelectedTime: (state) => {
      return state.selectedTime;
    },
    getSearchString: (state) => {
      return state.searchString;
    },
    getFilterPayload: (state) => {
      let payload = {
        arrivalDate: parseDate(
          state.filteredDate.arrivalDate,
          state.filteredDate.arrivalTime
        ),
        departureDate: parseDate(
          state.filteredDate.departureDate,
          state.filteredDate.departureTime
        ),
      };
      return payload;
    },
  },
};
