<template>
  <div>
    <Breadcrumb />
    <Recommendations v-if="recommendationList !== ''" />
    <MostSearched v-if="seo_data.seo_most_searched && seo_data.seo_most_searched.length > 0"/>
    <OtherParks v-if="seo_data.seo_others && seo_data.seo_others.length > 0"/>
  </div>

</template>

<script>
import seoHelper from "../../utils/seoHelper"
import Breadcrumb from "./seo/Breadcrumb"
import Recommendations from "./Recommendations"
import MostSearched from "./seo/MostSearched"
import OtherParks from "./seo/OtherParks"
import {mapGetters} from "vuex"


export default {
  components: {
    Breadcrumb,
    Recommendations,
    MostSearched,
    OtherParks
  },
  data() {
    return {
      seo_data: seoHelper.seo_data,
    };
  },
  computed: {

    ...mapGetters("map", [
      "recommendationList",
    ]),
  },
};
</script>

<style scoped>

</style>