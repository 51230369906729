<template>
  <div class="pay-meter-wrapper">
    <button @click="paymentLink">
      Pay Meter <i class="external"></i></button>
    <p>{{ data.meter_payment.source }}</p>
  </div>
</template>

<script>
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  props: ["data"],
  methods: {
    paymentLink() {
      TrackHelper.payMeterClicked(this.data)
      window.open(this.data.meter_payment.web_link, '_blank');
    }
  }
};
</script>

<style scoped>
.pay-meter-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}

.external {
  background-image: url('~images/external.svg');
  width: 14px;
  height: 14px;
  display: inline-block;
  position: relative;
  left: 2px;
  top: 1px;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #1C9BE6;
  border: 1px solid #1C9BE6;
  box-sizing: border-box;
  border-radius: 24px;
  font-size: 16px;
  padding: 8px 28px;
  color: #FFF;
  width: 100%;
  margin: 16px auto 0;
  cursor:pointer;
}

button:focus {
  outline: none;
}

p {
  font-size: 12px;
  padding-top: 4px;
}
</style>