<template>
  <div>
    <Garage v-if="data.status.name === 'Garage'" :data="data" />
    <OnStreet v-else :data="data" />
  </div>
</template>

<script>
import Garage from "./Garage";
import OnStreet from "./OnStreet";
export default {
  components: {
    Garage,
    OnStreet,
  },
  props: ["data"],
};
</script>
