<template>
    <div class="payment_popup" id="payment_failure">
        <div class="payment_modal">

            <img class="payment_popup_image" src="https://spotangels-assets.s3.amazonaws.com/payment_attention.png">

            <p class="payment_popup_title_text">{{payment_error_message}}</p>

            <button class="payment_popup_button" v-on:click="dismissErrorPopup">Try again</button>

        </div>
    </div>
</template>

<script>
    export default {
        props: ["payment_error_message"],
        methods: {
            dismissErrorPopup() {
              this.$parent.paymentFailure = false;
            },
        }
    }
</script>

<style scoped>

</style>