<template>
    <div>
        <div class="waiting_screen" v-show="loading">
            <img v-bind:src="waitingImage" class="img-responsive">
        </div>
        <div class="top_banner"><img :src="logoImage" class="banner_logo"><span class="centered">SpotAngels</span></div>
        <div class="row">
            <div class="overview column" v-show="!parkingPass">
                <HeaderMobile v-if="isMobile" :garage_name="segment.status.garage_name" :cc_screen="cc_screen" />
                <div v-else class="huge_title">Complete your reservation</div>

                <template v-if="isMonthly">
                    <ReservationTimesMonthly :deal="deal" v-show="!cc_screen" ref="times_monthly"/>
                    <UserInfo :deal="deal" :is_monthly="isMonthly" ref="infos" v-show="!cc_screen"/>
                    <button v-if="isMobile && !cc_screen" class="payment_continue_button" v-on:click="getCreditCardScreen" :disabled="!dealFound">Continue</button>
                    <div v-else>
                        <!-- Credit Card Screen -->
                        <div class="main_title" style="padding-top:12px;" v-if="!isMobile">PAYMENT</div>
                        <OrderSummaryMonthly v-if="userFacingError === ''" :price="deal.price" :checkout_msg="deal.checkout_msg" :description="deal.description" :discount="deal.discount"/>
                        <div v-else class="user_error">{{userFacingError}}</div>
                        <CCForm ref="cc_monthly"/>
                    </div>
                </template>

                <div v-else>
                    <!-- Common section for mobile and web -->
                    <ReservationTimes :params="initial_dates" @timesChangeEvent="recomputePrice" ref="times" v-show="!cc_screen" />
                    <UserInfo :deal="deal" :is_monthly="isMonthly" ref="infos" v-show="!cc_screen"/>
                    <!-- End of common section -->

                    <!-- If we are on mobile, split views into 2 screens one for info and one for cc payment -->
                    <div v-if="isMobile">
                        <div v-if="!cc_screen">
                            <OrderSummary v-if="userFacingError === ''" :on-site-price="segment.status.raw_price" :price="deal.price" :full_start_date="deal.payment.start_date" :full_end_date="deal.payment.end_date" />
                            <div v-else class="user_error">{{userFacingError}}</div>
                            <div v-show="walletPaymentEnabled" id="payment-request-button" class="mobile_payment_button">
                                <WalletPayment v-if="!cc_screen && dealFound && deal.source.toLowerCase() === 'interpark'" :price="deal.price" :source="deal.source" @token="pay" ref="wallet" />
                            </div>
                            <div v-if="walletPaymentEnabled">
                                <div v-if="dealFound" class="pay_with_cc link" v-on:click="getCreditCardScreen">Pay with Card</div>
                            </div>
                            <button v-else class="payment_continue_button" v-on:click="getCreditCardScreen" :disabled="!dealFound || userFacingError !== ''">Continue</button>
                        </div>
                        <div v-else>
                            <!-- Credit Card Screen -->
                            <OrderSummary v-if="cc_screen" :on-site-price="segment.status.raw_price" :price="deal.price" :full_start_date="deal.payment.start_date" :full_end_date="deal.payment.end_date" />
                            <CCForm />
                        </div>
                    </div>
                    <div v-else>
                        <div class="main_title" style="padding-top:12px;">PAYMENT</div>
                        <div v-show="walletPaymentEnabled" id="payment-request-button" class="mobile_payment_button">
                            <WalletPayment v-if="!cc_screen && dealFound && deal.source.toLowerCase() === 'interpark'" :price="deal.price" :source="deal.source" @token="pay" ref="wallet" />
                        </div>
                        <div v-if="walletPaymentEnabled" class="pay_with_cc_text" v-on:click="getCreditCardScreen">OR PAY WITH CARD</div>
                        <CCForm />
                    </div>
                </div>
            </div>
            <div v-show="!isMobile" class="overview column" style="width: 100px;">&nbsp;</div>
            <div class="overview column" v-show="!parkingPass && !isMobile" style="width: 440px;">
                <div class="bordered_box" v-if="segmentInitialized">
                    <div class="garage_title garage_title_desktop">{{segment.status.garage_name}}</div>
                    <div class="garage_title garage_address">{{segment.status.address}}</div>
                    <div class="desktop_only_image" v-show="!cc_screen"><MapboxImage :lng="lng" :lat="lat"/></div>
                    <template v-if="!isMonthly">
                        <OrderSummary v-if="userFacingError === ''" :on-site-price="segment.status.raw_price" :price="deal.price" :full_start_date="deal.payment.start_date" :full_end_date="deal.payment.end_date" />
                        <div v-else class="user_error">{{userFacingError}}</div>
                    </template>
                </div>

            </div>
        </div>
        <PaymentSuccess v-if="paymentSuccess" :email="$refs.infos.email" />
        <PaymentFailure v-if="paymentFailure" :payment_error_message="paymentErrorMessage" />
        <ParkingPass v-if="parkingPass" :segment="segment" :payment_data="payment_data" :payment_response="payment_response" />
    </div>
</template>

<script>
    import ReservationTimes from "../../components/checkout/reservation_times.vue"
    import OrderSummary from "../../components/checkout/order_summary.vue"
    import UserInfo from "../../components/checkout/user_info.vue"
    import WalletPayment from "../../components/checkout/wallet_payment.vue";
    import PaymentSuccess from "../../components/checkout/payment_success.vue";
    import PaymentFailure from "../../components/checkout/payment_failure.vue";
    import CCForm from "../../components/checkout/cc_form.vue";
    import ParkingPass from "../../components/checkout/parking_pass.vue";
    import HeaderMobile from "../../components/checkout/header_mobile.vue";
    import MapboxImage from "../../components/checkout/mapbox_image.vue";
    import waitingImage from "../../../assets/images/loading.gif";
    import ReservationTimesMonthly from "../../components/checkout/monthly/reservation_times_monthly.vue"
    import OrderSummaryMonthly from "../../components/checkout/monthly/order_summary_monthly.vue"
    import logoImage from "../../../assets/images/spotangels-logo.png";


    export default {
        data() {
            return {
                logoImage,
                waitingImage,
                initial_dates: {
                    startDateTime: null,
                    endDateTime: null,
                },
                segmentInitialized: false,
                lng: null,
                lat: null,
                summary_screen: false,
                cc_screen: false,
                reservation: null,
                visible: false,
                // garage_picture_url: null,
                segment: {
                    status: {
                        raw_price: 0,
                        garage_name: '',
                        city_id: '',
                        operator_id: '',
                        address: '',
                        entrances: [],
                    },
                },
                deal: {
                    price: null,
                    payment: {
                        start_date: null,
                        end_date: null,
                        license_plate_required: true,
                        phone_number_required: true,
                    }
                },
                dealFound: null,
                loading: true,
                paymentSuccess: false,
                paymentFailure: false,
                paymentErrorMessage: 'Payment Failed',
                parkingPass: false,
                isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
                walletPaymentEnabled: false,
                payment_response: { partner_response: {}, card: {}},
                firstLoadedUrl: sessionStorage.getItem('firstLoadedUrl'),
                isMonthly: false,
                isDownPayment: false,
             };
        },
        computed: {
            userFacingError : function () {
                if (this.loading)
                    return ' '
                else {
                    let error = ''
                    if (!this.dealFound) {
                        error = 'We could not load your deal. Please try different times.'
                        if (this.isMonthly)
                            error = 'There was an issue loading your deal. Please contact support.'
                    }
                    if (this.deal.price === 0)
                        error = 'Invalid rate. Please try different times.'
                    return error
                }
            },
            payment_data : function () {
              let vue = this;
              let data = {
                  'email': vue.$refs.infos.email,
                  'phone_number': vue.$refs.infos.phone_number,
                  'license_plate': vue.$refs.infos.license_plate,
                  'source': vue.deal.source,
                  'rate': vue.deal.price,
                  'segment_id': vue.checkout_params.segment_id,
                  'url': vue.firstLoadedUrl,
              }
              if (vue.isMonthly) {
                  data.start_time = vue.$refs.times_monthly.monthlyStartDate
                  // data.license_plate_state = 'CA'
                  // if (!data.license_plate)
                  //   data.license_plate = 'NA'
                  data.card_type = vue.$refs.cc_monthly.cardType
              } else {
                  data.start_time = vue.deal.payment.start_date
                  data.end_time = vue.deal.payment.end_date
              }
              if (vue.deal.payment !== undefined && vue.deal.payment.type_id !== undefined)
                  data.parking_type_id = vue.deal.payment.type_id
              return data
          },
          amplitude_info: function() {
              let vue = this;
              return {
                  "Url": vue.firstLoadedUrl,
                  "Source": sessionStorage.getItem('webSource'), // home or monthly or poi-city
                  "City Id": vue.segment.status.city_id,
                  "Operator Id": vue.segment.status.operator_id,
                  "Operator Name": vue.segment.status.operator_name,
                  "Segment Id": vue.checkout_params.segment_id,
                  "Price": vue.deal.price,
                  "Type": vue.deal.source,
                  "Offer": vue.isMonthly ? "monthly" : "transient",
                  "Paid with": "card",
                  "Down Payment": (vue.deal.down_payment) ? vue.deal.down_payment : ""
              }
          },
          revenue: function () {
            let vue = this;
            let revenue = 0;
            if (this.isMonthly)
              revenue = (vue.deal.discount) ? (vue.deal.price / 2 - vue.deal.discount) : vue.deal.price / 2
            else
              revenue = vue.deal.price / 10
            return revenue.toFixed(2)
          },
          gtag_info: function() {
              let vue = this;
              return {
                    "transaction_id": Date.now(),
                    "affiliation": vue.firstLoadedUrl,
                    "value": vue.revenue,
                    "currency": "USD",
                    "items": [
                        {
                            "id": vue.checkout_params.segment_id,
                            "name": vue.checkout_params.segment_id,
                            "category": vue.isMonthly ? "Garage Monthly Booking" : "Garage Booking",
                            "url": vue.firstLoadedUrl,
                            "quantity": 1,
                            "price": vue.revenue
                        }]
                }
            }
          },
        props: ["checkout_params"],
        components: {
            ReservationTimes,
            OrderSummary,
            UserInfo,
            WalletPayment,
            PaymentSuccess,
            PaymentFailure,
            CCForm,
            ParkingPass,
            HeaderMobile,
            MapboxImage,
            ReservationTimesMonthly,
            OrderSummaryMonthly,
        },
        created: function () {
            this.isMonthly = (this.checkout_params.type === "monthly")
            if (this.isMonthly)
                this.recomputePrice(new Date(), new Date()) // Dummy date parameters
            else {
                if ( !isDate(this.checkout_params.start_date) || !isDate(this.checkout_params.end_date) ) {
                    this.initial_dates.startDateTime = new Date();
                    this.initial_dates.endDateTime = new Date(this.initial_dates.startDateTime.getTime() + 2 * 60 * 60 * 1000);
                } else {
                    this.initial_dates.startDateTime = parseParamDate(this.checkout_params.start_date);
                    this.initial_dates.endDateTime = parseParamDate(this.checkout_params.end_date);
                }
                this.recomputePrice(this.initial_dates.startDateTime, this.initial_dates.endDateTime)
            }
        },
        methods: {
            checkForm() {
                return this.$refs.infos.isFormValid()
            },
            pay(ev) {
                let vue = this;
                vue.payment_data.stripe_token = ev.token.id;

                fetch(spotAngelsApiV3 + "/api/v3/pay_as_guest", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(vue.payment_data)
                })
                .then(response => {
                    if (!response.ok) {
                        vue.paymentFailure = true;
                        throw Error(response.statusText);
                    }
                    return response
                })
                .then(response => { return response.json(); })
                .then(result => {
                    ev.complete('success');
                    if (result.error !== undefined) {
                        vue.paymentErrorMessage = result.error;
                        vue.paymentFailure = true;
                        return;
                    }
                    vue.paymentSuccess = true;
                    vue.payment_response = result;
                    amplitude.getInstance().logEvent('Booking - Confirm Payment - Tap Pay', vue.amplitude_info);
                    gtag('event', 'purchase', vue.gtag_info);
                    fbq('track', 'Purchase', {currency: "USD", value: vue.revenue});
                })
                .catch((e) => { vue.paymentFailure = true });

            },
            recomputePrice(fsd, fed) {
                this.loading = true;
                RegulationsHelper.getSpotInfo(this.checkout_params.segment_id, fsd, fed, this.isMonthly, this.onSegmentGetSuccess, this.onSegmentFailure)
            },
            onSegmentGetSuccess(request, data) {
                let vue = this;
                vue.dealFound = false;
                // console.log('data', data);
              vue.segment = data;
              if (!vue.segmentInitialized) {
                    if (data.status.entrances !== undefined && data.status.entrances.length > 0) {
                        vue.lng = data.status.entrances[0].lng
                        vue.lat = data.status.entrances[0].lat
                    } else {
                        vue.lng = data.status.lng
                        vue.lat = data.status.lat
                    }
                    // if (data.pictures.length > 0)
                    //     vue.garage_picture_url = data.pictures[0].picture_url
                    vue.segmentInitialized = true;
                }
                if (data.status && data.status.deals && data.status.deals.length > 0) {
                    if (vue.isMonthly) {
                        data.status.deals.forEach(function (deal) {
                            if ( (deal.rate_id !== undefined && deal.rate_id.toString() === vue.checkout_params.rate_id) || (sessionStorage.getItem('offerPrice') !== undefined && deal.price.toString() === sessionStorage.getItem('offerPrice')) ){
                                vue.dealFound = true;
                                deal.payment = {license_plate_required: false, phone_number_required: true}
                                vue.isDownPayment = deal.down_payment !== undefined
                                deal.finalPrice =  vue.isDownPayment ? '$' + (deal.price * deal.down_payment / 100 - deal.discount).toFixed(2).toString() : '$' + (deal.price - deal.discount).toString()


                                let monthlyStartDate = new Date(deal.monthly_min_start_date)
                                let day = monthlyStartDate.getUTCDate()
                                let month = monthlyStartDate.getUTCMonth()
                                let year = monthlyStartDate.getUTCFullYear()
                                let newDate = new Date(year+"/"+(month+1)+"/"+day);

                                vue.$refs.times_monthly.monthlyStartDate = newDate
                                vue.deal = deal;
                            }
                        });
                    } else {
                        data.status.deals.forEach(function (deal) {
                            if ((deal.source.toLowerCase() === vue.checkout_params.source.toLowerCase() || vue.checkout_params.source === "null") && deal.payment) {
                                vue.dealFound = true;
                                vue.deal = deal;
                            }
                        });
                    }
                };
                vue.loading = false;
            },
            onSegmentFailure(r, e) {
                console.log('Failure', e)
                this.dealFound = false;
                this.loading = false;
            },
            getCreditCardScreen: function() {
                if (this.checkForm())
                    this.cc_screen = true;
            },

        }
    };
</script>