import ServicesHelper from "./servicesHelper";

class RegulationsHelper {
  static getBlockfaceOccupancy(blockfaceId, weekday, hour, success, error) {
    return ServicesHelper.get(
      `${regulationsBaseUrl}/api/v4/occupancy/blockface?id=${blockfaceId}&weekday=${weekday}&hour=${hour}&token=${regulationsAccessToken}&hash=${RegulationsHelper.buildHash({id: blockfaceId})}`,
      success, error
    )
  }

  static getSegmentOccupancy(segmentId, weekday, hour, success, error) {
    return ServicesHelper.get(
      `${regulationsBaseUrl}/api/v4/occupancy/blockface?segmentId=${segmentId}&weekday=${weekday}&hour=${hour}&token=${regulationsAccessToken}&hash=${RegulationsHelper.buildHash({id: segmentId})}`,
      success, error
    );
  }

  static getSpotInfo(segmentId, startDate, endDate, monthly, success, error) {
    if (!startDate) {
      startDate = new Date();
    }

    // console.log('startDate is', startDate, typeof(startDate), '---', new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)))
    startDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000));
    let url = `${regulationsBaseUrl}/api/v4/regulations/segment?id=${segmentId}&time=${(startDate.toISOString())}&token=${regulationsAccessToken}&hash=${RegulationsHelper.buildHash({id: segmentId})}`;

    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone !== undefined) {
      url += "&zone=" + timezone;
    }

    if (!!endDate) {
      endDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000));
      if (endDate <= startDate) {
        endDate = new Date(startDate.getTime());
        endDate.setHours(endDate.getHours() + 1);
      }
      url += "&endTime=" + endDate.toISOString();
    }

    if (monthly) {
      url += '&monthly=true'
    }

    return ServicesHelper.get(url, success, error)
  }

  static getRecommendations(lng, lat, startDate, endDate, monthly, success, error) {
    if (!startDate) {
      startDate = new Date();
    }

    // console.log('startDate is', startDate, 'type is', typeof(startDate), '---', new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)))
    startDate = new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000)).toISOString();
    let url = `${regulationsBaseUrl}/api/v4/regulations/radius/options?time=${startDate}&lat=${lat}&lng=${lng}&radius=800&token=${regulationsAccessToken}&hash=${RegulationsHelper.buildHash({lng: lng, lat: lat})}`;

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timezone !== undefined) {
      url += "&zone=" + timezone;
    }

    if (!!endDate && !monthly) {
      endDate = new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000));
      if (endDate <= startDate) {
        endDate = new Date(startDate.getTime());
        endDate.setHours(endDate.getHours() + 1);
      }
      url += "&endTime=" + endDate.toISOString();
    }

    if (monthly) {
      url += '&monthly=true'
    }

    return ServicesHelper.get(url, success, error)
  }

  static computeCalendar(regulations, meterPrices, success, error) {
    return ServicesHelper.post(
      `${regulationsBaseUrl}/api/v4/regulations/calendar`,
      {
        regulations: regulations,
        meter_prices: meterPrices,
      },
      success, error,
    )
  }

  /*
  Private methods, please don't call from outside
   */

  static buildHash(opts) {
    if (regulationsAccessToken === null) {
      throw 'No regulations access token given';
    }

    let base = regulationsAccessToken;
    if (opts.id !== undefined) {
      base += opts.id.toString();
    } else if (opts.lng !== undefined && opts.lat !== undefined) {
      base += opts.lng.toString() + opts.lat.toString();
    } else {
      throw 'Opts must have either id or lng and lat'
    }
    return btoa(RegulationsHelper.nxbHns(sha512(base))).replace(/\+/g, '-').replace(/\//g, '_');
  }

  static nxbHns(x) {
    const bytes = [];
    for (let i = 0; i < x.length - 1; i += 2)
      bytes.push(parseInt(x.substr(i, 2), 16));

    return String.fromCharCode.apply(String, bytes);
  }
}

export default RegulationsHelper;