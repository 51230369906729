<template>
  <div class="deals">
    <div v-for="(deal, index) in status.deals" :key="index" class="deals-wrapper">
      <img
          :src="require('images/icon-booking.svg')"
          alt="Deal"
      />
      <div class="deal-info">
        <div class="deal-price">
          <span v-if="status.raw_price > deal.price" class="full-price">{{ status.price }} on site</span>
          <span :class="(status.raw_price > deal.price) ? 'discounted-price' : null">{{ deal.price_fmt }}</span>
        </div>
        <div class="deal-provider">{{ deal.source }}</div>
      </div>
      <button class="deal-button" @click="openCheckout(deal, status.id)">
        {{ (deal.payment) ? deal.payment.cta_title : 'View Deal' }}
      </button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex"
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  props: ["status", "reviews_avg"],
  computed: {
    ...mapGetters("filter", ["getFilterPayload"])
  },
  methods: {
    openCheckout(deal, id) {
      TrackHelper.bookGarageClicked(this.status, deal, this.reviews_avg, 'Spot Sheet', 'Transient')
      if (deal.payment) {
        let start_date = this.getFilterPayload.arrivalDate;
        let end_date = this.getFilterPayload.departureDate;
        let isoDate = new Date(start_date.getTime() - (start_date.getTimezoneOffset() * 60000)).toISOString();
        let isoEndDate = new Date(end_date.getTime() - (end_date.getTimezoneOffset() * 60000)).toISOString();

        const link = "/checkout?segment_id=" + id +
            "&start_date=" + isoDate +
            "&end_date=" + isoEndDate +
            "&source=" + deal.source +
            "&type=transient"
        window.open(link);
      } else {
        window.open(deal.full_link)
      }

    },
  },
};
</script>

<style scoped>
img {
  width: 15px;
}
.deals {
  border-bottom: 4px solid #f5f7f8;
}

.deals-wrapper {
  display: flex;
  align-items: center;
  border-top: 1px solid #f5f7f8;
  color: #000;
  font-size: 12px;
  padding: 10px 20px;
}

.deal-info {
  margin-left: 5px;
}

.deal-button {
  background: #1c9be6;
  border: 1px solid #1c9be6;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  margin-left: auto;
  outline: none;
  padding: 5px 22px;
}

.deal-price {
  color: #212529;
  font-size: 14px;
}

.full-price {
  text-decoration: line-through;
}

.discounted-price {
  color: #1c9be6;
}

.deal-provider {
  color: #8293a3;
  font-size: 10px;
}
</style>