const TrackHelper = {
    headerTapClicked: (type) => {
        amplitude.getInstance().logEvent("Header - Tap", {type});
    },
    shareLinkCopied: () => {
        amplitude.getInstance().logEvent("Copy Link Tap", {"url": window.location.pathname});
    },
    shareSpotClicked: (url) => {
        amplitude.getInstance().logEvent("Web - Sheet - Share", {url});
    },
    requestMonthlySpotClicked: () => {
        amplitude.getInstance().logEvent('Monthly - Check Availability Tap');
    },
    requestMonthlySpotCancelClicked: () => {
        amplitude.getInstance().logEvent('Monthly - Cancel Tap');
    },
    requestMonthlySpotSubmitClicked: (status) => {
        amplitude.getInstance().logEvent('Monthly - Submit Tap', {
            "Operator Id": status.operator_id,
            "Segment Id": status.id,
            "Type": '',
            "url": window.location.pathname,
            "Website Version": 'v3'
        });
    },
    filterChanged: (type) => {
        amplitude.getInstance().logEvent("Web - Search - Filter Selected", {
            "url": window.location.pathname,
            "type": type,
            "Website Version": 'v3'
        });
    },
    mobileSlideUp: () => {
        amplitude.getInstance().logEvent("Parking Options - Mobile Slide Up", {
            "url": window.location.pathname,
            "source": sessionStorage.getItem("trackingSource"),
            "Website Version": 'v3'
        });
    },
    errorInitSegment: (segmentID) => {
        amplitude.getInstance().logEvent("WEB - Error on start with label", {
            id: segmentID,
            "url": window.location.pathname,
            "Website Version": 'v3'
        })
    },
    mapOpen: () => {
        amplitude.getInstance().logEvent("WEB - Open map", {
            "url": window.location.pathname,
            "source": sessionStorage.getItem("trackingSource"),
            "Website Version": 'v3'
        });
    },
    recommendationItemClicked: (park) => {
        amplitude.getInstance().logEvent("Web - Parking Options - Spot Clicked", {
            "Segment Id": park.id,
            "url": window.location.pathname,
            "Type": park.name,
            "bookable": (park.deals),
            "Website Version": 'v3'
        });
    },
    recommendationsViewDealClicked: (park, offer) => {
        let deal = park.deals[0];
        let amplitude_payload = {
            "Button location": "Parking Options",
            Later: false,
            Offer: offer,
            Type: deal.source,
            Price: deal.price,
            "Operator Id": park.operator_id,
            "Segment Id": park.id,
            "City Id": park.city_id,
            Source: sessionStorage.getItem("trackingSource"),
            "Website Version": 'v3'
        };
        amplitude.getInstance().logEvent('Web - Parking Options - Spot Clicked', amplitude_payload);
    },
    payMeterClicked: (data) => {
        amplitude.getInstance().logEvent('Pay Meter Tap', {
            'Price': data.raw_price,
            'Source': data.meter_payment.source,
            'Website Version': 'v3'
        });
    },
    bookGarageClicked: (status, deal, reviews_avg, buttonLocation, offer) => {
        let isDeal = status.raw_price > deal.price;
        if (status.raw_price === 0)
            isDeal = 'No on-site price'

        amplitude.getInstance().logEvent('Web - Spot Info - Tap Book Garage', {
            'Deal Wording': isDeal,
            'Button location': buttonLocation,
            'Later': false,
            'Offer': offer,
            'Type': deal.source,
            'Price': deal.price,
            'Operator Id': status.operator_id,
            'Segment Id': status.id,
            'City Id': status.city_id,
            'Source': sessionStorage.getItem("trackingSource"),
            'Operator Name': status.operator_name !== undefined ? status.operator_name.replace(/'/g,'') : '',
            'Down Payment' : (deal.down_payment) ? deal.down_payment : '',
            'Reviews avg': reviews_avg,
            'Website Version': 'v3'
        });
    },
    operatorWebsiteClicked: (status) => {
        amplitude.getInstance().logEvent('Web - Spot Info - Tap Garage Website', {
            'Offer': 'Transient',
            'Operator Id': status.operator_id,
            'Segment Id': status.id,
            'City Id': status.city_id,
            'Url': status.operator_url,
            'Website Version': 'v3'
        });
    },
    viewMonthlyOptionsClicked: (segmentId, cityId) => {
        amplitude.getInstance().logEvent('Web - Spot Info - Tap Monthly Options', {
            'Segment Id': segmentId,
            'City Id': cityId,
            'Website Version': 'v3'
        })
    },
    spotOpened: (data, monthlyMode) => {
        let bookable = false;
        let bookingSources = new Array("None");
        let monthlyBookingSources = new Array("None");
        if (data.status.deals) {
            bookingSources = [];
            data.status.deals.forEach(function (element, index) {
                bookingSources.push(data.status.deals[index]['source']);
            });
        }
        if (data.status.monthly_deals) {
            monthlyBookingSources = [];
            data.status.monthly_deals.forEach(function (element, index) {
                monthlyBookingSources.push(data.status.monthly_deals[index]['source']);
            });
        }
        let reviews = false
        let reviews_avg
        if (data.review_data && data.review_data[0].reviews) {
            reviews = true;
            reviews_avg = data.review_data['0'].rating;
        }
        let dealAvailable = false
        if (data.status.deals) {
            bookable = true;
            dealAvailable = data.status.deals.filter(deal => data.status.raw_price > deal.price).length > 0;
        }
        if (data.status.raw_price === 0) {
            dealAvailable = 'No on-site price'
        }
        amplitude.getInstance().logEvent("Web - Spot Info - Open Spot Info", {
            "Segment Id": data.status.id,
            "url": window.location.pathname,
            "Source": sessionStorage.getItem("trackingSource"),
            "bookable": bookable,
            "booking-operator": bookingSources,
            "Monthly Booking": monthlyBookingSources,
            "Operator Name": data.status.operator_name !== undefined ? data.status.operator_name : '',
            "Reviews": reviews,
            "Reviews avg": reviews_avg,
            "City Id": data.status.city_id,
            "Offer": monthlyMode ? 'Monthly' : 'Transient',
            "Deal Wording": dealAvailable,
            "Down Payment": (data.status.deals !== undefined && data.status.deals[0].down_payment !== undefined) ? data.status.deals[0].down_payment : '',
            "Website Version": 'v3'
        });
    },
    searchBoxTriggered: () => {
        amplitude.getInstance().logEvent("Web - Search - Click Result", {
            // "search": $('#searchBox input').val(),
            "url": window.location.pathname,
            "Website Version": 'v3'
        });
    },


};

export default TrackHelper;
