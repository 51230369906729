<template>
  <div class="deals">
<!--    <div class="monthly-deals-header">-->
<!--      <span class="monthly-deals-header-title">Monthly booking options</span>-->
<!--    </div>-->
    <button v-if="monthlyFull" class="deal-button" disabled>
      Monthly parking is full
    </button>
    <button v-else-if="receiveRequests" @click="requestSpot" class="deal-button">
      Request monthly spot
    </button>
  </div>
</template>

<script>
import TrackHelper from "../../utils/analytics/amplitude";

export default {
  props: ["monthlyFull", "receiveRequests"],
  methods: {
    requestSpot() {
      TrackHelper.requestMonthlySpotClicked()
      this.$emit("monthlyRequestedEvent", true);
    },
  },
};
</script>

<style scoped>
.deals {
  border-bottom: 4px solid #f5f7f8;
}
.monthly-deals-header {
  display: flex;
  align-items: center;
  padding: 20px;
}
.monthly-deals-header img {
  width: 18px;
}
.monthly-deals-header-title {
  color: #212529;
  font-size: 18px;
  padding-left: 5px;
}
.deal-button {
  background: #1c9be6;
  border: 1px solid #1c9be6;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 14px;
  margin-right: 20px;
  outline: none;
  padding: 5px 22px 5px 22px;
  margin: 20px auto;
}
</style>